import React from 'react';
import AssignedSections from '../../../modules/AssignedSections/components/AssignedSections';

const AssistantPage = () => {
  return (
    <div>
      <AssignedSections />
    </div>
  );
};

export default AssistantPage;