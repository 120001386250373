import React, { useContext } from 'react';
import { Radio } from 'antd';
import prepareChanges from '../helpers/prepareChanges';
import { getQuestionnaireSelectOptions } from '../helpers/getQuestionnaireSelectOptions';
import getResponse from '../helpers/getResponse';
import { QuestionnaireContext } from '../../../contexts/context';

const TypeChoiceAnswer = ({questionData, questionIndex, personIndex, nestedOptions={isNested:false}}) => {
  const {answersToUpdate, setAnswersToUpdate, isEdit} = useContext(QuestionnaireContext);

  const handleChange = (e) => {
    const newResponse = {
      pickedOption: e.target.value,
      pickedOptionName: questionData.options[e.target.value].option
    }
    setAnswersToUpdate(prepareChanges(answersToUpdate, newResponse, questionData, questionIndex, personIndex, nestedOptions))
  }

  const options = getQuestionnaireSelectOptions(questionData.options)
  const response = getResponse(answersToUpdate, questionData, questionIndex, nestedOptions, personIndex);

  return (
    <Radio.Group
      name="choice"
      disabled={!isEdit}
      onChange={handleChange}
      value={response.pickedOption}
      optionType="button"
      options={options}
    />
  );
};

export default TypeChoiceAnswer;
