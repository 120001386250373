import { Button } from 'antd';
import React, { useContext } from 'react';
import '../../../assets/operator/operator-deactivate-confirm.scss';
import { OperatorsContext } from '../../../contexts/context';

const OperatorDeactivatingConfirm = ({deactivateWithoutReassign, setPopupIsOpened, deactivatedOperatorName}) => {

  const cancelDeletion = () => {
    setPopupIsOpened(false);
  }

  return (
    <div className='operator-deactivate'>
      <p className='operator-deactivate__title'>
        Отключить аккаунт визовика
        <span className="operator-deactivate__operator-to-be-deactivated">
          {deactivatedOperatorName}
        </span>
      </p>
      <div className="operator-deactivate__buttons">
        <Button type='primary' onClick={deactivateWithoutReassign}>Да</Button>
        <Button type='default' onClick={cancelDeletion}>Нет</Button>
      </div>
    </div>
  );
};

export default OperatorDeactivatingConfirm;
