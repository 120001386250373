import { Button } from 'antd';
import React, { useContext } from 'react';
import prepareChanges from '../helpers/prepareChanges.js';
import ListItem from './ListItem.jsx';
import getResponse from '../helpers/getResponse.js';
import { QuestionnaireContext } from '../../../contexts/context.js';

const TypeListAnswer = ({questionData, questionIndex, personIndex, nestedOptions={isNested:false}}) => {
  const {answersToUpdate, setAnswersToUpdate, isEdit} = useContext(QuestionnaireContext);

  const changeList = (itemIndex, value, type) => {
    const settings = {
      itemIndex,
      value,
      type: type,
    }
    
    setAnswersToUpdate(prepareChanges(answersToUpdate, settings, questionData, questionIndex, personIndex, nestedOptions))
  }
  // список ответов для конкретного заявителя, либо общий.
  const response = getResponse(answersToUpdate, questionData, questionIndex, nestedOptions, personIndex);
  
  const list = response.map((item, index) => {
    return (
      <ListItem
        key={index}
        item={item}
        index={index}
        changeList={changeList}
      />
    )
  })

  return (
    <div>
      <ul style={{marginLeft: '20px', width:'70%'}}>
        {list}
      </ul>
      <Button
        type='link'
        onClick={() => changeList(null, null, 'add')}
        style={{display: isEdit ? 'block' : 'none'}}
      >
        Добавить
      </Button>
    </div>
  );
};

export default TypeListAnswer;
