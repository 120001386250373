import React from 'react';
import { Space, Button } from "antd";
import '../assets/apply-or-cancel/apply-or-cancel.scss'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import Spinner from './Spinner';

const ApplyOrCancel = ({isEdit, applyChanges, cancelChanges, loading, type=undefined }) => {
  // TODO: приклеить к низу экрана кнопки.
  return isEdit ? (
    <div className='apply-or-cancel'>
      {type === 'icons' ? (
        loading ? (
          <Spinner size='small'/>
        ) : (
          <>
            <CheckOutlined style={{color: 'green', fontSize:'1rem'}} onClick={applyChanges}/>
            <CloseOutlined style={{color: 'red', fontSize:'1rem'}} onClick={cancelChanges}/>
          </>
        )
      ) : (
        <div className='apply-or-cancel_buttons-type'>
          <Space size="large">
            <>
              <Button type="primary" onClick={applyChanges} loading={loading} >
                Сохранить
              </Button>
              <Button onClick={cancelChanges} disabled={loading}>
                Отмена
              </Button>
            </>
          </Space>
        </div>
      )}
    </div>
  ) : (
    null
  )
};

export default ApplyOrCancel;
