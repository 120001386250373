import { httpsCallable } from "firebase/functions";
import { functions } from "../../../firebase/firebase";

const changePrice = async (
  oldPrice,
  employeeName,
  newPrice,
  reason,
  applId
) => {
  try {
    const changeApplPrice = httpsCallable(functions, 'changeApplPrice');
    const response = await changeApplPrice(JSON.stringify({
      applId,
      employeeName,
      oldPrice,
      newPrice,
      reason,
    }))
    if(response.data === false) {
      throw {name:'PermissionError', message:'No permission'}
    }
  } catch (error) {
    throw error;
  }
};

export default changePrice;