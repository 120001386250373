import React from 'react';
import '../assets/components/picture-delete-button.scss';
import { CloseOutlined } from '@ant-design/icons';

const PictureDeleteButton = ({deleteFunc}) => {
  return (
    <div className='picture-delete-button__container' onClick={deleteFunc}>
      <CloseOutlined className='picture-delete-button__icon'/>
    </div>
  );
};

export default PictureDeleteButton;