import React, { useState } from 'react';
import NewsEditor from './editor/NewsEditor';
import NewsListItemInner from './listItem/NewsListItemInner';
import dayjs from 'dayjs';
import '../../../assets/news/news-list-item-container.scss';

const NewsListItemContainer = ({news, reloadNews}) => {
  const [ isEdit, setIsEdit ] = useState(false);
  const date = dayjs(news.createdAt.toMillis()).format('DD.MM.YYYY в HH:mm')

  return (
    <div className='news-list-item__container'>
      {isEdit ? (
        <NewsEditor
          news={news}
          date={date}
          setIsEdit={setIsEdit}
          reloadNews={reloadNews}
        />
      ) : (
        <NewsListItemInner
          news={news}
          date={date}
          setIsEdit={setIsEdit}
        />
      )}
    </div>
  );
};

export default NewsListItemContainer;