import { Modal } from 'antd';
import React from 'react';
import Auth from '../../Auth/components/Auth';
import ReAuthModalTitle from './ReAuthModalTitle';

const ConfirmPopUp = ({authModalOpened, handleModalClose, reauthenticate}) => {
  return (
    <Modal
      open={authModalOpened}
      classNames={{
        body: 'modal-body'
      }}
      onCancel={handleModalClose}
      footer={null}
      width="30%"
      wrapClassName='modal-wrapper'
      centered
      destroyOnClose
      title={<ReAuthModalTitle />}
    >
      <Auth onFinish={reauthenticate} />
    </Modal>
  );
};

export default ConfirmPopUp;
