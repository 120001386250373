const getUpdatedDocuments = (documents, operationType='add', additionalData={} ) => {
  const documentsStateClone = structuredClone(documents);
  if(operationType === 'add') {
    const {applicantId, sectionId, newDocsArray} = additionalData;
    const sectionIndex = documentsStateClone[applicantId].docSections.findIndex(section => section.sectionId === sectionId);
    documentsStateClone[applicantId].docSections[sectionIndex].docs = newDocsArray;
    return documentsStateClone;
  }
  if(operationType === 'delete') {
    const {applicantId, sectionId, deletingFileId} = additionalData;
    const sectionIndex = documentsStateClone[applicantId].docSections.findIndex(section => section.sectionId === sectionId);
    const docs = documentsStateClone[applicantId].docSections[sectionIndex].docs
    documentsStateClone[applicantId].docSections[sectionIndex].docs = docs.filter(doc => doc.uid !== deletingFileId);
    return documentsStateClone;
  }
}

export default getUpdatedDocuments;