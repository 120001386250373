import ApplicationInformation from "../components/ApplicationInformation";
import Journal from "../components/Journal";

const getAppInfoCollapseStructure = (journalData, appInfoData) => {
  const items = [
    {
      key: 1,
      label: 'Логи',
      children: (
        <Journal
          statusesHistoryData={journalData.statusesHistoryData}
          documentsHistoryData={journalData.documentsHistoryData}
          assignmentHistoryData={journalData.assignmentHistoryData}
          priceHistoryData={journalData.priceHistoryData}
        />
      )
    },
  ]

  if(appInfoData.appDetailsIsNotDisplayed) {
    return items;
  }

  items.push({
    key: 2,
    label: 'Детали заявки',
    children: (
      <ApplicationInformation
        curApplication={appInfoData.curApplication}
      />
    ),
  })

  return items;
}

export default getAppInfoCollapseStructure;