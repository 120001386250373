import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCollectionData, useCollectionDataOnce, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import getAssignedSectionsQuery from '../firebase/getAssignedSectionsQuery';
import { UserDataContext } from '../../../contexts/context';
import { Table, Button } from 'antd';
import getAssignedSectionTableData from '../helpers/getAssignedSectionTableData';
import MyError from '../../Error/components/MyError';
import Paginator from '../../../ui/Paginator';
import useAssignedSectionsCount from '../hooks/useAssignedSectionsCount';
import '../../../assets/assigned-sections/assigned-sections.scss';
import getAllCountriesRef from '../../../firebase/countries/getAllCountriesRef';
import { LayoutFuncsContext, SectionsPagesContext } from '../../../contexts/layoutContexts';
import getSectionsRowClass from '../helpers/getSectionsRowClass';
import getAssistantsQuery from '../../../firebase/assistants/getAssistantsQuery';
import getSectionsTableColumns from '../models/getSectionsTableColumns';
import getFromLocalStorage from '../../../localStorage/getFromLocalStorage';
import saveToLocalStorage from '../../../localStorage/saveToLocalStorage';



const AssignedSections = () => {
  // contexts
  const { authorizedUser, role } = useContext(UserDataContext);
  // localStorage.removeItem(authorizedUser.id)
  const { addAssignedSectionsPage } = useContext(LayoutFuncsContext);
  const { sectionsPageCount } = useContext(SectionsPagesContext);
  // states
  const [ sectionTableData, setSectionsTableData ] = useState([]);
  const [ sectionsTableFilters, setSectionsTableFilters ] = useState(() => getFromLocalStorage(authorizedUser.id, 'sectionsTableFilters'));
  // downloading data
  const [ assignedSections, loading, assignedSectionsError, collSnapshot ] = useCollectionData(getAssignedSectionsQuery(role, authorizedUser.id, sectionsTableFilters, sectionsPageCount*50), {snapshotListenOptions: {includeMetadataChanges:true}});
  const [ assistantsData, assistantsLoading, assistantsEror ] = useCollectionDataOnce(role === 'assistant' ? null : getAssistantsQuery());
  const [ countriesData, countriesLoading, countriesError, countriesDocSnapshot, reload ] = useDocumentDataOnce(getAllCountriesRef());
  const [ sectionsCount, sectionsCountLoading, sectionsCountError ] = useAssignedSectionsCount(authorizedUser, assignedSections, sectionsTableFilters) // Для пагинации.
  // variables
  const columns = useMemo(() => {
    return getSectionsTableColumns(assistantsData, sectionsTableFilters, role)
  }, [assistantsData, role, sectionsTableFilters])

  // effects
  useEffect(() => {
    if(assignedSections && countriesData && !collSnapshot.metadata.fromCache) {
      setSectionsTableData(getAssignedSectionTableData(assignedSections, countriesData.countries))
    }
  }, [assignedSections, collSnapshot, countriesData])

  // functions
  const downloadMoreSections = () => {
    addAssignedSectionsPage();
  }

  const handleTableChange = useCallback((pagination, filters, sorter, {action}) => {
    if(action === 'filter') {
      setSectionsTableFilters(filters)
      saveToLocalStorage(authorizedUser.id, filters, 'sectionsTableFilters')
    }
  }, [authorizedUser.id])

  // conditions
  if(assignedSectionsError ) {
    return <MyError error={assignedSectionsError} />
  }

  return (
    <div className='assigned-sections__container'>
      <div className='assigned-sections__before-table'>
        <Paginator currentCount={sectionTableData.length} totalCount={sectionsCount}/>
      </div>
      <Table
        columns={columns}
        dataSource={sectionTableData}
        pagination={false}
        rowClassName={getSectionsRowClass}
        onChange={handleTableChange}
      />
      <div className='assigned-sections__after-table'>
        <Paginator currentCount={sectionTableData.length} totalCount={sectionsCount}/>
      </div>
      <div style={{display:'flex', justifyContent:'center', marginTop: '1%'}}>
        <Button
          loading={loading}
          onClick={downloadMoreSections}
          disabled={sectionsCount === sectionTableData.length}
        >
          Загрузить еще
        </Button>
      </div>
    </div>
  );
};

export default AssignedSections;