import showNotification from "../../NotificationService/helpers/showNotification";
import { updateDoc } from "firebase/firestore";
import getAdminDocRef from "../../../firebase/admins/getAdminDocRef";

export const deactivateOperator = async (id) => {
  try {
    await updateDoc(getAdminDocRef(id), {active: false});
  } catch (error) {
    throw {name: error.name, code: error.code, message: 'Ошибка при отключении аккаунта'};
  }
};
