const createSectionObj = (sectionConfig, applicantIndex, sectionIndex, docsObj) => {
  // const section = docsObj.length ?
  const section = docsObj[applicantIndex]?.docSections.find(section => section.sectionId === sectionConfig.sectionId);
  // console.log(section)

  const docs = (section !== -1 && section) ? section.docs : [];
  // console.log(docs)
  return {
    sectionId: sectionConfig.sectionId,
    sectionTitle: sectionConfig.sectionTitle,
    maxDocs: sectionConfig.maxDocs,
    docs: docs,
  }
}

export default createSectionObj;