import { runTransaction } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";

const readUnreadMessages = async (unreadMessagesDocs, role) => {
  await runTransaction(firestore, async (transaction) => {
    unreadMessagesDocs.forEach(messageDoc => {
      transaction.update(messageDoc.ref, {readBy: [...messageDoc.get('readBy'), role], isReadFromCRM: true});
    });
  })
};

export default readUnreadMessages;