import React from 'react';
import '../../../../assets/documents/docs-by-sections/section-comment.scss';
import dayjs from 'dayjs';

const SectionCommentsItem = ({comment}) => {
  const time = dayjs(comment.createdAt.toMillis()).format('YYYY.MM.DD HH:mm')
  const commentByAssistent = comment.author.role === 'assistant';

  return (
    <div className='section-comment-item__container'>
      <div className={`section-comment-item__inner ${commentByAssistent ? 'assistant' : ''}`}>
        <div className='section-comment-item__metadata'>
          <div className='section-comment-item__author'>
            {comment.author.name}&nbsp;
          </div>
          <div className='section-comment-item__time'>
            {time}
          </div>
        </div>
        <p className='section-comment-item__text'>{comment.text}</p>
      </div>
    </div>
  );
};

export default SectionCommentsItem;