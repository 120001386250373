import React from 'react';
import '../../../assets/application-additionals/additional-services-item.scss';
import declination from '../../../utils/declination';

const AdditionalServicesItem = ({service, applicantsNumber}) => {
  const declWord = declination(applicantsNumber, ['заявителя', "заявителей", "заявителей"]);
  const price = service.price_usd * applicantsNumber;

  return (
    <li className='additional-services__list-item' >
      <p className='additional-services__name'>{service.name}</p>
      <div className='additional-services__price'>
        {`${price} КЗТ`}
        <span className='postfix-applicants-num'>за {applicantsNumber} {declWord}</span>
      </div>
    </li>
  );
};

export default AdditionalServicesItem;
