import React from 'react';
import DocumentsHistoryItem from './DocumentsHistoryItem';
import Spinner from '../../../components/Spinner';

const DocumentsHistory = ({ historyData }) => {

  const documentsLog = historyData.map(entry => {
    return <DocumentsHistoryItem key={entry.id + entry.operationType} entry={entry}/>
  })
  
  return (
    <div className="documents-history">
      <h3 className='documents-history__title'>
        История загрузки документов:
      </h3>
      <ul className='documents-history__list'>
        {documentsLog}
      </ul>
    </div>
  );
};

export default DocumentsHistory;