import dayjs from 'dayjs';
import React from 'react';
import '../../../assets/application-additionals/assignment-history/assignment-history.scss';

const AssignmentHistoryItem = ({entry}) => {
  const dateObject = dayjs(entry.changedAt.toMillis());
  const date = dateObject.format('DD.MM.YYYY HH:mm');
  return (
    <li className='assignment-history__container'>
      <p>{entry.appointer.name}</p>
      <p>{'назначил(а)'}</p>
      <p>{entry.assignee.name}</p>
      <p>{date}</p>
    </li>
  );
};

export default AssignmentHistoryItem;