import React, { memo, useContext } from 'react';
import { Card } from 'antd';
import '../../../assets/chat/dialog-list-item.scss'
import DialogueListItemTitle from './DialogueListItemTitle';
import getlastMessageTime from '../helpers/getlastMessageTime';
import getCardClassName from '../helpers/getCardClassName';
import { UserDataContext } from '../../../contexts/context';
import { useUpdateMessengerContext } from '../context/MessengerDataProvider';
const {Meta} = Card;

const cardGridStyle= {
  width:"100%",
  padding:"7px 7px 7px 20px",
}
const metaStyle = {
  backgroundColor:"transparent",
  border:"none",
  alignItems:"center",
}
const deletedStyle= {
  color:'#8A8A8A', fontWeight:'400'
}

const DialogueListItem = memo(({dialogue, dialogueSnapId, openDialogue, isSelected}) => {
  const { authorizedUser, features } = useContext(UserDataContext);
  const { setSelectedChat, setSelectedChatSnapId } = useUpdateMessengerContext()
  const appsCount = dialogue.totalApps;

  const dialogSelectHandler = () => {
    if(isSelected) return;
    openDialogue(dialogue);
    setSelectedChat(dialogue);
    setSelectedChatSnapId(dialogueSnapId);
  }

  // у клиента имени может не быть. Вывести айди если его нет.
  // const applicantName = dialogue?.name || client?.name || client?.phone || client?.email || client?.UID;
  const applicantName = dialogue?.name || dialogue.UID;

  const messageCreationTime = getlastMessageTime(dialogue.lastMessageTime);

  const accountIsDeleted = dialogue.accountDeleted;

  const cardClassName = features.helpFeatureEnabled ?
    getCardClassName(authorizedUser, isSelected, accountIsDeleted, dialogue.needHelp, dialogue.supportId) :
    getCardClassName(authorizedUser, isSelected, accountIsDeleted);

  return (
    <Card.Grid
      className={cardClassName}
      style={cardGridStyle} // не работает через класс.
    >
      <div
        className="dialogue-card__container"
        onClick={dialogSelectHandler}
      >
        <div className="dialogue-card__content">
          <Meta
            style={metaStyle}
            title={
              <DialogueListItemTitle
                applicantName={applicantName || <i style={deletedStyle}>Аккаунт удален</i>} // TODO: временное решение. Ждем изменений от Жангира
                messageCreationTime={messageCreationTime}
                appsCount={appsCount}
                dialogue={dialogue}
                dialogueSnapId={dialogueSnapId}
              />
            }
          />
        </div>
      </div>
    </Card.Grid>
  );
});

export default DialogueListItem;
