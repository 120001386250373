import React from 'react';
import '../../../assets/news/news-content.scss';

const NewsContent = ({children}) => {
  return (
    <div className='news-content'>
      {children}
    </div>
  );
};

export default NewsContent;