import dayjs from 'dayjs';
import React, { useContext } from 'react';
import '../../../assets/application-additionals/statuses-history/statuses-history-item.scss';
import { ProgramContext } from '../../../contexts/context';

const StatusesHistoryItem = ({entry}) => {
  const fullDate = dayjs(entry.changedAt.toMillis());
  const date =  fullDate.format('DD.MM.YYYY');
  const time = fullDate.format('HH:mm');

  return (
    <li className='statuses-history__item'>
      <p className='statuses-history__status-name'>{entry.statusName}</p>
      <p className='statuses-history__date'>{date}</p>
      <p className='statuses-history__time'>{time}</p>
      <p className='statuses-history__operator-name'>{entry.operatorName}</p>
    </li>
  );
};

export default StatusesHistoryItem;