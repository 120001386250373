import React from 'react';
import FilesUpload from '../../../../components/FilesUpload/FilesUpload';
import '../../../../assets/news/editor/news-editor-files.scss';


const NewsEditorFiles = ({fileList, filesBeforeUpload, disableDeletion, deleteFile}) => {
  return (
    <div className='news-editor-files__container' >
      <FilesUpload
        fileList={fileList}
        filesBeforeUpload={filesBeforeUpload}
        disableDeletion={disableDeletion}
        deleteFile={deleteFile}
      />
    </div>
  );
};

export default NewsEditorFiles;