import { Button } from 'antd';
import React, { useContext } from 'react';
import { ProgramContext } from '../contexts/context';
import finishIssue from '../firebase/issues/finishIssue';
import showNotification from '../modules/NotificationService/helpers/showNotification';

const FinishIssue = ({UID, issueId, dialoguerRef}) => {
  const { notificationAPI } = useContext(ProgramContext);

  const finishIssueHandler = async () => {
    try {
      await finishIssue(UID, issueId, dialoguerRef)
      showNotification(notificationAPI, 'process', {processName: 'finishIssue', status:'success'})
    } catch (error) {
      showNotification(notificationAPI, 'process', {processName: 'finishIssue', status:'error'})
    }
  }

    return (
      <Button
        onClick={finishIssueHandler}
        style={{border:'1px solid #ff7875', color:'#ff7875'}}
        ghost
      >
        Завершить обращение
      </Button>
    )
};

export default FinishIssue;