import React from 'react';
import MyError from './modules/Error/components/MyError';
import { UserDataContext } from './contexts/context';
import { Spin } from 'antd';
import useUserData from './useUserData';

const UserDataProvider = ({children}) => {
  const [
    user,
    authorizedUser,
    loading,
    error
  ] = useUserData();

  if(loading) {
    return (
      <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Spin size="large"/>
      </div>
    )
  }

  if(error ) {
    return <MyError error={error}/>
  }

  const context = {
    user,
    loading,
    authorizedUser,
    role: authorizedUser?.role,
  }

  context.features = {
    canEditNews: context.role === 'admin' || context.role === 'careSupport' || context.role === 'superOperator',
    canChangeEmail: context.role === 'admin' || context.role === 'careSupport' || context.role === 'superOperator',
    canChangeName: context.role === 'admin' || context.role === 'careSupport' || context.role === 'superOperator',
    canCreateAccount: context.role === 'admin' || context.role === 'careSupport' || context.role === 'superOperator',
    canAssignOperator: context.role === 'admin' || context.role === 'careSupport' || context.role === 'superOperator',
    // canAssignOperatorInChat: context.role !== 'operator' && context.role !== 'assistant',
    // canAssignCareSupport: context.role === 'admin' || context.role === 'superOperator' || context.role === 'careSupport' ,
    sectionsFeatureEnabled: context.role !== 'operator',
    helpFeatureEnabled: context.role !== 'operator' && context.role !== 'assistant',
    showNewMessageNotifications: context.role !== 'assistant',
    showNewAppNotifications: context.role !== 'assistant',
    canUseMessenger: context.role !== 'assistant',
    showHeaderSearchBar: context.role !== 'assistant',
    showPrice: context.role !== 'assistant' && context.role !== 'operator',
    canChangeAppPrice: context.role !== 'assistant' && context.role !== 'operator',
  }

  return (
    <UserDataContext.Provider value={context}>
      {children}
    </UserDataContext.Provider>
  )
};

export default UserDataProvider;