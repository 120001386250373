import { Button } from 'antd';
import React from 'react';

const LogoutButton = ({handleSignOut, name, roleRu}) => {
  return (
    <div className="logout">
      <div className="logout__button">
        <Button size='medium' danger onClick={handleSignOut}>Выйти</Button>
      </div>
      <p className='logout__user-name'>
        <span className='logout__name-title'>{roleRu}</span>
        <span className='logout__name'>{name}</span>
      </p>
    </div>
  );
};

export default LogoutButton;