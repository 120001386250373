const sectionStatuses = {
  1: {
    id: 'new',
    step: 1,
    roles: ['admin', 'careSupport','superOperator', 'operator' ],
    titleRu: 'Новая',
    tagColor: 'processing'
  },
  2: {
    id: 'inProgress',
    step: 2,
    roles: ['admin', 'careSupport','superOperator', 'operator', 'assistant'],
    titleRu: 'В процессе',
    tagColor: 'yellow'
  },
  3: {
    id: 'finished',
    step: 3,
    roles: ['admin', 'careSupport','superOperator', 'operator', 'assistant'],
    titleRu: 'Завершено',
    tagColor: 'orange',
  },
  4: {
    id: 'verified',
    step: 4,
    roles: ['admin', 'careSupport', 'superOperator', 'operator'],
    titleRu: 'Проверено',
    tagColor: 'success'
  }
}

export { sectionStatuses };