import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import getMessagesQuery from '../../Chat/firebase/getMessagesQuery';

const useMessagesData = (dialogueSnapId, messagesPages) => {
  const [ messagesCollSnapshot, messagesLoading, messagesError ] = useCollection(dialogueSnapId ? getMessagesQuery(dialogueSnapId, messagesPages) : null);
  const [ resultMessagesDocSnaps, setResultMessagesDocSnaps ] = useState([])

  useEffect(() => {
    // Сохраняем загруженные данные в стейт. Сразу из хука нельзя брать, т.к. при загрузке возвращает undefined
    if (messagesCollSnapshot) {
      setResultMessagesDocSnaps(messagesCollSnapshot.docs.reverse())
    }
  }, [messagesCollSnapshot])

  return [
    resultMessagesDocSnaps,
    messagesLoading,
    messagesError,
  ]
};

export default useMessagesData;