import React, { useContext } from 'react';
import '../../../assets/application-additionals/application-additionals.scss';
import '../../../assets/index.scss';
import { Collapse } from 'antd';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, orderBy, query } from 'firebase/firestore';
import Spinner from '../../../components/Spinner';
import MyError from '../../Error/components/MyError';
import { ApplicationStatus,  } from '../../../contexts/context';
import getAppInfoCollapseStructure from '../models/getAppInfoCollapseStructure';

const ApplicationAdditionals = ({curApplicationSnap}) => {
  const { accesses } = useContext(ApplicationStatus);
  const [ statusesHistoryData, statusesHistoryLoading, statusesHistoryError ] = useCollectionData(query(collection(curApplicationSnap.ref, 'statuses_history'), orderBy('changedAt','asc')));
  const [ documentsHistoryData, documentsHistoryLoading, documentsHistoryError ] = useCollectionData(query(collection(curApplicationSnap.ref, 'documents_history'), orderBy('changedAt','asc')), {snapshotOptions: {serverTimestamps:'estimate'}});
  const [ assignmentHistoryData, assignmentHistoryLoading, assignmentHistoryError ] = useCollectionData(query(collection(curApplicationSnap.ref, 'assignment_history'), orderBy('changedAt','asc')), {snapshotOptions: {serverTimestamps:'estimate'}});
  const [ priceHistoryData, priceHistoryLoading, priceHistoryError ] = useCollectionData(query(collection(curApplicationSnap.ref, 'price_history'), orderBy('changedAt','asc')), {snapshotOptions: {serverTimestamps:'estimate'}});

  const curApplication = curApplicationSnap.data();
  const items = getAppInfoCollapseStructure(
    {
      statusesHistoryData,
      documentsHistoryData,
      assignmentHistoryData,
      priceHistoryData,
    },
    {
      curApplication,
      appDetailsIsNotDisplayed:accesses.appDetailsIsNotDisplayed,
    }
  )

  if(statusesHistoryLoading || documentsHistoryLoading || assignmentHistoryLoading || priceHistoryLoading) {
    return <Spinner />
  }

  if(statusesHistoryError || documentsHistoryError || assignmentHistoryError || priceHistoryError) {
    return <MyError error={statusesHistoryError || documentsHistoryError || assignmentHistoryError || priceHistoryError}/>
  }

  return (
    <div className="application-additionals__container">
      <Collapse
        bordered={false}
        items={items}
        size={"middle"}
      />
    </div>
  );
};

export default ApplicationAdditionals;
