import React, { useContext } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs'
import prepareChanges from '../helpers/prepareChanges.js';
import { Timestamp } from 'firebase/firestore'
import getResponse from '../helpers/getResponse.js';
import { QuestionnaireContext } from '../../../contexts/context.js';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)


const TypeDateAnswer = ({questionData, questionIndex, personIndex, nestedOptions={isNested:false}}) => {
  const {answersToUpdate, setAnswersToUpdate, isEdit} = useContext(QuestionnaireContext);

  const handleChange = (dayJsObject) => {
    // получаем новый timestamp для firebase из милисекунд, полученных из дейтпикера
    // дейтпикер возвращает dayJsObject, из которого методом valueOf() получаем милисекунды выбранной даты.
    // Если в датапикере нажать крестик для сброса даты - то метод вернет null. Крестик заблокирован сейчас.
    const newTimestamp = Timestamp.fromMillis(dayJsObject.valueOf()) 
    
    setAnswersToUpdate(prepareChanges(answersToUpdate, newTimestamp, questionData, questionIndex, personIndex, nestedOptions))
  }

  const FBtimestamp = getResponse(answersToUpdate, questionData, questionIndex, nestedOptions, personIndex);// FB timestamp;
  const displayedValue = dayjs(FBtimestamp.toMillis()).utc() // получаем дату в UTC формате, иначе часовой пояс может изменить дату.
  return  (
    <DatePicker value={displayedValue} onChange={handleChange} disabled={!isEdit} allowClear={false}/>
  )
};

export default TypeDateAnswer;
