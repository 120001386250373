import { Timestamp } from "firebase/firestore"
import { nanoid } from "nanoid"

const createNewsDataObject = (newNewsText, authorizedUser) => {
  return {
    id: nanoid(),
    content: newNewsText,
    picture: {
      name: '',
      downloadUrl: '',
    },
    files: [],
    createdAt: Timestamp.now(),
    author: {
      id:authorizedUser.id,
      name: authorizedUser.name,
    },
    readers: [],
  }
}

export default createNewsDataObject;