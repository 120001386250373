/**
 * Находит заявку, по статусу готовности. Приоритет 4 > 3 > 2 > 1 > 0. Если незавершенные заявки отсутствуют, возвращает первую заявку с завершенным статусом (5.1 или 5.2)
 * @param {} allClientAppsData
 * @returns
 * @param {} allClientAppsData
 * @returns
 */
const getAppIdWithMaxPreparedStatus = (allClientAppsData) => {
  const maxNumber = 4;
  let saved = {
    appIndex: null,
    status: null,
  }
  allClientAppsData.forEach((app, index) => {
    const appStatus = app.preparedInformation.preparationStatus;
    if(saved.appIndex === null && appStatus <= maxNumber) {
      saved.appIndex = index;
      saved.status = appStatus;
    }

    if(appStatus > saved.status && appStatus <= maxNumber) {
      saved.appIndex = index;
      saved.status = appStatus;
    }
  })

  return saved.appIndex || saved.appIndex === 0 ? allClientAppsData[saved.appIndex].documentID : allClientAppsData.find(app => app.preparedInformation.preparationStatus > maxNumber).documentID;
}

export default getAppIdWithMaxPreparedStatus;
