import { getDateFromTimestamp } from "../../../utils/getDateFromTimestamp"
import { getShortApplicationId } from "../../../utils/getShortApplicationId"

const getApplicationsSwitcherOptions = (clientApplicationsSnapshots) => {
  return clientApplicationsSnapshots.map(appSnap => {
    return {
      label: `${getShortApplicationId(appSnap.id)}-${getDateFromTimestamp(appSnap.get('createdAt'))}`,
      value: appSnap.id,
    }
  })
}

export default getApplicationsSwitcherOptions;
