import React, { memo, useContext, useMemo, useState } from 'react';
import { Layout, Menu } from "antd";
import "../../../assets/aside.scss"
import { useLocation } from 'react-router-dom';
import { UserDataContext } from '../../../contexts/context';
import { useEffect } from 'react';
import checkLocationIsFromSiderMenu from '../../helpers/checkLocationIsFromSiderMenu';
import getItems from '../../helpers/getItems';
const {Sider} = Layout;

const Aside = memo(({ chatListOpen, handleMenuSelect, chatsInNeedOfHelpCount, unreadSectionsCount, totalUnreadMessagesCount, unreadNewsCount}) => {
  const { role } = useContext(UserDataContext);
  const location = useLocation();
  const [ selectedMenuItem, setSelectedMenuItem ] = useState([]);

  const menuItems = useMemo(() => {
    return getItems(role,  totalUnreadMessagesCount, chatsInNeedOfHelpCount, unreadSectionsCount, unreadNewsCount);
  }, [chatsInNeedOfHelpCount,  role, totalUnreadMessagesCount, unreadNewsCount, unreadSectionsCount])

  useEffect(() => {
    // подсвечивает выбранный пункт меню, либо нет, если location не из меню Sider.
    checkLocationIsFromSiderMenu(role, location.pathname) ? setSelectedMenuItem([location.pathname]) : setSelectedMenuItem([]);
  }, [location, role])

  useEffect(() => {
    // подсвечивает поле "Чат" в меню Sider, если открыт GlobalChat
    const index = selectedMenuItem.findIndex(key => {
      return key === '/chat';
    })

    if (chatListOpen && index === -1) {
      setSelectedMenuItem((prev) => [...prev, '/chat'])
    }

    if (!chatListOpen && index !== -1) {
      setSelectedMenuItem((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
    }
  }, [chatListOpen, selectedMenuItem])

  return (
    <Sider className="aside">
      <Menu
        mode="inline"
        theme="dark"
        className='aside__menu'
        selectedKeys={selectedMenuItem}
        items={menuItems}
        onSelect={handleMenuSelect}
      />
    </Sider >
  );
});

export default Aside;
