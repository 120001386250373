import { collection } from "firebase/firestore";
import { firestore } from "../firebase";

export const clientsPath = {
  clients: "users",
}

const getClientsRef = () => {
  return collection(firestore, clientsPath.clients);
}

export default getClientsRef;