const docSectionsConfig = [
  {
    sectionId:0,
        // sectionId:'section-1',
    sectionTitle:'Запись в посольство',
    maxDocs:1,
  },
  {
    sectionId:1,
        // sectionId:'section-2',
    sectionTitle:'Анкета',
    maxDocs:1,
  },
  {
    sectionId:2,
        // sectionId:'section-3',
    sectionTitle:'Бронь отеля 1',
    maxDocs:6,
  },
  {
    sectionId:3,
        // sectionId:'section-4',
    sectionTitle:'Бронь авиа 1',
    maxDocs:6,
  },
  {
    sectionId:4,
        // sectionId:'section-5',
    sectionTitle:'Страховка',
    maxDocs:1,
  },
  {
    sectionId:5,
        // sectionId:'section-6',
    sectionTitle:'План поездки',
    maxDocs:1,
    countriesToDisplay:['de'],
  },
  {
    sectionId:6,
        // sectionId:'section-7',
    sectionTitle:'Письмо для консула',
    maxDocs:1,
    priceToDisplay: 75000,
  },
  {
    sectionId:7,
    sectionTitle:'Другое',
    maxDocs:20,
  }
]

export default docSectionsConfig;