import React from 'react';

const Paginator = ({currentCount, totalCount=0}) => {
  return (
    <div style={{padding:'0.5% 2%', borderRadius:'12px', width: '18%'}}>
      <p style={{textAlign:'center', color: 'rgb(0 0 0 / 44%)'}}>{`Загружено ${currentCount} / ${totalCount}`}</p>
    </div>
  );
};

export default Paginator;
