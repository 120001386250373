import React from 'react';
import AlertDot from '../../../ui/AlertDot';

const ToolBarTitle = ({cardTitle, flagUrl, helping}) => {
  return (
    <div
      style={{ display: "flex", alignItems:"center"}}
    >
      <img
        src={flagUrl}
        height='33px'
        alt="флаг страны"
        style={{display:"block", marginRight:"10px", }}
      />
      {cardTitle}
      {helping ? (
        <AlertDot />
      ) : (
        null
      )}

    </div>
  )
};

export default ToolBarTitle;
