import Attachment from "../components/Attachment";

const getAttachments = (attachments, attachmentsIsLoading) => {
  return attachments.map((attachment, index) => {
    return (
      <Attachment
        key={`${attachment.name}-${index}`}
        attachment={attachment}
        isLoading={attachmentsIsLoading}
      />
    )
  })
}

export default getAttachments;
