import { Timestamp, limit, orderBy, query, where } from "firebase/firestore";
import getClientsRef from "../../../firebase/clients/getClientsRef";
import { getColumnDataBaseProps } from "../models/getClientsByAppsColumns";

const filersDbProps = {
  lastAppCreatedAt: 'lastAppCreatedAt',
  mainAppCountryFullName: 'mainApp.country_name_ru',
  mainAppAssignedTo: 'mainApp.assignedTo',
  assignedTo: 'assignedTo',
  mainAppStatus: 'mainApp.status',
  mainAppPayment: 'mainApp.paymentSuccessful',
  mainAppApplicants: 'mainApp.passportsNum',
  mainAppPrice:'mainApp.price_USD',
}

const getClientsByAppsQuery = (authorizedUser, tableFilters, searchFilters, clientsByAppsPageCount) => {
  const constraints = [
    where('hasCreatedApps', '==', true), // отображаем только тех клиентов, у которых есть заявки, созданные до конца.
  ]
  let orderByLastAppCreatedAtExists = false;

  if(authorizedUser.role === 'operator') {
    constraints.push(where('assignedTo', '==', authorizedUser.id)); // отображаем только тех клиентов, на которых "я" назначен.
  }

  if (searchFilters?.appsSearchValue) {
    if(searchFilters.selectedSearchOption === 'clientId') {
      constraints.push(where('UID', '>=', searchFilters.appsSearchValue))
      constraints.push(where('UID', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("UID"))
    }
    if(searchFilters.selectedSearchOption === 'assignedTo') {
      constraints.push(where('assignedTo', '>=', searchFilters.appsSearchValue))
      constraints.push(where('assignedTo', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("assignedTo"))
    }
    if(searchFilters.selectedSearchOption === 'phone') {
      constraints.push(where('phone', '>=', searchFilters.appsSearchValue))
      constraints.push(where('phone', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("phone"))
    }
    if(searchFilters.selectedSearchOption === 'email') {
      constraints.push(where('email', '>=', searchFilters.appsSearchValue))
      constraints.push(where('email', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("email"))
    }
    if(searchFilters.selectedSearchOption === 'name') {
      constraints.push(where('name', '>=', searchFilters.appsSearchValue))
      constraints.push(where('name', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("name"))
    }
    // при добавлении диапазонных ограничителей, если есть так же и упорядочивающие ограничители - первым идет упорядочивание по свойству, по которому стоит диапазонное ограничение. Затем указываются остальные упорядочивающие.
  }
  const filtersEntries = Object.entries(tableFilters);
  const pathsToFilter = filtersEntries.length ? getColumnDataBaseProps(authorizedUser.role) : [];
  Object.entries(tableFilters).forEach(entry => {
    const key = entry[0];
    const values = entry[1];
    if(values && values.length > 0) {
      if(key === 'mainAppPrice') {
        constraints.push(where(pathsToFilter[key], ">=", +values[0]));
        constraints.push(where(pathsToFilter[key], "<=", +values[1]));
        constraints.push(orderBy(pathsToFilter[key]));
        return
      }
      if(key === 'lastAppCreatedAt') {
        const firebaseTimestamps = values.map(value => Timestamp.fromMillis(value))
        constraints.push(where(pathsToFilter[key], ">=", firebaseTimestamps[0]));
        constraints.push(where(pathsToFilter[key], "<=", firebaseTimestamps[1]));
        constraints.push(orderBy(pathsToFilter[key], 'desc'));
        orderByLastAppCreatedAtExists = true;
        return
      }

      constraints.push(where(pathsToFilter[key], "in", values));
      constraints.push(orderBy(pathsToFilter[key]));
    }
  })

  constraints.push(orderBy('hasUnreadApps', 'desc')) // на самый верх пойдут клиенту у которых есть непросмотренные заявки
  if(!orderByLastAppCreatedAtExists) {
    constraints.push(orderBy('lastAppCreatedAt', 'desc')) // базовая сортировка клиентов по дате последней созданной заявки
  }

  if(clientsByAppsPageCount) {
    constraints.push(limit(50*clientsByAppsPageCount)) // добавляем пагинацию.
  }

  return query(getClientsRef(), ...constraints);
};

export default getClientsByAppsQuery;