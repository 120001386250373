import React from 'react';
import '../../../assets/news/news-info.scss';

const NewsInfo = ({date, authorName}) => {
  return (
    <div className='news-list-item__news-info'>
      <div className='news-list-item__date'>
        {date}
      </div>
      <div className='news-list-item__author-container'>
          Автор: <span className='news-list-item__author-name'>{authorName}</span>
      </div>
    </div>
  );
};

export default NewsInfo;