import React, {useState, useContext, useMemo, useCallback} from "react";
import { Button, Modal, Table } from "antd";
import { OperatorsContext, UserDataContext } from "../../../contexts/context.js";
import accoutsRowClassName from "../helpers/accoutsRowClassName.js";
import '../../../assets/accounts/accounts.scss';
import getOperatorsTableData from "../helpers/getOperatorsTableData.js";
import getAccountsTableColumns from "../helpers/getAccountsTableColumns.js";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import getEmployeesQuery from "../firebase/getEmployeesQuery.js";
import MyError from "../../Error/components/MyError.jsx";
import Spinner from "../../../components/Spinner.jsx";
import NewOperator from "./NewOperator.jsx";
import DeactivateOperator from "./DeactivateOperator.jsx";
import { useEffect } from "react";

const modalStyle = {
  mask: {backgroundColor:"#0000009C"},
}

const Accounts = () => {
  const [ actionType, setActionType ] = useState(null)
  const [ deactivatedOperatorId, setDeactivatedOperatorId ] = useState(null);
  const [ deactivatedOperatorName, setDeactivatedOperatorName ] = useState(null);
  const [ popupIsOpened, setPopupIsOpened ] = useState(false);
  // const [ tableData, setTableData ] = useState([]);
  const { role, features } = useContext(UserDataContext);
  const [ employeesData, employeesLoading, employeesError, employeesCollSnap, reloadEmployees ] = useCollectionDataOnce(getEmployeesQuery());

  // useEffect(() => {
  //   if(employeesData) {
  //     setTableData(getOperatorsTableData(employeesData));
  //   }
  // }, [employeesData])

  const tableData = useMemo(() => {
    if(employeesData) {
      return getOperatorsTableData(employeesData)
    } else {
      return
    }
  }, [employeesData])

  const columns = useMemo(() => {
    return getAccountsTableColumns(role)
  }, [role])

  const openPopupToCreateNewOperator = useCallback(() => {
    setActionType('createOperator');
    setPopupIsOpened(true);
  }, [])

  const closePopup = useCallback(() => {
    setPopupIsOpened(false);
  }, [])

  const context = useMemo(() => ({
    actionType,
    setActionType,
    deactivatedOperatorId,
    setDeactivatedOperatorId,
    deactivatedOperatorName,
    setDeactivatedOperatorName,
    popupIsOpened,
    setPopupIsOpened,
    reloadEmployees,
  }), [actionType, deactivatedOperatorId, deactivatedOperatorName, popupIsOpened, reloadEmployees])

  if(employeesLoading) {
    return <Spinner />
  }

  if(employeesError) {
    return <MyError error={employeesError} />
  }

  return (
    <OperatorsContext.Provider value={context}>
      <div
        className='accounts'
      >
        <div className='accounts__container'>
          {features.canCreateAccount ? (
            <div className='new-operator-button__container' >
              <Button type="primary" block="false" onClick={openPopupToCreateNewOperator}>Новый визовик</Button>
            </div>
          ) : (
            null
          )}
          <Table
            loading={employeesLoading}
            // columns={getAccountsTableColumns(role)}
            columns={columns}
            dataSource={tableData}
            // dataSource={getOperatorsTableData(employeesData)}
            rowKey="id"
            rowClassName={accoutsRowClassName}
            pagination={false}
            size='small'
          />
        </div>
        <Modal
          styles={modalStyle}
          open={popupIsOpened}
          onCancel={closePopup}
          footer={null}
          width="38%"
          destroyOnClose={true}
          // centered={true}
          wrapClassName='popup__contaier'
        >
          {actionType === 'createOperator' && <NewOperator/>}
          {actionType === 'deactivateOperator' && <DeactivateOperator />}
        </Modal>
      </div>
    </OperatorsContext.Provider>
  )
}

export default Accounts;
