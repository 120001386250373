import dayjs from "dayjs";

const combineIssuesTableData = (issuesData) => {
  return issuesData.map(issue => {
    return {
      key: issue.id,
      time: dayjs(issue.createdAt.toMillis()).format('DD/MM/YYYY HH:mm') ,
      UID: issue.client.UID,
      issueId: issue.id,
      supportId: issue.support.id,
      clientName: issue.client.name || issue.client.UID.slice(0,4),
      status: issue.status,
      totalApps: issue.totalApps,
    }
  })
}

export default combineIssuesTableData;