import { sectionStatuses } from "../models/sectionStatuses";

const getSectionStatusOptions = (role, curStep) => {
  const keys = Object.keys(sectionStatuses);
  return keys.reduce((acc, key) => {
    const sectionStatus = sectionStatuses[key]
    if(role === 'assistant' && sectionStatus.step < curStep) return acc;
    const statusObj = {
      value: sectionStatus.step,
      label: sectionStatus.titleRu,
    }
    if(!sectionStatus.roles.includes(role)) {
      statusObj.disabled = true;
    }
    acc.push(statusObj);
    return acc;
  }, [])
}

export default getSectionStatusOptions;