import React, { useContext, useState } from 'react';
import DetailPricesItem from './DetailPricesItem';
import { Button, Divider } from 'antd';
import '../../../assets/application-additionals/details-prices.scss';
import declination from '../../../utils/declination';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import GLOBAL_ROLES from '../../../constants/constants';
import ChangePriceForm from './ChangePriceForm';
import changePrice from '../firebase/changePrice';
import showNotification from '../../NotificationService/helpers/showNotification';

const DetailsPrices = ({curApplication, applicantsNumber}) => {
  const { features, role } = useContext(UserDataContext);
  const { authorizedUser } = useContext(UserDataContext);
  const { notificationAPI } = useContext(ProgramContext);
  const [ changePriceOpened, setChangePriceOpened ] = useState(false);
  const [ priceSaving, setPriceSaving ] = useState(false);

  const consularFee = curApplication.type.tourist * applicantsNumber; // TODO: потенциальная ошибка. Когда появятся заявки с другими типами виз - надо менять БД и тут рефакторить будет.
  const VKPackagePrice = curApplication.service.price_USD * applicantsNumber;
  const VKPackageName = curApplication.service.name;
  const totalPrice = curApplication.price_USD;
  const declWord = declination(applicantsNumber, ['заявитель', "заявителя", "заявителей"]);

  const cancelChanges = () => {
    setChangePriceOpened(false);
  }

  const handleChangePriceOpen = () => {
    setChangePriceOpened(true);
  }

  const handleChangePrice = async (newPrice, comment) => {
    try {
      setPriceSaving(true);
      await changePrice(
        curApplication.price_USD,
        authorizedUser.name,
        +newPrice,
        comment,
        curApplication.documentID,
      )
      showNotification(notificationAPI, 'process', {processName: 'changePrice', status:'success'})
      setChangePriceOpened(false);
    } catch (error) {
      console.log(error);
      if(error.name === 'PermissionError') {
        showNotification(notificationAPI, 'process', {processName: 'noPermission', status:'error'})
        return;
      }
      showNotification(notificationAPI, 'process', {processName: 'changePrice', status:'error'})
    } finally {
      setPriceSaving(false);
    }
  }

  return (
    <div className='details-prices'>
      <ul className='details-prices__list'>
        <li className='details-prices__list-item'>
          <p className='details-prices__detail-name'>Консульский сбор: </p>
          <div className='details-prices__detail-price'>
            {consularFee} КЗТ
            <span className='postfix-applicants-num'>за {applicantsNumber} {declWord}</span>
          </div>
        </li>
        {role !== GLOBAL_ROLES.operator ? (
          <li className='details-prices__list-item'>
            <p className='details-prices__detail-name'>
              Оформление VK:
              <span className='details-prices__package-name'>Пакет: {VKPackageName}</span>
            </p>
            <div className='details-prices__detail-name'>
              {VKPackagePrice} КЗТ
              <span className='postfix-applicants-num'>за {applicantsNumber} {declWord}</span>
            </div>
          </li>
        ) : (
          null
        )}
      </ul>
      {features.showPrice ? (
        <>
          <Divider />
          <div className='details-prices__list-item'>
            <p className="details-prices__total-price-name">Общая сумма: </p>
            <div className='details-prices__total-price-value'>
              {totalPrice} КЗТ
            </div>
          </div>
          {features.canChangeAppPrice ? (
            <div className='details-prices__change-price-container'>
              <Button type='link' onClick={handleChangePriceOpen}>Изменить сумму</Button>
            </div>
          ) : null }
          {features.canChangeAppPrice && changePriceOpened ? (
            <ChangePriceForm cancelChanges={cancelChanges} handleChangePrice={handleChangePrice} priceSaving={priceSaving}/>
          ) : null}
        </>
      ):(
        null
      )}

    </div>
  );
};

export default DetailsPrices;