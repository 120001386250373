import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { useEffect } from 'react';
import { UserDataContext } from '../../../contexts/context';
import getClientsQuery from '../firebase/getClientsByAppsQuery';
import MyError from '../../Error/components/MyError';
import { AllOperatorsContext, AppsSearchFiltersContext, ClientsByAppsPagesContext, LayoutFuncsContext } from '../../../contexts/layoutContexts';
import { getCountFromServer } from 'firebase/firestore';
import { Breadcrumb, Button, Table } from 'antd';
import Paginator from '../../../ui/Paginator';
import getClientsByAppsTableData from '../models/getClientsByAppsTableData';
import ColumnSelector from '../../../components/ColumnSelector/ColumnSelector';
import saveToLocalStorage from '../../../localStorage/saveToLocalStorage';
import getClientsByAppsQuery from '../firebase/getClientsByAppsQuery';
import getAllCountriesRef from '../../../firebase/countries/getAllCountriesRef';
import '../../../assets/clients-by-apps/clients-by-apps.scss'
import getClientsByAppsColumns, { getDefaultSetOfColumns, getListOfColsWithFilters, setOfColumns } from '../models/getClientsByAppsColumns';
import getFromLocalStorage from '../../../localStorage/getFromLocalStorage';
import useEmployeesPartialData from '../../../useEmployeesPartialData';
import removeFilterFromLocalStorage from '../../../localStorage/removeFilterFromLocalStorage';

const ALL_COUNTRIES_REF = getAllCountriesRef();

const handleRowClassName = (record, index) => {
  let rowClass = ''
  if(record.hasUnreadApps) rowClass += ' is-new';
  return rowClass;
}

const getDefaultFilters = (authorizedUser, filtersType) => {
  const storagedFilters = getFromLocalStorage(authorizedUser.id, filtersType);
  const listOfColsWithFilters = getListOfColsWithFilters(authorizedUser.role);
  return listOfColsWithFilters.reduce((acc, columnKey) => {
    acc[columnKey] = storagedFilters?.[columnKey]
    return acc;
  }, {})
}

const ClientsByApps = memo(() => {
  // contexts
  const { authorizedUser, role } = useContext(UserDataContext);
  const { clientsByAppsPageCount } = useContext(ClientsByAppsPagesContext)
  const { addClientsByAppsPage, resetClientsByAppsPageNum} = useContext(LayoutFuncsContext);
  const { searchFilters } = useContext(AppsSearchFiltersContext);
  const { allOperators } = useContext(AllOperatorsContext)
  // states
  const [ clientByAppsResultData, setClientByAppsResultData ] = useState([]);
  const [ totalAppsCount, setTotalAppsCount ] = useState(0);
  const columnsType = 'clientsByAppsColumns';
  const tableFiltersType = 'clientsByAppsFilters';
  const [ tableFilters, setTableFilters ] = useState(() => getDefaultFilters(authorizedUser, tableFiltersType));
  const [ columnsToDisplay, setColumnsToDisplay ] = useState (() => setOfColumns(authorizedUser, false, columnsType));
  const [ timer, setTimer ] = useState(0)
  // download data
  const [ clientsByApps, clientsByAppsLoading, clientsByAppsError, clientsByAppsCollSnap ] = useCollectionData(getClientsByAppsQuery(authorizedUser, tableFilters, searchFilters, clientsByAppsPageCount));
  const [ countriesData, countriesLoading, countriesError, countriesDocSnapshot ] = useDocumentData(ALL_COUNTRIES_REF);
  const [ employeesData, employeesLoading, employeesError ] = useEmployeesPartialData(false);
  
  const columns = useMemo(() => {
    return getClientsByAppsColumns(columnsToDisplay, tableFilters, countriesData?.countries, employeesData)
  }, [columnsToDisplay, countriesData?.countries, employeesData, tableFilters])

  const hideOrShowColumn = (value, node, extra) => {
    const newColumnsToDisplay = columnsToDisplay.map(col => {
      if(col.children) {
        const newChildren = col.children.map(nestedCol => {
          if(nestedCol.value === value) {
            if(nestedCol.isSelected) { // сбросить фильтр, если убрали колонку.
              setTableFilters(filters => {
                return {...filters, [value]: null}
              })
              removeFilterFromLocalStorage(value, authorizedUser.id, tableFiltersType);
            }
            return {...nestedCol, isSelected: !nestedCol.isSelected}
          }
          return nestedCol;
        })
        return {...col, children: newChildren}
      }
      if(col.value === value) {
        if(col.isSelected) { // сбросить фильтр, если убрали колонку.
          setTableFilters(filters => {
            return {...filters, [value]: null}
          })
          removeFilterFromLocalStorage(value, authorizedUser.id, tableFiltersType)
        }
        return {...col, isSelected: !col.isSelected}
      } else {
        return col
      }
    });
    setColumnsToDisplay(newColumnsToDisplay);
    saveToLocalStorage(authorizedUser.id, newColumnsToDisplay, columnsType)
  }

  const resetColumns = () => {
    const defaultColumnsToDisplay = setOfColumns(authorizedUser, true)
    setColumnsToDisplay(defaultColumnsToDisplay);
    saveToLocalStorage(authorizedUser.id, defaultColumnsToDisplay, columnsType);
  }

  const handleTableChange = useCallback((pagination, filters, sorter, {action}) => {
    if(action === 'filter') {
      Object.entries(filters).forEach(entry => {
        const newFilters = {...tableFilters, [entry[0]]: entry[1]}
        setTableFilters(newFilters)
        saveToLocalStorage(authorizedUser.id, newFilters, tableFiltersType);
        resetClientsByAppsPageNum();
      })
    }
  }, [authorizedUser.id, resetClientsByAppsPageNum, tableFilters])

  useEffect(() => {
    if(clientsByAppsCollSnap && !clientsByAppsLoading && countriesData) {
      // if(!clientsByAppsCollSnap.metadata.fromCache) {
        setClientByAppsResultData(getClientsByAppsTableData(clientsByApps, countriesData.countries, allOperators));
      // }
    }
  },[allOperators, clientsByApps, clientsByAppsCollSnap, clientsByAppsLoading, countriesData])

  useEffect(() => {
    // получаение общего количества возможных заявок
    const getAllAppsCount = async () => {
      const queryForAppsWithoutLimit = getClientsByAppsQuery(authorizedUser, tableFilters, searchFilters);
      const aggregateSnapshot = await getCountFromServer(queryForAppsWithoutLimit);
      setTotalAppsCount(aggregateSnapshot.data().count);
    }
    getAllAppsCount();
  }, [authorizedUser, searchFilters, tableFilters])

  // включить, когда появится время ожидания ответа оператора.
  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     setTimer(prev => ++prev);
  //   }, 5000)
  //   return () => clearInterval(timerId);
  // }, [])

  if(clientsByAppsError) {
    return <MyError error={clientsByAppsError} />
  }

  const downloadMoreClients = () => {
    addClientsByAppsPage();
  }
  return (
    <div className='clients-by-apps'>
      <Breadcrumb
        items
      />
      <h1 className='clients-by-apps__title'>Клиенты с заявками</h1>
      <div style={{display:"flex", flexDirection:'row-reverse'}}>
        <Paginator currentCount={clientByAppsResultData.length} totalCount={totalAppsCount}/>
        <ColumnSelector
          columnsToDisplay={columnsToDisplay}
          hideOrShowColumn={hideOrShowColumn}
          resetColumns={resetColumns}
          groups='hasGroups'
        />
      </div>
      <Table
        columns={columns}
        dataSource={clientByAppsResultData}
        pagination={false}
        size="small"
        bordered
        rowClassName={handleRowClassName}
        onChange={handleTableChange}
      />
      <div style={{display:'flex', justifyContent: 'flex-end', width: '100%'}}>
        <Paginator currentCount={clientByAppsResultData.length} totalCount={totalAppsCount}/>
      </div>
      <div style={{display:'flex', justifyContent:'center', marginTop: '1%'}} >
        <Button
          loading={clientsByAppsLoading}
          onClick={downloadMoreClients}
          disabled={totalAppsCount === clientByAppsResultData.length}
        >
          Загрузить еще
        </Button>
      </div>
    </div>
  )
});

export default ClientsByApps;