import { Timestamp, serverTimestamp, updateDoc } from "firebase/firestore";
import getAdminDocRef from "../../../firebase/admins/getAdminDocRef";

const readUnreadNews = (userId, lastNewsViewTime, newsCollSnap) => {
  let allNewsAreRead = true;
  for(const newsDocSnap of newsCollSnap.docs) {
    if(lastNewsViewTime.toMillis() < newsDocSnap.get('createdAt').toMillis()) {
      allNewsAreRead = false
      break;
    }
  }
  if(!allNewsAreRead) {
    updateDoc(getAdminDocRef(userId), {lastNewsViewTime: Timestamp.now()}).catch(error => console.log(error))
  }

};

export default readUnreadNews;