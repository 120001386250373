import React from 'react';
import FileList from '../../../../components/FileList/FileList';
import '../../../../assets/news/list-item-inner/news-list-item-files.scss';


const NewsListItemFiles = ({fileList, allowDeletion}) => {
  return (
    <div className='news-list-item-files__container'>
      <FileList
        fileList={fileList}
        allowDeletion={allowDeletion}
      />
    </div>
  );
};

export default NewsListItemFiles;