import React from 'react';
import ImageUpload from '../../../../components/ImageUpload';
import { EditOutlined } from "@ant-design/icons";
import '../../../../assets/news/editor/news-editor-header.scss';
import NewsInfo from '../NewsInfo';


const NewsEditorHeader = ({date, authorName, fileList, beforeHeadPictureUpload, setIsEdit}) => {

  const editHandler = () => {
    setIsEdit(false);
  }

  return (
    <div className='news-editor__header'>
      <NewsInfo
        date={date}
        authorName={authorName}
      />
      <div className='news-editor__buttons'>
        <>
          <ImageUpload
            fileList={fileList}
            beforeUploadHandler={beforeHeadPictureUpload}
          />
          <EditOutlined
            className="news-editor__edit-icon interactive-icons"
            style={{ fontSize: '22px', color: '#08c'}}
            onClick={editHandler}
          />
        </>
      </div>
    </div>
  );
};

export default NewsEditorHeader;