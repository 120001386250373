import React, { useContext } from 'react';
import '../../../assets/application-additionals/documents-history/documents-history-item.scss';
import dayjs from 'dayjs';
import { ProgramContext } from '../../../contexts/context';
import { formatBytes } from '../../../utils/formatBytes';

const DocumentsHistoryItem = ({entry}) => {
  const fullDate = dayjs(entry.changedAt.toMillis());
  const date =  fullDate.format('DD.MM.YYYY');
  const time = fullDate.format('HH:mm');
  const operationType = entry.operationType === 'upload' ? 'загружено' : 'удалено';
  const size = entry.size === '' ? '' : formatBytes(entry.size);

  return (
    <li className='documents-history__item'>
      <p className="documents-history__doc-name">{entry.documentName}</p>
      <p className="documents-history__size">{size}</p>
      <p className="documents-history__operator-name">{entry.operatorName}</p>
      <p className="documents-history__date">{date}</p>
      <p className="documents-history__time">{time}</p>
      <p className="documents-history__operation-type">{operationType}</p>
    </li>
  );
};

export default DocumentsHistoryItem;
