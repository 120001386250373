import React, { memo } from 'react';
import { Input } from 'antd';
import { useChatSearchContext, useUpdateMessengerContext } from '../context/MessengerDataProvider';
const { Search } = Input;

const searchStyle = {
  width:'100%',
}

const DialogueSearch = memo(() => {
  const { setChatsSearchFilter } = useUpdateMessengerContext()
  const { chatsSearchFilter } = useChatSearchContext()

  const handleValueChange = (e) => {
    setChatsSearchFilter(e.target.value);
 }

  return (
    <Search
      allowClear
      size={"large"}
      onChange={handleValueChange}
      value={chatsSearchFilter}
      style={searchStyle}
    />
  );
});

export default DialogueSearch;
