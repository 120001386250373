import { Link } from "react-router-dom";
import GLOBAL_ROLES from "../../../constants/constants";
import TableStatusTag from "../../../components/TableStatusTag";
import PaymentIcon from "../../../components/PaymentIcon";
import AssignOperator from "../../AssignOperator/components/AssignOperator";
import getFromLocalStorage from "../../../localStorage/getFromLocalStorage";
import getAssignedToFilterData from "../../../models/getAssignedToFilterData";
import AssignedToCustomizedFilter from "../../../components/AssignedToTableFilter/AssignedToCustomizedFilter";
import DatesRangeTableFilter from "../../../components/DatesRangeTableFilter/DatesRangeTableFilter";
import EmployeeItem from "../../../components/EmployeeItem";
import getCitizenshipFilters from "../../../models/getCitizenshipFilters";

const { admin, careSupport, superOperator, operator } = GLOBAL_ROLES;

const idStyle = { color:"#0EA5E9", fontWeight:"800" }

const createStatusTag = (status) => {
  return <TableStatusTag status={status}/>;
}

const renderPaymentIcon = (text) => {
  return <PaymentIcon payment={text} />
}

const searchTextFilter = (input, record) => {
  return record.text.toLowerCase().includes(input.toLowerCase())
}

const testStucture = [
  {
    key:'UIDshort',
    filter: {
      hasFilter: false
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: true,
    config: {
      onHeaderCell: (column) => {
        column.className = 'table-group'
      },
      title: 'ID клиента',
      dataIndex: 'UIDshort',
      key: 'UIDshort',
      align: "center",
      // width: 70,
      render: (text, record, _) => {
        return (
          <Link
            to={`/client-apps/${record.UID}`}
            style={idStyle}
          >
            {text}
          </Link>
        )
      },
    }
  },
  {
    key:'applicant',
    filter: {
      hasFilter: false
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: true,
    config: {
      title: 'Клиент',
      dataIndex: 'applicant',
      key: 'applicant',
      align: "center",
      ellipsis: true,
    }
  },
  {
    key:'citizenship',
    filter: {
      hasFilter: true,
      dbPath: 'citizenship',
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: true,
    config: {
      title: 'Гражданство',
      dataIndex: 'citizenship',
      key: 'citizenship',
      align: "center",
      filters: getCitizenshipFilters(),
      filterSearch: searchTextFilter,
    }
  },
  {
    key:'email',
    filter: {
      hasFilter: false
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: false,
    config: {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: "center",
      ellipsis: true,
      // width: "200px",
    }
  },
  {
    key:'phone',
    filter: {
      hasFilter: false
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: false,
    config: {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      align: "center",
    }
  },
  {
    key:'awaitTime',
    filter: {
      hasFilter: false
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: false,
    config: {
      title: 'Ожидание',
      dataIndex: 'awaitTime',
      key: 'awaitTime',
      align: "center",
      // ellipsis: true,
    }
  },
  {
    key:'lastAppCreatedAt',
    filter: {
      hasFilter: true,
      dbPath: 'lastAppCreatedAt',
    },
    role: [admin, careSupport, superOperator, operator],
    isSelectedDefault: true,
    config: {
      title: 'Дата последней заявки',
      dataIndex: 'lastAppCreatedAt',
      key: 'lastAppCreatedAt',
      align: "center",
      className: 'time-column',
      filterDropdown: (props) => <DatesRangeTableFilter filterProps={props}/>,
    }
  },
  {
    key:'assignedTo',
    filter: {
      hasFilter: true,
      dbPath: 'assignedTo',
    },
    role: [admin, careSupport, superOperator],
    isSelectedDefault: true,
    config: {
      title: 'Ответственный',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      align: "center",
      ellipsis:true,
      className: 'time-column',
      filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
      render: (_, record, _index) => {
        return (
          <AssignOperator
            clientId={record.UID}
            assignedTo={record.assignedTo}
          />
        )
      },
    }
  },
  {
    config: {
      title: 'Основная заявка',
      key: 'mainApp',
    },
    children: [
      {
        key:'mainAppPrice',
        filter: {
          hasFilter: false,
          dbPath: 'mainApp.price_USD',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title:'Сумма',
          dataIndex: 'mainAppPrice',
          key: 'mainAppPrice',
          align: 'center',
          // filterDropdown: props => (
          //   <NumbersRangeTableFilter
          //     filterProps={props}
          //   />
          // )
        }
      },
      {
        key:'mainAppStatus',
        filter: {
          hasFilter: false,
          dbPath: 'mainApp.status',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title: 'Статус',
          dataIndex: 'mainAppStatus',
          key: 'mainAppStatus',
          align: "center",
          ellipsis: true,
          width: 90,
          // render: createStatusTag,
          // filters: getStatusesFilters(),
        },
      },
      {
        key:'mainAppCountryFullName',
        filter: {
          hasFilter: false,
          dbPath: 'mainApp.country_code',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title: 'Страна',
          dataIndex: 'mainAppCountryFullName',
          key: 'mainAppCountryFullName',
          align: "center",
          // ellipsis: true,
          // filters:[], // запишется после загрузки стран в функции getColumnsConfig.
          filterSearch: searchTextFilter,
        }
      },
      {
        key:'mainAppIIN',
        filter: {
          hasFilter: false,
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: false,
        config: {
          title: 'ИИН',
          dataIndex: 'mainAppIIN',
          key: 'mainAppIIN',
          align: "center",
        }
      },
      {
        key:'mainAppPaymentDate',
        filter: {
          hasFilter: false,
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title: 'Дата оплаты',
          dataIndex: 'mainAppPaymentDate',
          key: 'mainAppPaymentDate',
          align: "center",
        }
      },
      {
        key:'mainAppApplicants',
        filter: {
          hasFilter: false,
          dbPath: 'mainApp.passportsNum',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title: '№ заявителей',
          dataIndex: 'mainAppApplicants',
          key: 'mainAppApplicants',
          align: "center",
          // width:85,
          ellipsis: true,
          // filterDropdown: props => <ApplicantsNumTableFilter filterProps={props}/>,
          // filters: []
        }
      },
      {
        key:'mainAppPayment',
        filter: {
          hasFilter: false,
          dbPath: 'mainApp.paymentSuccessful',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title:'Оплата',
          dataIndex: 'mainAppPayment',
          key: 'mainAppPayment',
          align: 'center',
          width: 90,
          render: renderPaymentIcon,
          // filters: getPaymentFilters(),
        }
      },
      {
        key:'mainAppAssignedTo',
        filter: {
          hasFilter: true,
          dbPath: 'mainApp.assignedTo',
        },
        role: [admin, careSupport, superOperator, operator],
        isSelectedDefault: true,
        config: {
          title: 'Ответственный',
          dataIndex: 'mainAppAssignedTo',
          key: 'mainAppAssignedTo',
          align: "center",
          ellipsis: true,
          filterMode: 'tree',
          render:(employee) => <EmployeeItem name={employee?.name} role={employee?.role}/>,
          filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
        }
      }
    ]
  }
]

const getDefaultSetOfColumns = (role) => {
  // добавить проверку на отображение по дефолту
  return testStucture.reduce((outer, column, i) => {
    if(!column.children && !column.role.includes(role)) return outer;
    const option = {
      title: column.config.title,
      value: column.config.key,
      isSelected: column.isSelectedDefault,
      index: i,
    }
    if(column.children) {
      const children = column.children.reduce((inner, nestedColumn, j) => {
        if(!nestedColumn.role.includes(role)) return inner;
        inner.push({
          title: nestedColumn.config.title,
          value: nestedColumn.config.key,
          isSelected: nestedColumn.isSelectedDefault,
          index: j,
        })
        return inner;
      },[])
      option.children = children;
      option.selectable=false;
      option.checkable = false;
    }
    outer.push(option);
    return outer;
  }, [])
}

export const setOfColumns = (authorizedUser, needReset, columnsType) => {
  const { role, id } = authorizedUser;
  if(role === 'assistant') return;
  if(!needReset) {
    const storagedSetOfColumns = getFromLocalStorage(id, columnsType);
    return storagedSetOfColumns || getDefaultSetOfColumns(role);
  } else {
    return getDefaultSetOfColumns(role);
  }
}

const getClientsByAppsColumns = (columnsToDisplay, tableFilters, countriesData, employeesData) => {
  return columnsToDisplay.reduce((acc, column) => {
    if(column.isSelected === false) return acc; // проверка на false, т.к. может быть и undefined (тогда эта колонка группа и она нам нужна).
    if(column.isSelected === undefined) { // значит это групповая колонка
      const columnChildren = column.children.reduce((acc, nestedColumn) => {
        if(!nestedColumn.isSelected) return acc;
        // if(nestedColumn.value === 'mainAppCountryFullName') {
        //   acc.push({...testStucture[column.index].children[nestedColumn.index].config, filters: getCountriesFilters(countriesData), defaultFilteredValue: tableFilters.mainAppCountryFullName})
        //   return acc;
        // }
        if(nestedColumn.value === 'mainAppAssignedTo') {
          acc.push({...testStucture[column.index].children[nestedColumn.index].config, filters: getAssignedToFilterData('assignedEmployeesFilter', employeesData), defaultFilteredValue: tableFilters.mainAppAssignedTo })
          return acc;
        }
        // if(nestedColumn.value === 'mainAppPrice') {
        //   acc.push({...testStucture[column.index].children[nestedColumn.index].config, defaultFilteredValue: tableFilters.mainAppPrice})
        //   return acc;
        // }
        // if(nestedColumn.value === 'mainAppStatus') {
        //   acc.push({...testStucture[column.index].children[nestedColumn.index].config, defaultFilteredValue: tableFilters.mainAppStatus})
        //   return acc;
        // }
        // if(nestedColumn.value === 'mainAppApplicants') {
        //   acc.push({...testStucture[column.index].children[nestedColumn.index].config, defaultFilteredValue: tableFilters.mainAppApplicants})
        //   return acc;
        // }
        // if(nestedColumn.value === 'mainAppPayment') {
        //   acc.push({...testStucture[column.index].children[nestedColumn.index].config, defaultFilteredValue: tableFilters.mainAppPayment})
        //   return acc;
        // }

        acc.push(testStucture[column.index].children[nestedColumn.index].config)
        return acc;
      },[])
      const group = {
        title: column.title,
        children: columnChildren,
      }
      acc.push(group)
      return acc;
    }
    // простая колонка
    if(column.value === 'lastAppCreatedAt') {
      acc.push({...testStucture[column.index].config, defaultFilteredValue: tableFilters.lastAppCreatedAt})
      return acc;
    }
    if(column.value === 'assignedTo') {
      acc.push({...testStucture[column.index].config, filters: getAssignedToFilterData('assignedEmployeesFilter', employeesData), defaultFilteredValue: tableFilters.assignedTo})
      return acc;
    }
    if(column.value === 'citizenship') {
      acc.push({...testStucture[column.index].config, defaultFilteredValue: tableFilters.citizenship})
      return acc;
    }
    acc.push(testStucture[column.index].config)
    return acc;
  }, [])
}

/**
 * Возвращает массив ключей тех колонок, которые содержат фильтр.
 * @returns string[]
 */
export const getListOfColsWithFilters = (role) => {
  return testStucture.reduce((acc, column) => {
    if(column.children) {
      column.children.forEach(columnInner => {
        if(!columnInner.role.includes(role)) return
        if(columnInner.filter.hasFilter) {
          acc.push(columnInner.key)
        }
      })
    } else {
      if(!column.role.includes(role)) return acc;
      if(column.filter.hasFilter) {
        acc.push(column.key);
      }
    }
    return acc;
  },[])
}

/**
 * Возращает карту соответствий колонок, у которых есть фильтры и свойств в БД по которым нужно фильтровать.
 * @returns
 */
export const getColumnDataBaseProps = (role) => {
  return testStucture.reduce((acc, column) => {
    if(column.children) {
      column.children.forEach(columnInner => {
        if(columnInner.role.includes(role) && columnInner.filter.hasFilter) {
          acc[columnInner.key] = columnInner.filter.dbPath
        }
      })
    } else {
      if(!column.role.includes(role)) return acc;
      if(column.filter.hasFilter) {
        acc[column.key] = column.filter.dbPath;
      }
    }
    return acc;
  }, {})
}

export default getClientsByAppsColumns;
