const createExtraFileList = (docs) => {
  if(!docs.length) {
    return [];
  }
  // отображение списка загруженных файлов требует немного других свойств с теми же данными + новые.
  return docs.map(doc => {
    const {name, uid, downloadURL:url, link } = doc;
    return {
      name,
      uid,
      url,
      link,
      status: 'done',
    }
  })
}

export default createExtraFileList;