import { getCountFromServer } from 'firebase/firestore';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getAllFieldsFromDocSnapshot } from '../helpers/getAllFieldsFromDocSnapshot';

const useCount = (query) => {
  const [ count, setCount ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const getCount = async () => {
      try {
        const aggregateSnapshot = await getCountFromServer(query);
        setCount(getAllFieldsFromDocSnapshot(aggregateSnapshot).count)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false);
      }
    }
    getCount()
  },[query])

  return [
    count, loading, error,
  ]
};

export default useCount;