
/**
 * Получает response вопроса для отображения и дальнейшего использования. 
 * Учитывает:
 * 1) редактировался ли вопрос
 * 2) является ли вопрос подвопросом дропдауна
 * 3) требует ли вопрос ответов от каждого заявителя отдельно
 * @param {*} answersToUpdate - массив, собирающий изменяемые вопросы [{
 *   newResponse, - полный response вопроса (всех заявителей, если вопрос персональный)
 *   index - индекс вопроса из общего списка вопросов.
 * },]
 * @param {*} questionData - объект question для вопроса или кастомный question для подвопроса (Создается самим дропдауном при отрисовке подвопроса)
 * @param {*} questionIndex - индекс вопроса из общего списка вопросов
 * @param {*} nestedOptions - вспомогательная информация для подвопросов дропдауна. {
 *   isNested, - если true - значит это подвопрос дропдауна. Хранится в дропдауне - response. или response[индекс заявителя]. + answers[индекс подвопроса]
 *   nestedQuestionIndex, - индекс подвопроса из массива answers
 *   dropdownQuestionData, - question самого дропдауна
 * }
 * @param {*} personIndex - num / null. Если передан, значит вопрос персональный для каждого заявителя. Нет - общий.
 * @returns - response вопроса (который может являться общим или персональным, а так же подвопросом дропдауна)
 */
const getResponse = (answersToUpdate, questionData, questionIndex, nestedOptions=null, personIndex) => {
  const mainQuestionIsPersonal = personIndex !== null;
  const alreadyChangedQuestion = answersToUpdate.find(question => question.index === questionIndex);
  let response;

  if (alreadyChangedQuestion) {
    // вопрос редактировался
    if(nestedOptions?.isNested) {
      // подвопрос дропдауна
      if (mainQuestionIsPersonal) {
        // основной вопрос - персональный
        const personalDropdown = alreadyChangedQuestion.newResponse[personIndex];
        response = personalDropdown.answers[nestedOptions.nestedQuestionIndex].answer
      } else {
        // основной вопрос - общий
        response = alreadyChangedQuestion.newResponse.answers[nestedOptions.nestedQuestionIndex].answer;
      }
    } else {
      // вопрос самостоятельный
      if (mainQuestionIsPersonal) {
        // основной вопрос - персональный
        response = alreadyChangedQuestion.newResponse[personIndex];
      } else {
        // основной вопрос - общий
        response = alreadyChangedQuestion.newResponse;
      }
    }
  } else {
    // вопрос не редактировался
    if(nestedOptions?.isNested) {
      // подвопрос дропдауна
      if (mainQuestionIsPersonal) {
        // основной вопрос - персональный
        const personalDropdown = nestedOptions.dropdownQuestionData.response[personIndex];
        response = personalDropdown.answers[nestedOptions.nestedQuestionIndex].answer;
      } else {
        // основной вопрос - общий
        response = nestedOptions.dropdownQuestionData.response.answers[nestedOptions.nestedQuestionIndex].answer;
      }
    } else {
      // вопрос самостоятельный
      if (mainQuestionIsPersonal) {
        // основной вопрос - персональный
        response = questionData.response[personIndex];
      } else {
        // основной вопрос - общий
        response = questionData.response;
      }
    }
  }
  return response
}

export default getResponse;
