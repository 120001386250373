import React, { memo } from 'react';
import '../../../assets/loading.scss';
import DialogueListItem from './DialogueListItem';
import DialoguesEmpty from './DialoguesEmpty';
import { useChatsContext, useSelectedChatContext } from '../context/MessengerDataProvider';

const DialoguesList = memo(({openDialogue}) => {
  const { chatsData } = useChatsContext();
  const { selectedChat } = useSelectedChatContext();

  if(chatsData && chatsData.length === 0) {
    return <DialoguesEmpty />
  }

  return chatsData.map(dialogueSnap => {
    const dialogue = dialogueSnap.data();
    const isSelected = selectedChat?.UID === dialogue.UID;

    return (
      <DialogueListItem
        key={dialogueSnap.get('UID')}
        dialogue={dialogue}
        dialogueSnapId={dialogueSnap.id}
        openDialogue={openDialogue}
        isSelected={isSelected}
      />
    )
  })
});

export default DialoguesList;
