import { orderBy, query, where, limit } from "firebase/firestore";
import getAssignedSectionsRef from "../../../firebase/assignedSections/getAssignedSectionsRef";

const getAssignedSectionsQuery = (role, authorizedUserId, sectionsTableFilters, limitCount=0) => {
  const constraints = [
    orderBy('lastActivity', 'desc'),
    orderBy('assistantId'), // чтобы не попадали секции без assistantId (до первого назначения).
  ]
  if( role === 'assistant') {
    constraints.push(where('assistantId', '==', authorizedUserId))
  }
  if(sectionsTableFilters) {
    Object.keys(sectionsTableFilters).forEach(key=> {
      if(sectionsTableFilters[key]?.length) {
        console.log('прошли')
        constraints.push(where(key, 'in', sectionsTableFilters[key]))
      }
    })
  }

  if(limitCount > 0) {
    constraints.push(limit(limitCount));
  }

  return query(getAssignedSectionsRef(), ...constraints);
}

export default getAssignedSectionsQuery;