import RoleIcons from '../../../components/RoleIcons';
import ActionButtonContainer from '../components/ActionButtonContainer';


const renderDeleteOperator = (_text, record, _index) => {
  return <ActionButtonContainer adminData={record} />
}
const renderRoleIcon = (text) => {
  return <RoleIcons role={text} iconSize='32px'/>
}
export const columns = [
  {
    access:'all',
    key:'roleEn',
    dataIndex:'roleEn',
    title: 'Роль',
    render: renderRoleIcon,
  },
  {
    access:'all',
    key: "operatorName",
    dataIndex: "name",
    title: "Имя, Фамилия",
  },
  {
    access:'all',
    key: "shortId",
    dataIndex: "shortId",
    title: "ID",
  },
  {
    access:'all',
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    title: "Телефон",
  },
  {
    access:'all',
    key: "applications",
    dataIndex: "applications",
    title: "Заявки",
  },
  {
    access:'all',
    key: "online",
    dataIndex: "online",
    title: "В сети",
  },
  {
    access:'all',
    key: "unreadMessages",
    dataIndex: "unreadMessages",
    title: "Непрочитанные сообщения",
  },
  {
    access:['admin', 'careSupport', 'superOperator'],
    key: "delete",
    dataIndex: 'active',
    title: "Активность",
    align: 'center',
    render: renderDeleteOperator,
  },
]

