import { useEffect } from "react";
import { useState } from "react";
import getIssuesQuery from "../firebase/getIssuesQuery";
import { getCountFromServer } from "firebase/firestore";

const useIssuesCount = () => {
  const [ count, setCount ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    // получаение общего количества запросов на помощь
    const getAllIssuesCount = async () => {
      try {
        const aggregateSnapshot = await getCountFromServer(getIssuesQuery());
        setCount(aggregateSnapshot.data().count);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    getAllIssuesCount();
  }, [])


  return [count, loading, error]
}

export default useIssuesCount;