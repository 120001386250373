import React from 'react';
import '../../../assets/profile/reauth-modal.scss';

const ReAuthModalTitle = () => {
  return (
    <div className='reauth-modal__title'>
      Подтвердите действие
    </div>
  );
};

export default ReAuthModalTitle;
