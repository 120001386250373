import { serverTimestamp } from "firebase/firestore";

const createNewStatusData = (step) => {
  return {
    step,
    lastActivity: serverTimestamp(),
    isRead: false,
  }
}

export default createNewStatusData;