import { Empty } from 'antd';
import React from 'react';

const NotificationsBoard = () => {
  return (
    <div style={{height: '100%', display: 'flex', justifyContent:'center', alignItems:'center'}}>
      <Empty />
    </div>
  );
};

export default NotificationsBoard;