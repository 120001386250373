import { query, where } from "firebase/firestore";
import getNewsCollRef from "../../firebase/news/getNewsCollRef";

const getUnreadNewsQuery = (userLastNewsViewTime) => {
  return query(
    getNewsCollRef(),
    where('createdAt', '>', userLastNewsViewTime)
  )
};

export default getUnreadNewsQuery;