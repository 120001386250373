import React, { useContext } from 'react';
import { Button, Select } from 'antd';
import { OperatorsContext, UserDataContext } from '../../../contexts/context';
import '../../../assets/operator/reassign-and-deactivate-form.scss';
import getEmployeesSelectData from '../../../models/getEmployeesSelectData';
import { AllOperatorsContext } from '../../../contexts/layoutContexts';

const ReassignAndDeactivateForm = ({setSelectedOperator, selectedOperator, reassignAndDeactivate}) => {
  const { allOperators, allOperatorsLoading, allOperatorsError } = useContext(AllOperatorsContext)
  const { deactivatedOperatorName } = useContext(OperatorsContext)
  const { role } = useContext(UserDataContext);

  const handleSelect = (selectedValue, _option) => {
    setSelectedOperator(selectedValue)
  }

  const employeeFilterhandler = (input, option) => {
    if('options' in option) {
      return false;
    } else {
      const label = option.label.props.name
      return label.toLowerCase().startsWith(input.toLowerCase())
    }
  }

  const errorValue = allOperatorsError && 'Ошибка'

  return (
    <div className='reassign'>
      <div className="reassign__title">
        Кому перенаправить все заявки и чаты <span className="reassign__operator-to-be-deactivated">{deactivatedOperatorName}</span>
      </div>
      <div className="reassign__select">
        <Select
          value={errorValue || selectedOperator}
          placeholder="Выберите"
          options={getEmployeesSelectData('operatorAssign', allOperators, role)}
          loading={allOperatorsLoading}
          showSearch={true}
          filterOption={employeeFilterhandler}
          popupMatchSelectWidth={false}
          style={{
            width: 136,
          }}
          onSelect={handleSelect}
        />
      </div>
      <div className='reassign__button'>
        <Button type='primary' onClick={reassignAndDeactivate}>Подтвердить</Button>
      </div>
    </div>
  );
};

export default ReassignAndDeactivateForm;
