import { orderBy, query, where } from "firebase/firestore";
import getAdminsRef from "../../../firebase/admins/getAdminsRef";

const getEmployeesQuery = () => {
  return query(
    getAdminsRef(),
    where('role', 'in', ['superOperator', 'operator', 'careSupport', 'assistant']),
    orderBy('active', 'desc'),
    orderBy('role'),
  )
}

export default getEmployeesQuery;