import { Button, Table } from 'antd';
import React, { useContext, useState } from 'react';
import '../../../assets/issues/issues.scss';
import getIssuesColumns from '../models/getIssuesColumns';
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import getIssuesQuery from '../firebase/getIssuesQuery';
import Spinner from '../../../components/Spinner';
import MyError from '../../Error/components/MyError';
import { useEffect } from 'react';
import combineIssuesTableData from '../models/combineIssuesTableData';
import { IssuesContext, ProgramContext } from '../../../contexts/context';
import getAssignCareSupportDataQuery from '../firebase/getAssignCareSupportDataQuery';
import { getDocs, query, runTransaction } from 'firebase/firestore';
import { firestore } from '../../../firebase/firebase';
import showNotification from '../../NotificationService/helpers/showNotification';
import useIssuesCount from '../hooks/useIssuesCount';
import Paginator from '../../../ui/Paginator';
import { getChatQueryForApplication } from '../../../firebase/chat/getChatQueryForApplication';
import getAssignedSupportInfo from '../models/getAssignedSupportInfo';
import getIssueRef from '../firebase/getIssueRef';
import { IssuesPagesContext, LayoutFuncsContext } from '../../../contexts/layoutContexts';

const Issues = () => {
  // contexts
  const { notificationAPI } = useContext(ProgramContext);
  const { addIssuesPage } = useContext(LayoutFuncsContext);
  const { issuesPageCount } = useContext(IssuesPagesContext);

  // states
  const [ issuesTableData, setIssuesTableData ] = useState([]);
  // data dwonloading
  const [ issuesData, issuesLoading, issuesError, issuesCollSnap ] = useCollectionData(getIssuesQuery(50 * issuesPageCount), {snapshotOptions: {serverTimestamps:'estimate'}} );
  const [ employeesAsCareSuuportData, employeesAsCareSuuportLoading, employeesAsCareSuuportError ] = useCollectionDataOnce(getAssignCareSupportDataQuery());
  const [ issuesCount, issuesCountLoading, issuesCountError ] = useIssuesCount(); // Для пагинации.

  useEffect(() => {
    if(issuesCollSnap) {
      setIssuesTableData(combineIssuesTableData(issuesData))
    }
  },[issuesCollSnap]);

  if(issuesLoading || employeesAsCareSuuportLoading) {
    return <Spinner />
  }

  if(issuesError || employeesAsCareSuuportError) {
    return <MyError error={issuesError || employeesAsCareSuuportError}/>
  }

  const downloadMoreIssues = () => {
    addIssuesPage();
  }

  const assignSupport = async (issueId, UID, supportInfo) => {
    try {
      const dialoguesCollSnapshot = await getDocs(query(getChatQueryForApplication(UID)));
      if(dialoguesCollSnapshot.empty) throw new Error('Чат не найден');
      if(dialoguesCollSnapshot.docs[0].get('assignedTo') === supportInfo.id) { // ответственный за клиента не может быть назначен на обращение за помощью
        throw new Error('Current employee already assigned to this client')
      }
      const dialogueRef = dialoguesCollSnapshot.docs[0].ref;
      const support = getAssignedSupportInfo(supportInfo.id, supportInfo.name);
      await runTransaction(firestore, async (transaction) => {
        transaction
        .update(getIssueRef(issueId), {status:"inProgress", support})
        .update(dialogueRef, {
          needHelp: false,
          supportId: supportInfo.id,
        })
      })
      showNotification(notificationAPI, 'process', {processName: 'assignIssue', status:'success'})
    } catch (error) {
      console.log(error)
      if(error.message === 'Current employee already assigned to this client') {
        showNotification(notificationAPI, 'process', {processName: 'employeeIsBusy', status:'error'})
      } else {
        showNotification(notificationAPI, 'process', {processName: 'assignIssue', status:'error'})
      }
    }
  }

  const context = {
    assignSupport,
  }

  return (
    <IssuesContext.Provider value={context} >
      <div className='issues__container'>
        <div className='issues__before-table'>
          <Paginator currentCount={issuesTableData.length} totalCount={issuesCount}/>
        </div>
        <Table
          columns={getIssuesColumns(employeesAsCareSuuportData)}
          dataSource={issuesTableData}
          size="small"
          pagination={false}
        />
        <div className='assigned-sections__after-table'>
          <Paginator currentCount={issuesTableData.length} totalCount={issuesCount}/>
        </div>
        <div style={{display:'flex', justifyContent:'center', marginTop: '1%'}}>
          <Button
            loading={issuesLoading}
            onClick={downloadMoreIssues}
            disabled={issuesCount === issuesTableData.length}
          >
            Загрузить еще
          </Button>
        </div>
      </div>
    </IssuesContext.Provider>
  );
};

export default Issues;