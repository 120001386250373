import React, { useContext } from 'react';
import ActionButton from './ActionButton';
import { OperatorsContext, ProgramContext } from '../../../contexts/context';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase/firebase';
import { adminsPath } from '../../../firebase/admins/adminsPath';
import showNotification from '../../NotificationService/helpers/showNotification';
import getAdminDocRef from '../../../firebase/admins/getAdminDocRef';


const ActionButtonContainer = ({adminData}) => {
  const { setActionType, setDeactivatedOperatorId, setDeactivatedOperatorName, setPopupIsOpened, reloadEmployees } = useContext(OperatorsContext);
  const { notificationAPI } = useContext(ProgramContext);

  const openPopupToReassign = () => {
    setDeactivatedOperatorId(adminData.id);
    setDeactivatedOperatorName(adminData.name);
    setActionType('deactivateOperator');
    setPopupIsOpened(true);
  }

  const activateOperator = async () => {
    try {
      await updateDoc(getAdminDocRef(adminData.id),  {active: true});
      showNotification(notificationAPI, 'process', {processName: 'operatorActivate', status:'success'});
      reloadEmployees();
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'operatorActivate', status:'error'});
    }
  }

  return (
    <ActionButton openPopupToReassign={openPopupToReassign} activateOperator={activateOperator} isActive={adminData.active}/>
  );
};

export default ActionButtonContainer;
