import updateDocField  from "../../../firebase/updateDocField";

export const updateUploadedFilesInfo = async (ref, data) => {
  // console.log('updateUploadedFilesInfo')
  try {
    await updateDocField(ref, data)
  } catch (error) {
    throw error
  }
};
