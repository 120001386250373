import React, { useContext, useEffect, useState } from 'react';
import { ApplicantPassportContext } from '../../../../contexts/context';
import { Form, Image } from 'antd';
import { getDownloadURL, getMetadata } from 'firebase/storage';
import { getFileRef } from '../../../../firebase/firebase';
import PdfImage from './PdfImage';

const TypePhotoField = ({fieldName, dbValue}) => {
  const { passportIndex } = useContext(ApplicantPassportContext);
  const [ imgContentType, setImgContentType ] = useState(null);
  const [ url, setUrl ] = useState();

  useEffect(() => {
    if(dbValue) {
      const func = async () => {
        const metadata = await getMetadata(getFileRef(dbValue))
        const url = await getDownloadURL(getFileRef(dbValue));
        setImgContentType(metadata.contentType);
        setUrl(url)
      }
      func()
    }
  }, [dbValue])

  return (
    <>
      <Form.Item
        name={[String(passportIndex), fieldName ]}
        initialValue={dbValue}
      >
        {imgContentType && imgContentType.split('/').at(-1) === 'pdf' ? (
          <PdfImage
            url={url}
            imgContentType={imgContentType}
          />
        ) : (
          <Image
            width={100}
            src={url}
          />
        )}
      </Form.Item>

    </>
  );
};

export default TypePhotoField;
