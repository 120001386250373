import React from 'react';
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


const ActionButton = ({openPopupToReassign, activateOperator, isActive}) => {

  return (
    <div style={{opacity:'unset'}}>
      {isActive ? (
        <a href="#" onClick={openPopupToReassign}><CloseCircleOutlined style={{fontSize:'20px', color:'red'}}/></a>
      ):(
        <a href="#" onClick={activateOperator}><PlusCircleOutlined  style={{fontSize:'20px'}}/></a>
      )}
    </div>
  );
};

export default ActionButton;
