import React from 'react';
import QuestionnaireItem from './QuestionnaireItem';

const SubQuestions = ({response, questionData, questionIndex, personIndex}) => {
  const subQuestions = response.answers.map((subQuestion, subQuestionIndex) => {
    const subQuestionType = questionData.options[response.pickedOption].questions[subQuestionIndex].type;

    // кастомный объект question для вложенного в дропдаун вопроса.
    const subQuestionQuestionObj = {
      isMultiple: false,
      name: subQuestion.question,
      type: subQuestionType,
      response: subQuestion.answer,
    }

    if (subQuestionType === 'choice') subQuestionQuestionObj.options = questionData.options[response.pickedOption].questions[subQuestionIndex].options;

    return (
      <QuestionnaireItem
        key={`${questionIndex}-sub-${subQuestionIndex}`}
        question={subQuestionQuestionObj}
        questionIndex={questionIndex}
        personIndex={personIndex}
        nestedOptions={{
          isNested: true,
          nestedQuestionIndex: subQuestionIndex,
          dropdownQuestionData: questionData,
        }}
      />
    )
  })

  return (
    <div style={{marginLeft:"20px"}} >
      {subQuestions}
    </div>
  );
};

export default SubQuestions;