import React, { useContext } from 'react';
import DocumentSection from './DocumentSection';
import '../../../../assets/documents/docs-by-sections/document-sections.scss';
import { UserDataContext } from '../../../../contexts/context';

const DocumentSections = ({sections, applicantId, applicantName}) => {
  const { role } = useContext(UserDataContext)

  const docsSections = sections.map(section => {
    return (
      <DocumentSection
        key={section.sectionId}
        section={section}
        applicantId={applicantId}
        applicantName={applicantName}
      />
    )
  })
  return (
    <div className={`document-sections ${role === 'assistant' ? 'assistant-sections' : ''}`}>
      {docsSections}
    </div>
  )
};

export default DocumentSections;