import { onAuthStateChanged } from 'firebase/auth';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { auth, firestore } from './firebase/firebase';
import { onSnapshot } from 'firebase/firestore';
import getAuthorizedUserRef from './firebase/admins/getAuthorizedUserRef';

const useUserData = () => {
  const [ user, setUser ] = useState(null);
  const [ authorizedUser, setAuthorizedUser ] = useState(null);
  const [ userLoading, setUserLoading ] = useState(true);
  const [ authorizedUserLoading, setAuthorizedUserLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setAuthorizedUserLoading(true); // если вышли из аккаунта - загрузка документа пользователя была завершена ранее. Снова активируем перед началом самой загрузки. Если сделать это позже, то на UI в этот момент отобразится страница "нет доступа".
      if (user) {
        setUser(user)
      } else {
        // первый вход либо вход после signOut.
        setUser(prev =>  prev ? null : prev);
        setAuthorizedUser(prev =>  prev ? null : prev)
      }
      setUserLoading(false); // завершаем этап загрузки аккаунта из firebase.
    })
    return () => unsub()
  },[])

  useEffect(() => {
    if(!userLoading){
      if(user) {
        // в файрбейзе есть аккаунт пользователя
        const unsub = onSnapshot(getAuthorizedUserRef(user.uid), (docSnap) => {
          if(!docSnap) {
            setError(new Error('Ошибка при получении данных о пользователе'));
            return;
          }
          setAuthorizedUser(docSnap.data());
          setAuthorizedUserLoading(false); // завершаем этап загрузки документа о пользователе
        },
        (error) => {
          setError(new Error('Ошибка при получении данных о пользователе'));
        })
        return () => unsub()
      } else {
        // в файрбейзе нет аккаунта пользователя или авторизации еще не было.
        setAuthorizedUserLoading(false); // завершаем этап загрузки документа о пользователе
      }
    }
  }, [user, userLoading])

  return [
    user,
    authorizedUser,
    authorizedUserLoading,
    error,
  ]
};

export default useUserData;