import React, { useContext } from 'react';
import { Select } from 'antd';
import prepareChanges from '../helpers/prepareChanges.js';
import { getQuestionnaireSelectOptions } from '../helpers/getQuestionnaireSelectOptions.js';
import getResponse from '../helpers/getResponse.js';
import getUpdatedDropDownResponse from '../helpers/getUpdatedDropDownResponse.js';
import SubQuestions from './SubQuestions.jsx';
import { QuestionnaireContext } from '../../../contexts/context.js';

const TypeDropdownAnswer = ({questionData, questionIndex, personIndex}) => {
  // вложенным быть не может.
  const {answersToUpdate, setAnswersToUpdate, isEdit} = useContext(QuestionnaireContext);

  const handleSelect = (value,  optionInstance) => {
    // собираем новый объект respons'a для дропдауна.
    const newResponse = getUpdatedDropDownResponse(value, optionInstance, questionData);
    setAnswersToUpdate(prepareChanges(answersToUpdate, newResponse, questionData, questionIndex, personIndex));
  }

  const response = getResponse(answersToUpdate, questionData, questionIndex, null, personIndex);
  const options = getQuestionnaireSelectOptions(questionData.options) // составляем опции для селекта

  return (
    <>
      <Select
        style={{
          width: 200,
        }}
        value={response.pickedOption} // индекс опции
        placeholder=""
        options={options}
        disabled={!isEdit}
        onSelect={handleSelect}
      />
      <SubQuestions
        response={response}
        questionData={questionData}
        questionIndex={questionIndex}
        personIndex={personIndex}
      />
    </>
  )
};

export default TypeDropdownAnswer;
