import { deleteObject } from "firebase/storage";
import { getFileRef } from "../../../firebase/firebase";

const deleteFilesFromStorage = async (links) => {
  const promises = [];
  links.forEach(link => promises.push(deleteObject(getFileRef(link))));
  await Promise.all(promises);
  return;
}

export default deleteFilesFromStorage;