import dayjs from 'dayjs';
import React from 'react';
import '../../../assets/application-additionals/prices-history/prices-history.scss';

const PriceHistoryItem = ({data}) => {
  const time = dayjs(data.changedAt.toMillis()).format('DD.MM.YYYY HH:mm')
  return (
    <li className='price-history__item'>
      <p className='price-history__old-price'>{data.from}</p>
      <p className='price-history__new-price'>{data.to}</p>
      <p className='price-history__time'>{time}</p>
      <p className='price-history__comment'>{data.reason}</p>
      <p className='price-history__employee-name'>{data.name}</p>
    </li>
  );
};

export default PriceHistoryItem;