import { doc } from "firebase/firestore";
import { applicationsPath } from "../../../firebase/applications/appsPath";
import getDocSectionRef from "./getDocSectionRef";

const getDocSectionCommentRef = (appId, applicantId, sectionId, commentId) => {
  const { comments } = applicationsPath;
  const commentPath = `${comments}/${commentId}`;
  return doc(getDocSectionRef(appId, applicantId, sectionId), commentPath);
}

export default getDocSectionCommentRef;