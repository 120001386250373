import { collection, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { applicationsPath } from "../../firebase/applications/appsPath";

const getNewUnreadApplicationQuery = (authorizedUser) => {
  const constraints = [
    where('isCreated', '==', true),
    where('isRead', '==', false),
  ];
  if(authorizedUser.role === 'operator') {
    constraints.push(where('preparedInformation.assignedTo', '==', authorizedUser.id))
  }
  constraints.push(
    orderBy('preparedInformation.assignedAt', 'desc'),
    orderBy('documentID'),
  )
  return query(collection(firestore, applicationsPath.applications), ...constraints, limit(1))
};

export default getNewUnreadApplicationQuery;