import { getDocs, orderBy, query, where } from "firebase/firestore";
import { getAppsCollRef } from "../../../firebase/applications/getAppsCollRef";

const getClientAllUnfinishedApps = (clientId) => {
  // запрос не включает айди текущего визовика, потому что только текущий оператор может получить доступ к заявкам клиента,значит он и назначен.
  const constraints = [
    where('isCreated', '==', true),
    where('UID', '==', clientId),
    where('preparedInformation.preparationStatus', '<=', 4),
  ]

  return query(getAppsCollRef(), ...constraints);
}

const getClientFinishedAppsByCurOperator = (clientId, operatorId) => {
  // запрос включает айди текущего оператора, потому что у заявителя могут быть завершенные другим визовиком заявки.
  const constraints = [
    where('isCreated', '==', true),
    where('UID', '==', clientId),
    where('preparedInformation.preparationStatus', '>', 4),
    where('preparedInformation.assignedTo', '==', operatorId),
  ]

  return query(getAppsCollRef(), ...constraints);
}
/**
 * объединяет  массивы всех незавершенных заявок клиента со всеми завершенными текущим визовиком заявками.
 * @param {*} clientId 
 * @param {*} operatorId 
 * @returns 
 */
const getAppsForOperatorToChangeStatus = async (clientId, operatorId) => {
  try {
    const clientUnfinishedAppsCollSnap = await getDocs(getClientAllUnfinishedApps(clientId));
    const ClientFinishedAppsByCurOperatorCollSnap = await getDocs(getClientFinishedAppsByCurOperator(clientId, operatorId));
    return clientUnfinishedAppsCollSnap.docs.concat(ClientFinishedAppsByCurOperatorCollSnap.docs);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default getAppsForOperatorToChangeStatus;
