import React from 'react';
import UserDataProvider from './UserDataProvider';
import AppRouter from './AppRouter';
import VersionCheck from './VersionCheck';
import ReloadTimer from './ReloadTimer';



const App = () => {
  return (
      <UserDataProvider>
        <VersionCheck>
          <ReloadTimer>
            <AppRouter />
          </ReloadTimer>
        </VersionCheck>
      </UserDataProvider>
  );
};

export default App;