import allAppsCheckAssignedToAndStatus from "./allAppsCheckAssignedToAndStatus";

const checkAccesses = (authorizedUser, curApplication, allClientAppsData) => {
  const meAssigned = authorizedUser.id === curApplication.preparedInformation.assignedTo;
  const appStatus = curApplication.preparedInformation.preparationStatus;
  const {meOperatorAssignedAndInProgress,  meOperatorAssignedAndFinished} = allAppsCheckAssignedToAndStatus(allClientAppsData, authorizedUser);
  const role = authorizedUser.role
  return {
    // Смена статуса заявки доступна админам, супервизовикам и назначенным операторам, в заявках со статусом 0-4
    canChangeAppStatus: role === 'admin' || role === 'careSupport' || role === 'superOperator' || (role === 'operator' && meAssigned && appStatus <= 4), // доступно всем, и назначенным операторам в статусе 0-4
    switcherActive: true,
    // Отображение секции деталей заявки запрещено для ассистентов.
    appDetailsIsNotDisplayed: role === 'assistant',
    // Редактирование паспорта доступно админам, супервизовикам, и назначенным операторам в статусе 0-2
    cantEditPassport: role === 'admin' || role === 'careSupport' || role === 'superOperator' || (role === 'operator' && meAssigned && appStatus <= 2),
    // Редактирование заявки доступно админам, супервизовикам, и назначенным операторам в статусе 0-2
    cantEditQuestionnaire: role === 'admin' || role === 'careSupport' || role === 'superOperator' || (role === 'operator' && meAssigned && appStatus <= 2), // доступно всем, и назначенным операторам в статусе 0-2
    // Отображение чата заявки запрещено для ассистентов
    chatIsNotDisplayed: role === 'assistant',
    // Добавлять документы могут все, включая назначенных операторов, в статусе заявки 0-4.
    canUploadDocuments: role === 'admin' || role === 'careSupport' || role === 'superOperator' || role === 'assistant' || (role === 'operator' && meAssigned && appStatus <= 4),
    // Отображении селекта для назначения асистента на секцию документов
    canAssignAssistant: role === 'admin' || role === 'careSupport' || role === 'superOperator' || (role === 'operator' && meAssigned && appStatus <= 4),
  }
}

export default checkAccesses;
