import { orderBy, query, where } from "firebase/firestore";
import getAssignedSectionsRef from "../../firebase/assignedSections/getAssignedSectionsRef";

const getUnreadSectionsQuery = (authorizedUser) => {
  const constraints = [
    where('isRead', '==', false),
    orderBy('assistantId'),
  ];

  if(authorizedUser.role === 'assistant') {
    constraints.push(where('assistantId', '==', authorizedUser.id))
  }

  return query(getAssignedSectionsRef(), ...constraints)
};

export default getUnreadSectionsQuery;