import { Button, Tag } from "antd";
import { Link } from "react-router-dom";
import issueStatuses from "../configs/issueStatuses";
import AssignCareSupport from "../components/AssignCareSupport";

const goToApps = (text, record, index) => {
  if(!record.totalApps) {
    return false;
  }

  return (
    <Link
      to={`/application/${record.UID}`} // переходим на старницу заявки, если у клиента есть заявки.
    >
      <Button size="small">К заявкам</Button>
    </Link>
  )
}

const issueStatusTagRender = (text, record, index) => {
  return (
    <Tag
      bordered="false"
      color={issueStatuses[text].tagColor}
    >
      {issueStatuses[text].titleRu}
    </Tag>
  )
}

const issueAssigneeRender = (record, employeesAsCareSuuportData) => {
  return (
    <AssignCareSupport
      issueId={record.issueId}
      UID={record.UID}
      value={record.supportId}
      employeesAsCareSuuportData={employeesAsCareSuuportData}
      disabled={record.status === issueStatuses.finished.id}
    />
  )
}

const getIssuesColumns = (employeesAsCareSuuportData) => {
  return [
    {
      title: 'Клиент',
      dataIndex: 'clientName',
      key: 'clientName',
      align: "center",
      ellipsis: true,
    },
    {
      title: 'Время',
      dataIndex: 'time',
      key: 'time',
      align: "center",
      ellipsis: true,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      align: "center",
      ellipsis: true,
      render: issueStatusTagRender,
    },
    {
      title: 'Ответственный',
      dataIndex: 'supportId',
      key: 'supportId',
      align: "center",
      ellipsis: true,
      render: (text, record, _) => issueAssigneeRender(record, employeesAsCareSuuportData),
    },
    {
      title: 'Заявки',
      dataIndex: 'goToApps',
      key: 'goToApps',
      align: "center",
      ellipsis: true,
      render: goToApps,
    },
  ]
}

export default getIssuesColumns;