import getAvailableEmployeesRoles from "../accesses/getAvailableEmployeesRoles";
import EmployeeItem from "../components/EmployeeItem";
import RoleIcons from "../components/RoleIcons";
import { GLOBAL_ROLES_RU } from "../constants/constants";

const getEmployeesSelectData = (action, employeesData, role) => {
  if(!employeesData) return [];
  const availableEmployeesRoles = getAvailableEmployeesRoles(action, role);
  // return availableEmployeesRoles.map(employeeRole => {
  //   return {
  //     label: GLOBAL_ROLES_RU[employeeRole],
  //     options: employeesData.reduce((acc, employee) => {
  //       if(employee.role === employeeRole) {
  //         acc.push({
  //           value: employee.id,
  //           label: <EmployeeItem name={employee.name} role={employee.role}/> ,
  //         })
  //         return acc;
  //       }
  //       return acc;
  //     }, [])
  //   }
  // })
  return availableEmployeesRoles.map(employeeRole => {
    return {
      label: GLOBAL_ROLES_RU[employeeRole.role],
      options: employeesData.reduce((acc, employee) => {
        if(employee.role === employeeRole.role) {
          acc.push({
            value: employee.id,
            disabled: employeeRole.disabled,
            label: <EmployeeItem name={employee.name} role={employee.role}/> ,
          })
          return acc;
        }
        return acc;
      }, [])
    }
  })
}

export default getEmployeesSelectData;
