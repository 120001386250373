import React from 'react';
import '../../assets/components/file-list/item-text.scss'

const ItemText = ({fileInfo, index}) => {
  const fileNumber = `${index + 1}. `
  return (
    <p className='file-list__file-name'>
      {fileNumber}
      {fileInfo.downloadURL ? (
        <a className='file-list__link' href={fileInfo.downloadURL} target='_blank' rel='noreferrer'>
          {`${fileInfo.name}`}
        </a>
      ) : (
        fileInfo.name
      )}
    </p>
  )
};

export default ItemText;