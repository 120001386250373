import { Badge } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserDataContext } from '../../../contexts/context';

const itemContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems:'center',
}

const SiderMenuAssistantField = ({unreadSectionsCount}) => {
  const { features, role } = useContext(UserDataContext);

  return (
    <div>
      <div style={itemContainerStyle}>
        <Link
          to={role === 'assistant' ? '/' : "assistant-page"}
        >
          Ассистент
        </Link>
        <div>
          {features.sectionsFeatureEnabled ? (
            <Badge
              count={unreadSectionsCount}
              styles={{
                root:{color: "white", marginLeft:"2px"},
                indicator: {boxShadow:'none', backgroundColor:"#56DA27"}
              }}
            />
          ) : (
            null
          )}

        </div>
      </div>
    </div>
  );
};

export default SiderMenuAssistantField;