import { Link } from "react-router-dom";

const getHeadMenuItems = () => {
  return [
    {
      key:"/notifications",
      label:<Link to='/notifications' >Уведомления</Link>,
    }, // сейчас, т.к. нет такого роута - редиректит на главную. Кнопка "Уведомления" гореть не будет.
    {
      key:"/user-profile",
      label:<Link to='/user-profile' >Профиль</Link>,
    },
  ]
}

export default getHeadMenuItems;
