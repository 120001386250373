import { runTransaction, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import getDocSectionRef from "./getDocSectionRef";
import getDocSectionCommentRef from "./getDocSectionCommentRef";

const leaveComment = async (appId, applicantId, sectionId, comment) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      transaction
      .set(getDocSectionCommentRef(appId, applicantId, sectionId, comment.id), comment)
      .set(getDocSectionRef(appId, applicantId, sectionId), {lastActivity: serverTimestamp(), isRead: false}, {merge: true})
    })
  } catch (error) {
    throw error;
  }
}

export default leaveComment;