import React,{useState, useContext, useEffect} from 'react';
import { Layout, Typography, theme } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import ApplyOrCancel from '../../../components/ApplyOrCancel';
import { updateQuestionnaireAnswers } from '../firebase/updateQuestionnaireAnswers';
import '../../../assets/application-questionnaires.scss';
import showNotification from '../../NotificationService/helpers/showNotification';
import PersonalQuestionnaires from './PersonalQuestionnaires';
import CommonQuestionnaire from './CommonQuestionnaire';
import combinePreparedData from '../helpers/combinePreparedData';
import categoryForQuestion from '../helpers/categoryForQuestionIfItIsPersonal';
import { ApplicationStatus, ProgramContext, QuestionnaireContext } from '../../../contexts/context';
const { useToken } = theme;
const {Title} = Typography;



const ApplicationQuestionnaires = ({questionnaire, appRef, passports}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [answersToUpdate, setAnswersToUpdate] = useState([]); // [{newResponse: any, index: number},]
  const {notificationAPI} = useContext(ProgramContext);
  const {curAppStatus, accesses} = useContext(ApplicationStatus);

  useEffect(() => {
    if (!accesses.cantEditQuestionnaire ) cancelChanges();
  },[accesses.cantEditQuestionnaire])

  const applyChanges = async (e) => {
    try {
      // TODO: функция, проверяющая answersToUpdate на заполенность всех обязательных вопросов
      await updateQuestionnaireAnswers(appRef, questionnaire, answersToUpdate)
      showNotification(notificationAPI, 'process', {processName: 'dataUpdated', status: 'success',})
      setAnswersToUpdate([]);
      setIsEdit(false)
    } catch (e) {
      console.log(e)
      showNotification(notificationAPI, 'process', {processName: 'dataUpdated', status: 'error',})
    }
  }

  const cancelChanges = () => {
    setAnswersToUpdate([]);
    setIsEdit(false)
  }

  const preparedData = combinePreparedData(questionnaire, passports.length);
  return (
    <Layout style={{}}>
      <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
        <Title level={3}>Анкета</Title>
        {questionnaire && accesses.cantEditQuestionnaire ?  (
          <EditOutlined
            className="interactive-icons"
            style={{ fontSize: '22px', color: '#08c', marginLeft:"10px", marginRight:"10px"}}
            onClick={() =>  setIsEdit(true)}
          />
        ) : (
          null
        )}
      </div>
      <QuestionnaireContext.Provider value={{answersToUpdate, setAnswersToUpdate, isEdit}}>
        <CommonQuestionnaire commonQuestionnaireData={preparedData?.[categoryForQuestion.false]} />
        <PersonalQuestionnaires personalQuestionnaireData={preparedData?.[categoryForQuestion.true]} passports={passports} />
      </QuestionnaireContext.Provider>
      <ApplyOrCancel
        isEdit={isEdit} // пока не менять, т.к. компонент вызывается из других мест программы
        setIsEdit={setIsEdit}
        applyChanges={applyChanges}
        cancelChanges={cancelChanges}
      />
    </Layout>
  );
};

export default ApplicationQuestionnaires;
