import { Badge } from 'antd';
import React, { useContext } from 'react';
import { UserDataContext } from '../../../contexts/context';


const SiderMenuChatField = ({unreadMessagesCount, chatsInNeedOfHelpCount}) => {
  const { features } = useContext(UserDataContext);

  return (
    <div>
      <div style={{display: "flex", justifyContent: "space-between", alignItems:'center'}}>
        <p>Чат</p>
        <div>
          {features.helpFeatureEnabled ? (
            <Badge
              count={chatsInNeedOfHelpCount}
              styles={{
                root:{color: "white"},
                indicator: {boxShadow:'none', backgroundColor:"rgb(255, 65, 65)"}
              }}
            />
          ) : (
            null
          )}
          <Badge
            count={unreadMessagesCount}
            styles={{
              root:{color: "white", marginLeft:"2px"},
              indicator: {boxShadow:'none', backgroundColor:"#56DA27"}
            }}
          />
        </div>
      </div>
    </div>
  )
};

export default SiderMenuChatField;
