import { getDocs, writeBatch } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { dialogueActions } from "../helpers/chat-active-status";
import getClientQueryByUID from "../../../firebase/clients/getClientQueryByUID";

const assignAndUnassignToMe = async (value, employeeId, supportId, clientId, dialogueRef) => {
  try {
    const userCollSnap = await getDocs(getClientQueryByUID(clientId));

    if(value === dialogueActions.takeInpRogress.value) {
      // назначение на себя
      if(supportId === employeeId) { // если назначаемый сотрудник уже назначен на обращение за помощью.
        throw new Error('Current employee already busy with this client')
      }
      const batch = writeBatch(firestore);
      if(!userCollSnap.empty) {
        batch.update(userCollSnap.docs[0].ref, {assignedTo: employeeId})
      }
      batch.update(dialogueRef, {assignedTo: employeeId})
      await batch.commit();
    }

    if( value === dialogueActions.finish.value) {
      // снять клиента с себя
      const batch = writeBatch(firestore);
      if(!userCollSnap.empty) {
        batch.update(userCollSnap.docs[0].ref, {assignedTo: ''})
      }
      batch.update(dialogueRef, {
        'assignedTo': '',
        'active': false
      })
      await batch.commit();
    }
  } catch (error) {
    throw error;
  }
};

export default assignAndUnassignToMe;