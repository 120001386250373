import React, { useContext } from 'react';
import NewsFeed from '../../../modules/NewsFeed/components/NewsFeed';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import NewNewsForm from '../../../modules/NewNewsForm/components/NewNewsForm';
import { UserDataContext } from '../../../contexts/context';
import getNewsQuery from '../firebasse/getNewsQuery';
import '../../../assets/news/page/news-page.scss';
import { useEffect } from 'react';
import readUnreadNews from '../firebasse/readUnreadNews';

const NewsPage = () => {
  const { authorizedUser } = useContext(UserDataContext);
  const [ newsData, newsLoading, newsError, newsSnapshot, reload ] = useCollectionDataOnce(getNewsQuery());
  const { features } = useContext(UserDataContext);

  useEffect(() => {
    if(newsSnapshot && !newsLoading) {
      readUnreadNews(authorizedUser.id, authorizedUser.lastNewsViewTime, newsSnapshot);
    }
  }, [authorizedUser.id, authorizedUser.lastNewsViewTime, newsLoading, newsSnapshot])

  return (
    <div className='news-page__container' >
      <div className='news-page__inner' >
        {features.canEditNews && <NewNewsForm reloadNews={reload}/>}
        <NewsFeed
          newsData={newsData}
          newsLoading={newsLoading}
          newsError={newsError}
          reload={reload}
        />
      </div>
    </div>
  );
};

export default NewsPage;