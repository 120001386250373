import React, { useContext } from 'react';
import SectionCommentsItem from './SectionCommentsItem';
import { Spin } from 'antd';
import ErrorList from '../../../../components/ErrorList/ErrorList';
import { DocSectionContext } from '../../../../contexts/context';
import SectionCommentsServiceMessage from './SectionCommentsServiceMessage';

const SectionCommentsList = () => {
  const {commentsData, commentsLoading, commentsError,} = useContext(DocSectionContext)

  if( commentsLoading ) {
    return <Spin></Spin>
  }
  if(commentsError) {
    return <ErrorList error={commentsError}/>
  }

  return commentsData.map(comment => {
    if (comment.type === 'service') {
      return <SectionCommentsServiceMessage key={comment.id} comment={comment} />
    }
    return <SectionCommentsItem key={comment.id} comment={comment} />
  })
};

export default SectionCommentsList;