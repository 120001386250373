import { Link } from "react-router-dom";
import SiderMenuChatField from "../sider/components/SiderMenuChatField";
import SiderMenuAssistantField from "../sider/components/SiderMenuAssistantField";
import SiderMenuNewsField from "../sider/components/SiderMenuNewsField";

const getItems = (role, unreadMessagesCount, chatsInNeedOfHelpCount, unreadSectionsCount, unreadNewsCount) => {
  const siderMenuItemsSample = [

    {
      roles: ['admin', 'operator', 'superOperator', 'careSupport'],
      menuItem: {
        key: '/',
        label: (
          <Link
            to={'/'}

          >
            Все заявки
          </Link>
        ),
      },
    },
    {
      roles: ['admin','superOperator', 'careSupport'],
      menuItem: {
        key:"/client-by-apps",
        label: <Link to="/client-by-apps">Клиенты c заявками</Link>
      },
    },
    {
      roles: ['admin','superOperator', 'careSupport'],
      menuItem: {
        key:"/clients",
        label: <Link to="clients">Клиенты</Link>
      },
    },
    {
      roles: ['admin','superOperator', 'careSupport'],
      menuItem: {
        key:"/users-manager",
        label: <Link to="users-manager">Визовики</Link>
      },
    },
    {
      roles: ['admin','superOperator', 'careSupport'],
      menuItem: {
        key:"/push-creator",
        label: <Link to="push-creator">Сообщения клиенту</Link>
      },
    },
    {
      roles: ['admin', 'careSupport', 'superOperator', 'assistant'],
      menuItem: {
        key: role === 'assistant' ? '/' : "/assistant-page", // для асистента эта страница дефолтная, корневой роут
        label: <SiderMenuAssistantField unreadSectionsCount={unreadSectionsCount} />
      },
    },
    {
      roles: ['admin', 'operator', 'superOperator', 'assistant', 'careSupport'],
      menuItem: {
        key:"/news",
        label: <SiderMenuNewsField unreadNewsCount={unreadNewsCount}/>
      },
    },
    {
      roles: ['admin', 'superOperator', 'careSupport'],
      menuItem: {
        key: "/care-page",
        label: (
          <Link
            to={"care-page"}
          >
            Отдел заботы
          </Link>
        )
      },
    },
    {
      roles: ['admin', 'operator', 'superOperator', 'careSupport'],
      menuItem: {
        key:"/chat",
        label: <SiderMenuChatField unreadMessagesCount={unreadMessagesCount} chatsInNeedOfHelpCount={chatsInNeedOfHelpCount}/>,
      },
    }
  ]

  return siderMenuItemsSample.reduce((acc, menuItemSample) => {
    if (menuItemSample.roles.includes(role)) {
      acc.push(menuItemSample.menuItem);
      return acc;
    }
    return acc;
  }, [])
}

export default getItems;