import { doc, writeBatch } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import createHistoryEntry from "../helpers/docs-by-sections/createHistoryEntry";
import { applicationsPath } from "../../../firebase/applications/appsPath";
import addToLog from "../../../firebase/addToLog";
import { getAppRefById } from "../../../firebase/applications/getAppRefById";

const addToHistory = async (authorizedUser, appId, action ) => {
  if(action.type === 'upload') {
    const batch = writeBatch(firestore);
    for (let file of action.fileList) {
      const logData = createHistoryEntry(file, authorizedUser, 'upload');
      batch.set(doc(firestore, `${applicationsPath.applications}/${appId}/documents_history/${file.uid}`), logData)
    }
    await batch.commit();
    return;
  }

  if(action.type === 'delete') {
    const logData = createHistoryEntry(action.file, authorizedUser, 'delete');
    await addToLog(logData, getAppRefById(appId), 'documents_history/')
    return;
  }
}

export default addToHistory;