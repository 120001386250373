import React, { createContext, useContext, useMemo, useState } from 'react';
import useChatsData from '../hooks/useChatsData';
import useMessagesData from '../hooks/useMessagesData';
import useClientData from '../../../firebase/clients/hooks/useClientData';

const ChatsDataContext = createContext({});
const MessagesDataContext = createContext({});
const ClientDataContext = createContext({});
const SelectedChatContext = createContext({});
const ChatSearchContext = createContext({});
const UpdateMessengerContext = createContext({});

const MessengerDataProvider = ({children}) => {
  //states
  const [ chatListPages, setChatListPages ] = useState(1);
  const [ messagesPages, setMessagesPages ] = useState(1);
  const [ selectedChat, setSelectedChat ] = useState(null);
  const [ selectedChatSnapId, setSelectedChatSnapId ] = useState(null);
  const [ chatsSearchFilter, setChatsSearchFilter ] = useState('');
  // download data
  const [ chatsData, chatsLoading, chatsError ] = useChatsData(chatsSearchFilter, chatListPages);
  const [ messagesData, messagesLoading, messagesError ] = useMessagesData(selectedChat?.UID, messagesPages); // должен принять id выбранного чата. Если нет его то что то обработать
  const [ clientData, clientDataLoading, clientDataError ] = useClientData(selectedChat?.UID);

  const chatsDataContext = useMemo(() => {
    return {
      chatsData,
      chatsLoading,
      chatsError,
    }
  }, [chatsData, chatsError, chatsLoading])

  const messagesDataContext = useMemo(() => {
    return {
      messagesData,
      messagesLoading,
      messagesError,
    }
  }, [messagesData, messagesError, messagesLoading])

  const clientDataContext = useMemo(() => {
    return {
      clientData,
      clientDataLoading,
      clientDataError,
    }
  }, [clientData, clientDataError, clientDataLoading])

  const updateMessengerContext = useMemo(() => {
    return {
      setChatListPages,
      setMessagesPages,
      setSelectedChat,
      setChatsSearchFilter,
      setSelectedChatSnapId,
    }
  }, [])

  const chatSearchContext = useMemo(() => ({chatsSearchFilter}), [chatsSearchFilter])
  const selectedChatContext = useMemo(() => ({selectedChat, selectedChatSnapId}), [selectedChat, selectedChatSnapId])

  return (
    <ChatsDataContext.Provider value={chatsDataContext}>
      <MessagesDataContext.Provider value={messagesDataContext}>
        <ClientDataContext.Provider value={clientDataContext}>
          <SelectedChatContext.Provider value={selectedChatContext}>
            <ChatSearchContext.Provider value={chatSearchContext}>
              <UpdateMessengerContext.Provider value={updateMessengerContext}>
                {children}
              </UpdateMessengerContext.Provider>
            </ChatSearchContext.Provider>
          </SelectedChatContext.Provider>
        </ClientDataContext.Provider>
      </MessagesDataContext.Provider>
    </ChatsDataContext.Provider>
  );
};

export default MessengerDataProvider;

export const useChatsContext = () => useContext(ChatsDataContext);
export const useMessagesContext = () => useContext(MessagesDataContext);
export const useChatSearchContext = () => useContext(ChatSearchContext);
export const useSelectedChatContext = () => useContext(SelectedChatContext);
export const useUpdateMessengerContext = () => useContext(UpdateMessengerContext);
export const useClientContext = () => useContext(ClientDataContext)