import React from 'react';
import PriceHistoryItem from './PriceHistoryItem';

const PriceHistory = ({priceHistoryData}) => {

  const pricesHistory = priceHistoryData.map(data => {
    return <PriceHistoryItem key={data.id} data={data}/>
  })
  return (
    <div className="price-history">
      <h3 className='price-history__title'>
        История цен:
      </h3>
      <ul className='price-history__list'>
        {pricesHistory}
      </ul>
    </div>
  );
};

export default PriceHistory;