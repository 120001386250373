import { serverTimestamp } from "firebase/firestore";

const createHistoryEntry = (file, authorizedUser, operationType) => {
  return {
    id: file.uid,
    changedAt: serverTimestamp(),
    documentName: file.name,
    size: operationType === 'delete' ? '' : file.size,
    operatorId: authorizedUser.id,
    operatorName: authorizedUser.name,
    operationType: operationType,
  }
}

export default createHistoryEntry;