import { Select } from 'antd';
import React, { useContext } from 'react';
import { IssuesContext, UserDataContext } from '../../../contexts/context';
import getEmployeesSelectData from '../../../models/getEmployeesSelectData';

const AssignCareSupport = ({issueId, UID, value, employeesAsCareSuuportData, disabled}) => {
  const { assignSupport } = useContext(IssuesContext);
  const { role } = useContext(UserDataContext);

  const selectSupportHandler = async (value, option ) => {
    const selectedEmployeeName = option.label.props.name // т.к. label - react-element
    await assignSupport(issueId, UID, {id: value, name: selectedEmployeeName});
  }

  const options = getEmployeesSelectData('issueAssign',  employeesAsCareSuuportData, role);
  return (
    <div>
      <Select
        value={value || null} // чтобы отобразить плейсхолдер если в значении пустая строка.
        style={{width: '200px'}}
        options={options}
        placeholder='Назначить...'
        onSelect={selectSupportHandler}
        popupMatchSelectWidth={false}
        bordered={false}
        disabled={disabled}
      />
    </div>
  );
};

export default AssignCareSupport;