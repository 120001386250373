import React from 'react';

const UnreadMessagesArea = ({unreadMessages}) => {
  return (
    <div  className='unread-messages__container'>
      <div  className="unread-notification">Непрочитанные сообщения</div>
      {unreadMessages}
    </div>
  );
};

export default UnreadMessagesArea;