import React from 'react';
import DetailsInfo from './DetailsInfo';
import DetailsPrices from './DetailsPrices';

const ApplicationDetails = ({curApplication, applicantsNumber}) => {
  return (
    <div className="application-details">
      <h3>Детали заявки</h3>
      <DetailsInfo curApplication={curApplication}/>
      <DetailsPrices curApplication={curApplication} applicantsNumber={applicantsNumber}/>
    </div>
  );
};

export default ApplicationDetails;