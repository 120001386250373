import { Upload } from 'antd';
import React, { useContext } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import '../../../../assets/documents/docs-by-sections/section-upload.scss';
import { DocSectionContext } from '../../../../contexts/context';

const SectionUpload = ({uploadIsDisabled, beforeUploadHandler}) => {
  const { section } = useContext(DocSectionContext);

  return (
    <div className='section__upload-container'>
      <Upload
        disabled={uploadIsDisabled}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          size: [200, 4],
          showInfo: true,
          format: (percent) => `${percent}%`,
        }}
        fileList={section.docs}
        multiple={section.maxDocs > 1}
        accept="application/pdf, image/jpeg, .pdf, .jpg, .jpeg, .jpe .jif, .jfif, .jfi"
        beforeUpload={beforeUploadHandler}
        showUploadList={false}
      >
        <div className={`section__upload-icon-container ${uploadIsDisabled ? 'upload-not-allowed' : ''}`}>
          <UploadOutlined className='section__upload-icon' style={{margin:'0 5px'}}/>
        </div>
      </Upload>
    </div>
  );
};

export default SectionUpload;