const numberCellRender = (text, _record, _index) => {
  return <p style={{fontWeight: '700'}}>{text}</p>
}

const columns = [
  {
    access:'all',
    key: "number",
    dataIndex: "number",
    title: "№",
    render: numberCellRender,
  },
  {
    access:'all',
    key: "shortId",
    dataIndex: "shortId",
    title: "ID",
  },
  {
    access:'all',
    key: "operatorName",
    dataIndex: "name",
    title: "Имя, Фамилия",
  },
  {
    access:'all',
    key: "phoneNumber",
    dataIndex: "phoneNumber",
    title: "Телефон",
  },
  {
    access:'all',
    key: "email",
    dataIndex: "email",
    title: "E-mail",
  },
  {
    access:'all',
    key: "applications",
    dataIndex: "applications",
    title: "Заявки",
  },
  {
    access:'all',
    key: "unreadMessages",
    dataIndex: "unreadMessages",
    title: "Непрочитанные сообщения",
  },
]

export default columns;