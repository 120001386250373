import React, {memo, useContext, useState} from 'react';
import { Select } from "antd";
import { UserDataContext } from '../../contexts/context';
import getEmployeesSelectData from '../../models/getEmployeesSelectData';
import { AllOperatorsContext } from '../../contexts/layoutContexts';

const  OperatorsSelect = memo(({changeOperatorIntern, transparent, curOperator, selectIsDisabled, size}) => {
  const { allOperators, allOperatorsLoading, allOperatorsError } = useContext(AllOperatorsContext)
  const { role } = useContext(UserDataContext);
  const handleSelectClick = (e) => {
    e.stopPropagation();
  }

  const handleSelect = async (selectedValue, option) => {
    const selectedEmployeeName = option.label.props.name // т.к. label - react-element
    changeOperatorIntern(selectedValue, selectedEmployeeName)
  }
  const employeeFilterhandler = (input, option) => {
    if('options' in option) {
      return false;
    } else {
      const label = option.label.props.name
      return label.toLowerCase().startsWith(input.toLowerCase())
    }
  }

  const errorValue = allOperatorsError && 'Ошибка'

  return (
    <div onClick={handleSelectClick}>
      <Select
        size={size}
        disabled={selectIsDisabled}
        bordered={!transparent}
        value={errorValue || curOperator}
        placeholder="Назначить"
        showSearch={true}
        filterOption={employeeFilterhandler}
        options={getEmployeesSelectData('operatorAssign', allOperators, role )}
        loading={allOperatorsLoading}
        popupMatchSelectWidth={false}
        style={{
          width: 136,
        }}
        onSelect={handleSelect}
      />
    </div>
  );
});

export default OperatorsSelect;
