import { collection, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { adminsPath } from "../../../firebase/admins/adminsPath";
import getAvailableEmployeesRoles from "../../../accesses/getAvailableEmployeesRoles";

const getAssignCareSupportDataQuery = () => {
  const availableRolesData = getAvailableEmployeesRoles('issueAssign');
  const availableRoles = availableRolesData.map(roleData => roleData.role);
  return query(
    collection(firestore, adminsPath.admins),
    where('role', 'in', [...availableRoles]),
    orderBy('role')
  )
}

export default getAssignCareSupportDataQuery;