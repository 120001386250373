import {  orderBy, query, where } from "firebase/firestore";
import getClientsRef from "../../../firebase/clients/getClientsRef";

const getDeletingOperatorClientsQuery = (id) => {
  return query(
    getClientsRef(),
    where('assignedTo', '==', id),
  );
};

export default getDeletingOperatorClientsQuery;