import dayjs from "dayjs";

export const getDateFromTimestamp = (firebaseTimestamp, withTime=null) => {

  if(!firebaseTimestamp) {
    return '';
  }
  // TODO: проверить какую дату получают пользователи из других временных зон
  const date = dayjs(firebaseTimestamp.toMillis());
  if(withTime === 'withTime') return date.format('DD.MM.YY HH:mm')
  return date.format('DD.MM.YY')
}
