const getEmployee = (allEmployees, employeeId) => {
  if(!allEmployees) return {}
  const employee = allEmployees.find(employee => employee.id === employeeId);
  if(!employee) {
    return {}
  } else {
    return employee;
  }
};

export default getEmployee;