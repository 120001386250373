import { addZero } from "../../../utils/addZero";

const getAwaitTime = (lastClientMessageTime) => {
  if (!lastClientMessageTime) return '';
  const date = lastClientMessageTime.toDate();
  const now = new Date();
  const diff = now - date;
  const days = Math.trunc(diff/1000/60/60/24); // получили целое количество дней.
  const hours = addZero(now.getHours() - date.getHours());
  const minutes = addZero(now.getMinutes() - date.getMinutes());
  const seconds = addZero(now.getSeconds() - date.getSeconds());
  return `${days}.${hours}:${minutes}:${seconds}`;
};

export default getAwaitTime;