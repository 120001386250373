import { limit, orderBy, query, where } from "firebase/firestore"

export const getQueryForAppsWithLimit = (ref,  authorizedUser, UID, filters, searchFilters, appsLimit = null, pageCount=null) => {
  let constraints = [
    where('isCreated', '==', true),
  ];

  if(UID) {
    // Если таблица грузится для одного клиента
    constraints.push(where('UID', '==', UID))
  }
  if (authorizedUser.role === 'operator') {
    constraints.push(where("preparedInformation.assignedTo", "==", authorizedUser.id));
  }

  if (searchFilters?.appsSearchValue) {
    if(searchFilters.selectedSearchOption === 'applicationId') {
      constraints.push(where('documentID', '>=', searchFilters.appsSearchValue))
      constraints.push(where('documentID', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("documentID"))
    }
    if(searchFilters.selectedSearchOption === 'clientId') {
      constraints.push(where('UID', '>=', searchFilters.appsSearchValue))
      constraints.push(where('UID', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("UID"))
    }
    if(searchFilters.selectedSearchOption === 'assignedTo') {
      constraints.push(where('preparedInformation.assignedTo', '>=', searchFilters.appsSearchValue))
      constraints.push(where('preparedInformation.assignedTo', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("preparedInformation.assignedTo"))
    }
    if(searchFilters.selectedSearchOption === 'phone') {
      constraints.push(where('applicantPhone', '>=', searchFilters.appsSearchValue))
      constraints.push(where('applicantPhone', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("applicantPhone"))
    }
    if(searchFilters.selectedSearchOption === 'email') {
      constraints.push(where('applicantEmail', '>=', searchFilters.appsSearchValue))
      constraints.push(where('applicantEmail', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("applicantEmail"))
    }
    if(searchFilters.selectedSearchOption === 'name') {
      constraints.push(where('applicantName', '>=', searchFilters.appsSearchValue))
      constraints.push(where('applicantName', '<=', searchFilters.appsSearchValue + '\uf8ff'))
      constraints.push(orderBy("applicantName"))
    }
    // при добавлении диапазонных ограничителей, если есть так же и упорядочивающие ограничители - первым идет упорядочивание по свойству, по которому стоит диапазонное ограничение. Затем указываются остальные упорядочивающие.
  }
  Object.entries(filters).forEach(entry => {
    const key = entry[0];
    const value = entry[1];
    if(value && value.length > 0) {
      if(key === 'status') {
        constraints.push(where("preparedInformation.preparationStatus", "in", value))
        constraints.push(orderBy("preparedInformation.preparationStatus"))
      }
      if(key === 'payment') {
        constraints.push(where("paymentSuccessful", "in", value))
        constraints.push(orderBy("paymentSuccessful"))
      }
      if(key === 'assignedTo') {
        constraints.push(where("preparedInformation.assignedTo", "in", value))
        constraints.push(orderBy("preparedInformation.assignedTo"))
      }
      if(key === 'countryFullName') {
        constraints.push(where("country_code", "in", value));
        constraints.push(orderBy("country_code"));
      }
    }
  })

  constraints.push(orderBy("isRead"))
  constraints.push(orderBy("createdAt", "desc"))

  if (appsLimit && appsLimit > 0 && pageCount && pageCount > 0) {
    constraints.push(limit(appsLimit * pageCount))
  }

  return query(ref, ...constraints)
}
