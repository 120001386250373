import React from 'react';
import GiftItem from './GiftItem';
import '../../../assets/application-additionals/application-information/gifts/gifts.scss';

const Gifts = ({gifts}) => {
  const giftItems = !gifts ? null : gifts.map((gift, index) => {
    return <GiftItem key={gift.id} gift={gift} giftNum={index + 1}/>
  })
  return (
    <div className='gifts__container'>
      <span className='gifts__title'>{`Подарки: `}</span>
      {!gifts ? 'Подарки не выбраны' : (
        <div className='gifts__items'>
          {giftItems}
        </div>
      )}
    </div>
  )
};

export default Gifts;