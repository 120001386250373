import React, { forwardRef, memo, useEffect, useLayoutEffect, useRef } from 'react';
import '../../../assets/chat/dialogue-list.scss';
import '../../../assets/loading.scss';
import { useLocation } from 'react-router-dom';
import { Card, Drawer } from 'antd';
import DialogueSearch from './DialogueSearch';
import DialoguesList from './DialoguesList';
import Spinner from '../../../components/Spinner';

const drawerContainerStyle = {position:'relative'}
const cardStyle = {padding:"0", borderRadius:"0", boxShadow:"none"};
const drawerClasses = {
  body: 'dialogues-list__body'
}

const DialoguesListContainer = memo(forwardRef(({chatListOpen, openDialogue, showLoader, closeMessenger}, ref) => {
  const dialoguesListContainerRef = useRef(null);
  const location = useLocation();

  useLayoutEffect(() => {
    // Сдвиг мессенджера от топа на высоту скролла страницы
    if (chatListOpen) {
      dialoguesListContainerRef.current.style.top = `${window.scrollY}px`
    }
  }, [chatListOpen, dialoguesListContainerRef])

  useEffect(() => {
    // при смене страницы сбрасываем отступ от топа
    dialoguesListContainerRef.current.style.top = `0px`
  }, [location])

  return (
    <div
      ref={dialoguesListContainerRef}
      style={drawerContainerStyle}
    >
      <Drawer
          classNames={drawerClasses}
          rootClassName="dialogues-list"
          placement="left"
          title={<DialogueSearch />}
          open={chatListOpen}
          mask={false}
          onClose={closeMessenger}
          getContainer={false}
          zIndex={100}
          width={500}
          afterOpenChange={() => console.log('дравер открылся')}
        >
          <div ref={ref}>
            <Card
              id='messengerCard'
              bordered={false}
              bodyStyle={cardStyle}
            >
              <DialoguesList openDialogue={openDialogue}/>
              {showLoader && <Spinner size='small'/>}
            </Card>
          </div>
        </Drawer>
    </div>
  );
}));

export default DialoguesListContainer;
