import { setDoc } from "firebase/firestore"
import combineNewUserData from "./combineNewUserData"
import getAdminDocRef from "../../../firebase/admins/getAdminDocRef"

export const createDbOperatorObject = async (newOperatorFormValues, newUser ) => {
  try {
    const newUserData = combineNewUserData(newOperatorFormValues, newUser);
    await setDoc(getAdminDocRef(newUser.uid), newUserData);
  } catch (error) {
    console.log(error);
    throw error;
  }
}