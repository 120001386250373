import { signOut } from 'firebase/auth';
import React, { useContext } from 'react';
import { auth } from '../../../firebase/firebase';
import { Button } from 'antd';
import { UserDataContext } from '../../../contexts/context';

const AccessDenied = () => {
  const handleSignOut = () => {
    signOut(auth)
  }

  return (
    <div style={{width:'99vw', height: '100vh'}}>
      <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'10%'}}>
        <div style={{padding:'30px', width:'fit-content', color:'black', fontSize:'24px', fontFamily:'sans-serif'}}>
          <p  style={{color:'red', marginBottom:'20px'}}>Доступ запрещен!</p>
          <Button type='primary' onClick={handleSignOut}>Выйти</Button>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
