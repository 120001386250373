const getClientsTableData = (clientsData) => {
  return clientsData.map((client, index) => {
    return {
      key: client.UID,
      number: index + 1,
      shortId: client.UID.slice(0,4),
      name: client?.name || '',
      phoneNumber: client?.phone || '',
      email: client?.email || '',
      applications: '',
      unreadMessages: '',
    }
  })
}

export default getClientsTableData;