import React from 'react';
import AdditionalServicesItem from './AdditionalServicesItem';

const AdditionalServices = ({curApplication, applicantsNumber}) => {

  const additionalServices = curApplication.additionalServices.map(selectedService => {
    return <AdditionalServicesItem key={selectedService.id} service={selectedService} applicantsNumber={applicantsNumber}/>
  })

  return (
    <div className="additional-services">
      <h3>Дополнительные услуги</h3>
      <ul className='additional-services__list'>
        {additionalServices}
      </ul>
    </div>
  );
};

export default AdditionalServices;