import { Button } from 'antd';
import React, { memo, useContext } from 'react';
import '../../../assets/messenger/dialogue-header.scss'
import { useParams } from 'react-router-dom';
import { UserDataContext } from '../../../contexts/context';
import FinishIssue from '../../../components/FinishIssue';
import { useUpdateMessengerContext } from '../context/MessengerDataProvider';

const DialogueHeader = memo(({UID, supportId, issueId, totalApps, goToAppFromMessenger}) => {
  const { clientId } = useParams();
  const { authorizedUser } = useContext(UserDataContext);
  const { setSelectedChat, setSelectedChatSnapId } = useUpdateMessengerContext()

  const goToAppFromMessengerHandler = () => {
    goToAppFromMessenger(UID, clientId)
    setSelectedChat(null)
    setSelectedChatSnapId(null)
  }

  return (
    <div className='dialogue-drawer__header'>
      <h3 className='dialogue-drawer__title'>Чат</h3>
      <div className='dialogue-drawer__buttons'>
        {supportId === authorizedUser.id ? (
          <FinishIssue
            UID={UID}
            issueId={issueId}
          />
        ) : (
          null
        )}
        {totalApps > 0 ? (
          <Button
            onClick={goToAppFromMessengerHandler}
            type='primary'
          >
            Перейти к заявкам
          </Button>
        ) : (
          null
        )}
      </div>
    </div>
  );
});

export default DialogueHeader;