import { doc, getDocs, query, runTransaction } from "firebase/firestore";
import { getChatQueryForApplication } from "../chat/getChatQueryForApplication";
import { firestore } from "../firebase";
import issuePaths from "./issuesPaths";

const finishIssue = async (UID, issueId, dialogueSnapRef=null) => {
  try {
    let dialogueRef;
    if (dialogueSnapRef) {
      dialogueRef = dialogueSnapRef;
    } else {
      const dialogueCollSnap = await getDocs(query(getChatQueryForApplication(UID)));
      if(dialogueCollSnap.empty) throw new Error('Чат не найден');
      dialogueRef = dialogueCollSnap.docs[0].ref;
    }
    await runTransaction(firestore, async (transaction) => {
      transaction
      .update(dialogueRef, {supportId: ''})
      .update(doc(firestore, issuePaths.issues, issueId), {status: 'finished'})
    })
  } catch (error) {
    console.log(error)
  }
}

export default finishIssue;