import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { Layout, Menu, ConfigProvider, Input } from "antd";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import '../../../assets/header/head.scss';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import HeadMenu from "./HeadMenu";
import { theme } from "antd";
import { UserDataContext } from "../../../contexts/context";
import LogoutButton from "./LogoutButton";
import { GLOBAL_ROLES_RU } from "../../../constants/constants";
import getHeadMenuItems from "../../helpers/getHeadMenuItems";
import checkLocationIsFromTopMenu from "../../helpers/checkLocationIsFromTopMenu";
import HeadSearch from "./HeadSearch";
import { checkToShow } from "../config/searchConfig";
const { getDesignToken, useToken } = theme;
const { Header } = Layout;
const { Search } = Input;

const Head = memo(() => {
  // contexts
  const { features, role, authorizedUser } = useContext(UserDataContext)
  // states
  const [ selectedMenuItem, setSelectedMenuItem ] = useState([]);
  // router
  const navigate = useNavigate();
  const location = useLocation();
  // ant-design-hooks
  const { token } = useToken();

  useEffect(() => {
    // подсвечивает выбранный пункт меню, либо нет, если location не из меню шапки.
    checkLocationIsFromTopMenu(location.pathname) ? setSelectedMenuItem([location.pathname]) : setSelectedMenuItem([]);
  }, [location])

  const handleLogoClick = () => {
    navigate('/')
  }

  const handleSignOut = () => {
    signOut(auth)
  }

  const roleRu = `${GLOBAL_ROLES_RU[role]}: `;

  const showSearchPanel = useMemo(() => {
    if(features.showHeaderSearchBar) {
      return checkToShow(location.pathname)
    } else {
      return false;
    }
  }, [features.showHeaderSearchBar, location.pathname])

  return (
    <ConfigProvider
      theme={{
        components:{
          Layout: {
            headerBg: token.colorBgLayout
          }
        }
      }}
    >
      <Header className='head'>
        <div className="logo__container">
          <Logo
            className='logo'
            onClick={handleLogoClick}
          />
        </div>
        <div className='head__content'>
          {showSearchPanel ?  (
            <HeadSearch />
          ) : (
            <div className='head__stub'></div>
          )}
          <div className="top-navigation">
            <LogoutButton handleSignOut={handleSignOut} name={authorizedUser.name} roleRu={roleRu}/>
            <HeadMenu >
              <Menu
                className='menu'
                style={{
                  justifyContent: "center",
                  marginLeft: "20px"
                }}
                items={getHeadMenuItems()}
                mode="horizontal"
                selectedKeys={selectedMenuItem}
              />
            </HeadMenu>
          </div>
        </div>
      </Header>
    </ConfigProvider>
  )
})

export default Head;
