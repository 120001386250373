import React from 'react';
import '../../assets/news/news-picture-preview.scss'

const NewsPicturePreview = ({url}) => {
  return (
    <div className='news__picture-preview-container'>
      <img
        className='news__picture-preview'
        src={url}
        alt='newsImage'
      ></img>
    </div>
  )
};

export default NewsPicturePreview;