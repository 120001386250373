import getAssignedToFilterData from "../../../models/getAssignedToFilterData";
import { assignedSectionsColumns } from "./assigned-sections-columns";

const getSectionsTableColumns = (assistantsData, sectionsTableFilters, role) => {
  return assignedSectionsColumns.reduce((acc, column) => {
    const additionalProps = {};
    if(column.key === 'assistantId') {
      if(role === 'assistant') return acc;
      additionalProps.filters = getAssignedToFilterData('assistantAssign', assistantsData);
    }
    if(sectionsTableFilters?.[column.key]?.length) {
      additionalProps.defaultFilteredValue = sectionsTableFilters[column.key];
    }
    acc.push({...column, ...additionalProps});
    return acc;
  }, [])
};

export default getSectionsTableColumns;