import React from 'react';
import '../../../assets/push-history/push-history-item.scss'
import dayjs from 'dayjs';

const PushHistoryItem = ({push, num}) => {
  const time = dayjs(push.createdAt.toMillis()).format('YYYY.MM.DD hh:mm:ss');

  return (
    <div className='push-history-item__container'>
      <span className='push-history-item__num'>{num}</span>
      <div className='push-history-item__content'>
        <h3 className='push-history-item__title'>{push.title}</h3>
        <div className='push-history-item__text'>{push.body}</div>
      </div>
      <div className='push-history-item__metadata'>
        <span>{time}</span>&nbsp;
        <span>{push.author.name}</span>
      </div>
    </div>
  );
};

export default PushHistoryItem;