import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import '../../../assets/news/new-news-form/new-news-form.scss';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import { uploadBytesResumable } from 'firebase/storage';
import { getFileRef } from '../../../firebase/firebase';
import getUploadedFileDownloadURL from '../../../firebase/getUploadedFileDownloadURL';
import showNotification from '../../NotificationService/helpers/showNotification';
import MyEditor from '../../../CKeditor/components/MyEditor';
import ImageUpload from '../../../components/ImageUpload';
import readFileAsDataURL from '../../../utils/readFileAsDataURL';
import NewNewsFormPicturePreview from './NewNewsFormPicturePreview';
import createNewsDataObject from '../helpers/createNewsDataObject';
import saveNewsData from '../firebase/saveNewsData';
import FilesUpload from '../../../components/FilesUpload/FilesUpload';
import downloadNewsFiles from '../../../firebase/files/downloadNewsFiles';

const NewNewsForm = ({reloadNews}) => {
  const { authorizedUser } = useContext(UserDataContext);
  const { notificationAPI } = useContext(ProgramContext);
  const [ headPictureList, setHeadPictureList ] = useState([]);
  const [ fileList, setFileList ] = useState([]);
  const [ imgPreviewUrl, setImgPreviewUrl ] = useState(null);
  const [ newNewsText, setNewNewsText ] = useState('')
  const [ loading, setLoading ] = useState(false);
  const [ myEditorInstance, setMyEditorInstance ] = useState(null);

  const changeText = (editor) => {
    setNewNewsText(editor.getData())
  }

  const deletePicture = () => {
    setHeadPictureList([]);
    setImgPreviewUrl('');
  }

  const deleteFile = (file) => {
    setFileList(prev => prev.filter(fileInfo => fileInfo.uid !== file.uid));
  }

  const beforeUploadHandler = async (file, fileList) => {
    setHeadPictureList(fileList);
    const url = await readFileAsDataURL(file);
    setImgPreviewUrl(url);
    return Promise.reject();
  }

  const filesBeforeUpload = async (file, fileList) => {
    // const url = await readFileAsDataURL(file);
    setFileList(prev => [...prev, {
      uid: file.uid,
      fileOrigin: file,
      link: '',
      downloadURL: '',
      name: file.name,
    }])

    return false;
  }

  const publish = async () => {
    setLoading(true);
    try {
      if(!newNewsText) {
        throw new Error('Содержание новости не может быть пустым')
      }
      const newsDataObject = createNewsDataObject(newNewsText, authorizedUser);
      // предполагается, что картинка к новости может быть только одна.
      if(headPictureList.length) {
        const uploadResult = await uploadBytesResumable(getFileRef(`news/${newsDataObject.id}/title-picture-${Date.now()}`), headPictureList[0]);
        const url = await getUploadedFileDownloadURL(uploadResult.metadata.fullPath);
        newsDataObject.picture.downloadUrl = url;
        newsDataObject.picture.name = uploadResult.metadata.name;
      }
      if(fileList.length) {
        const path = `news/${newsDataObject.id}`;
        const dataToUpload = await downloadNewsFiles(fileList, path, setFileList);
        newsDataObject.files = dataToUpload;
      }
      await saveNewsData(newsDataObject);
      reloadNews();
      setHeadPictureList([]);
      setFileList([])
      setImgPreviewUrl('');
      setNewNewsText('');
      myEditorInstance.setData('');
      showNotification(notificationAPI, 'process', {processName:'news', status:'success'});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName:'news', status:'error'});
    } finally {
      setLoading(false);
    }
  }

  return (
    <div
      className='new-news-form__container'
    >
      <NewNewsFormPicturePreview url={imgPreviewUrl} deleteFunc={deletePicture}/>
      <div className='new-news-form__text-container'>
        <MyEditor changeText={changeText} setMyEditorInstance={setMyEditorInstance} disabled={loading}/>
      </div>
      <div className='new-news-form__footer'>
        <div className='new-news-form__actions'>
          <div className='new-news-form__picture-download-icon'>
            <ImageUpload
              fileList={headPictureList}
              beforeUploadHandler={beforeUploadHandler}
            />
          </div>
          <div className='new-news-form__files-download-container'>
            <FilesUpload
              fileList={fileList}
              filesBeforeUpload={filesBeforeUpload}
              deleteFile={deleteFile}
              disableDeletion={loading}
            />
          </div>
        </div>
        <div className='new-news-form__upload-button'>
          <Button
            type="primary"
            onClick={publish}
            disabled={loading}
            loading={loading}
          >
            Опубликовать
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewNewsForm;
