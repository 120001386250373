import { serverTimestamp, updateDoc } from "firebase/firestore";

const readUnreadSection = (sectionDocSnapRef) => {
  try {
    updateDoc(sectionDocSnapRef, {'isRead': true})
  } catch (error) {
    throw error
  }
};

export default readUnreadSection;