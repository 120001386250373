import React from 'react';
import '../../assets/components/file-list/file-list.scss';
import ListItem from './ListItem';

const FileList = ({fileList, onDelete, disableDeletion, allowDeletion=true}) => {
  const list = fileList.map((fileInfo, index) => {
    return <ListItem
      key={fileInfo.uid}
      fileInfo={fileInfo}
      index={index}
      onDelete={onDelete}
      disableDeletion={disableDeletion}
      allowDeletion={allowDeletion}
    />
  })

  return (
    <div className='file-list__container'>
      <div className='file-list__list'>
        {list}
      </div>
    </div>
  );
};

export default FileList;