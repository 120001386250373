import React from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import '../../assets/components/files-num/files-num.scss';

const FilesNum = ({filesLenght, maxNum, disabled}) => {
  return (
    <div className={`files-num__container ${disabled ? 'disabled' : ''}`}>
      <FileTextOutlined className='files-num__num-icon'/>
      <sub className='files-num__num'>
        {filesLenght}/{maxNum}
      </sub>
    </div>
  );
};

export default FilesNum;