import getUploadedFileDownloadURL from "../../../firebase/getUploadedFileDownloadURL";

const getUpdatedData = async (newsDataState, uploadedPictureResult, filesInfoToUpload) => {
  try {
    const updatedData = newsDataState;
    if(uploadedPictureResult) {
      const url = await getUploadedFileDownloadURL(uploadedPictureResult.metadata.fullPath);
      updatedData.picture = {
        name: uploadedPictureResult.metadata.name,
        downloadUrl: url,
      }
    }
    if(filesInfoToUpload) {
      updatedData.files = [...updatedData.files, ...filesInfoToUpload];
    }
    return updatedData;
  } catch (error) {
    throw error;
  }
}

export default getUpdatedData;