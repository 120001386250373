import React, { useState, useRef, useContext, useLayoutEffect } from 'react';
import { ProgramContext, UserDataContext } from '../../../contexts/context.js';
import MyError from '../../Error/components/MyError.jsx';
import ChatFooter from './ChatFooter.jsx';
import "../../../assets/chat/chat.scss";
import findUnreadMessages from '../helpers/findUnreadMessages.js';
import { getCountFromServer } from 'firebase/firestore';
import ChatMessages from './ChatMessages.jsx';
import getMessagesQuery from '../firebase/getMessagesQuery.js';
import { useCollection } from 'react-firebase-hooks/firestore';
import { LayoutFuncsContext, ScrollModeContext } from '../../../contexts/layoutContexts.js';
import readUnreadMessages from '../firebase/readUnreadMessages.js';
import editName from '../../../firebase/clients/editName.js';
import showNotification from '../../NotificationService/helpers/showNotification.js';
import ChatHeader from './ChatHeader.jsx';

const Chat = ({applicantId, source, dialogue, selectedChatSnapId, clientData, clientLoading}) => {
  // contexts
  const { authorizedUser, role } = useContext(UserDataContext);
  const { scrollMode } = useContext(ScrollModeContext);
  const { switchChatScrollMode } = useContext(LayoutFuncsContext)
  const { notificationAPI } = useContext(ProgramContext);
  // states
  const [ uploadingMessageWithAttachments, setUploadingMessageWithAttachments ] = useState([]);
  const [ resultMessagesDocSnaps, setResultMessagesDocSnaps ] = useState([])
  const [ messagesPages, setMessagesPages ] = useState(1);
  const [ clientIsEditing, setClientIsEditing ] = useState(false);
  // downloading data
  const [ messagesCollSnapshot, messagesLoading, messagesError ] = useCollection(getMessagesQuery(selectedChatSnapId, messagesPages));
  // refs
  const allMessages = useRef(null);
  const firstMessageOffset = useRef(0);
  const scrolledToTop = useRef(false)
  // variables

  useLayoutEffect(() => {
    // Если доскроллили до верха:
    // 1.добавляем страницу
    // 2.сохраняем offsetTop самого раннего сообщения
    // 3.сохраняем информацию, что доскроллили. Тем самым блокируя повторные вызовы функции, пока новые данные не отрисуются.
    //   После отрисовки скролл займет нужное положение и инфомрация, что доскролили сбросится. Разблокировав данную функцию.
    if(!messagesLoading) {
      const messagesContainer = allMessages.current;
      const handleScrollToTop = async () => {
        if(messagesContainer.scrollTop === 0) {
          if(!scrolledToTop.current) {
            const aggregateSnapshot = await getCountFromServer(getMessagesQuery(selectedChatSnapId));
            if (aggregateSnapshot.data().count !== resultMessagesDocSnaps.length) {
              const children = Array.from(messagesContainer.children).find(child => child.classList.contains('message__container'))
              setMessagesPages(prev => ++prev);
              firstMessageOffset.current = children.offsetTop;
              scrolledToTop.current = true;
            }
          }
        }
      }
      messagesContainer.addEventListener('scroll', handleScrollToTop);
      return () => messagesContainer.removeEventListener('scroll', handleScrollToTop);
    }
  }, [selectedChatSnapId, messagesLoading, resultMessagesDocSnaps.length])

  useLayoutEffect(() => {
    // Сохраняем загруженные данные в стейт. Сразу из хука нельзя брать, т.к. при загрузке возвращает undefined
    if (messagesCollSnapshot) {
      setResultMessagesDocSnaps(messagesCollSnapshot.docs.reverse())
    }
  }, [messagesCollSnapshot])

  useLayoutEffect(() => {
    // Когда загруженные данные сохарнились в стейт и чат был проскроллен к верху:
    // 1. устанавливаем скролл в позицию последнего сообщения предыдущего набора данных.
    // 2. сбрасываем информацию, что доскроллили.
    if(resultMessagesDocSnaps.length && scrolledToTop.current) {
      const array = Array.from(allMessages.current.children).filter(child => child.classList.contains('message__container'))
      const elementToScroll = array[array.length - ((messagesPages - 1) * 50)];
      allMessages.current.scrollTop = elementToScroll.offsetTop - firstMessageOffset.current
      scrolledToTop.current = false;
    }
  }, [resultMessagesDocSnaps]) // не добавлять messagesPages, иначе эффект отработает раньше времени, а при обновлении resultMessagesDocSnaps функция не выполнится.

  useLayoutEffect(() => {
    // Включает/выключает режим скроллинга чата.
    if(!messagesLoading) {
      const messagesContainer = allMessages.current;
      const handleScroll = () => {
        const scrolledToBottom = Math.abs(messagesContainer.scrollHeight - messagesContainer.clientHeight - Math.round(messagesContainer.scrollTop)) <= 1;
        switchChatScrollMode(!scrolledToBottom );
      }
      messagesContainer.addEventListener('scroll', handleScroll);
      return () => messagesContainer.removeEventListener('scroll', handleScroll);
    }
  }, [messagesLoading, switchChatScrollMode])

  useLayoutEffect(() => {
    // Установка скролла в позицию самого низа списка сообщений.
    // Работает в случае scrollMode = false (чат пролистан к самому низу списка сообщений), чтобы при появлении нового сообщения чат скроллился книзу.
    if(resultMessagesDocSnaps.length && !scrollMode) {
      allMessages.current.scrollTop = allMessages.current.scrollHeight;
    }
  },[resultMessagesDocSnaps, scrollMode])

  useLayoutEffect(() => {
    // если я ответственный за клиента (назначен на чат и его незавершенные заявки) - прочитывать все непрочитанные сообщения пассивно,
    // но только в режиме scrollMode=false(чат пролистан к самому низу)
    // таким образом, если я не ответственный - значит я наблюдатель (приведение), то есть не прочитываю сообщений. Могу отправлять и наблюдать.
    if(!messagesLoading && dialogue.assignedTo === authorizedUser.id) {
      try {
        const unreadMessagesDocs = findUnreadMessages(resultMessagesDocSnaps);
        if(!scrollMode && unreadMessagesDocs.length) {
          readUnreadMessages(unreadMessagesDocs, role)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },[authorizedUser.id, authorizedUser.role, dialogue.assignedTo, messagesLoading, resultMessagesDocSnaps, role, scrollMode])

  const changeClientData = async (newData) => {
    try {
      setClientIsEditing(true)
      await editName(applicantId, newData)
      showNotification(notificationAPI, 'process', {processName: 'clientChanged', status: 'success'})
    } catch (error) {
      console.log(error);
      showNotification(notificationAPI, 'process', {processName: 'clientChanged', status: 'error'})
    } finally {
      setClientIsEditing(false)
    }
  }

  if (messagesError) {
    return <MyError error={messagesError}/>
  }


  const unreadMessagesDocs = findUnreadMessages(resultMessagesDocSnaps);

  return (
    <div className="chat__container" >
      <ChatHeader
        dialogue={dialogue}
        clientData={clientData}
        applicantId={applicantId}
        source={source}
        selectedChatSnapId={selectedChatSnapId}
        changeClientData={changeClientData}
        clientIsEditing={clientIsEditing}
      />

      <ChatMessages
        ref={allMessages}
        messageDocs={resultMessagesDocSnaps}
        assignedTo={dialogue.assignedTo}
        uploadingMessageWithAttachments={uploadingMessageWithAttachments}
      />
      <ChatFooter
        selectedChatSnapId={selectedChatSnapId}
        dialogue={dialogue}
        unreadMessagesDocs={unreadMessagesDocs}
        applicantId={applicantId}
        allMessagesRef={allMessages}
        setUploadingMessageWithAttachments={setUploadingMessageWithAttachments}
      />
    </div>
  );
};

export default Chat;
