import React from 'react';
import '../../../assets/application-additionals/application-information/gifts/gift-item.scss';

const GiftItem = ({gift, giftNum}) => {
  return (
    <div className='gift__container'>
      <div className='gift-num'>{`${giftNum}. `}</div>
      <div className='gift-name'>{gift.name}</div>
      <div className='gift-description'>{gift.description.toLowerCase()}</div>
    </div>
  );
};

export default GiftItem;