import React from 'react';
import '../../../../assets/news/editor/news-editor-apply-changes.scss';

const NewsEditorApplyChanges = ({children}) => {
  return (
    <div className='news-editor-apply-changes__container'>
      <div className='news-editor-apply-changes__inner'>
        {children}
      </div>
    </div>
  );
};

export default NewsEditorApplyChanges;