import React, { useContext } from 'react';
import { Input } from "antd";
import prepareChanges from '../helpers/prepareChanges.js';
import getResponse from '../helpers/getResponse.js';
import { QuestionnaireContext } from '../../../contexts/context.js';

const TypeTextAnswer = ({questionData, questionIndex, personIndex, nestedOptions={isNested:false}}) => {
  const {answersToUpdate, setAnswersToUpdate, isEdit} = useContext(QuestionnaireContext);

  const handleChange = (e) => {
    // при изменении сохраняем объект {newResponse, index} в answersToUpdate.
    setAnswersToUpdate(prepareChanges(answersToUpdate, e.target.value, questionData, questionIndex, personIndex, nestedOptions));
  }

  const response = getResponse(answersToUpdate, questionData, questionIndex, nestedOptions, personIndex); // строка

  return isEdit ? (
    <div>
      <Input bordered={true} onChange={handleChange} value={response}/>
    </div>
  ) : (
    <div style={{color:"black", font:"400 17px Jost, sans-serif"}}>
        {response}
    </div>
  )
};

export default TypeTextAnswer;
