import React, {useState, useRef, useContext, useCallback, useMemo, useLayoutEffect } from "react";
import { ConfigProvider, Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import Head from "./head/components/Head";
import Aside from "./sider/components/Aside";
import '../assets/app-layout.scss';
import '../assets/notification/notification.scss';
import { useCollectionData } from "react-firebase-hooks/firestore";
import getClientsQuery from "../firebase/clients/getClientsQuery";
import MyError from "../modules/Error/components/MyError";
import Messenger from "../modules/Messenger/components/Messenger";
import { Timestamp } from "firebase/firestore";
import { UserDataContext } from "../contexts/context";
import getChatsWithUnreadMessagesQuery from "./firebase/getChatsWithUnreadMessagesQuery";
import countUnreadMessages from "./helpers/countUnreadMessages";
import getChatsInNeedOfHelpQuery from "./firebase/getChatsInNeedOfHelpQuery";
import LayoutContextsProvider from "../contexts/LayoutContextsProvider";
import getUnreadSectionsQuery from "./firebase/getUnreadSectionsQuery";
import NewApplicationNotificationService from "../modules/NotificationService/components/NewApplicationNotificationService";
import TimeToReloadMessage from "./TimeToReloadMessage";
import getUnreadNewsQuery from "./firebase/getUnreadNewsQuery";
import MessengerDataProvider from "../modules/Messenger/context/MessengerDataProvider";

const {Content} = Layout;

const CLIENTS_QUERY = getClientsQuery();

const AppLayout = () => {
  // contexts
  const { authorizedUser, role, features } = useContext(UserDataContext);
  // states
  const [ chatListOpen, setChatListOpen] = useState(false);
  const [ scrollMode, setScrollMode ] = useState(false);
  const [ dialogueWindowOpen, setDialogueWindowOpen ] = useState(false);
  // data downloading
  const [ clientsData, clientsLoading, clientsError ] = useCollectionData(CLIENTS_QUERY);
  const [ chatsWithUnreadMessages, chatsWithUnreadMessagesLoading, chatsWithUnreadMessagesError ] = useCollectionData(features.canUseMessenger ? getChatsWithUnreadMessagesQuery(authorizedUser) : null) // запрос на чаты с непрочитанными сообщениями
  const [ chatsInNeedOfHelp, chatsInNeedOfHelpLoading, chatsInNeedOfHelpError ] = useCollectionData(features.helpFeatureEnabled ? getChatsInNeedOfHelpQuery(authorizedUser) : null) // запрос на чаты, нуждающиеся в помощи.
  const [ unreadSections, unreadSectionsLoading, unreadSectionsError ] = useCollectionData(features.sectionsFeatureEnabled ? getUnreadSectionsQuery(authorizedUser) : null);
  const [ unreadNews, unreadNewsLoading, unreadNewsError ] = useCollectionData(getUnreadNewsQuery(authorizedUser.lastNewsViewTime));
  // refs
  const loginTime = useRef(Timestamp.now());
  const totalUnreadMessagesCount = useMemo(() => countUnreadMessages(chatsWithUnreadMessages),[chatsWithUnreadMessages]);
  const chatsInNeedOfHelpCount = chatsInNeedOfHelp?.length || 0;
  const unreadSectionsCount = unreadSections?.length || 0;
  const unreadNewsCount = unreadNews?.length || 0;
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if(chatListOpen) {
      const bodyClientWidthBefore = document.body.clientWidth;
      document.body.setAttribute('style', 'overflow: hidden');
      const bodyClientWidthAfter = document.body.clientWidth;
      document.body.setAttribute('style', `overflow: hidden; padding-right: ${bodyClientWidthAfter - bodyClientWidthBefore}px`);
      return () => document.body.setAttribute('style', 'overflow: auto');
    }
  }, [chatListOpen])

  const handleMenuSelect = useCallback(({item, key, keyPath, selectedKeys, domEvent}) => {
    if (key === "/chat") {
      setChatListOpen((prev) => !prev)
    }
  }, [])

  const closeChatList = useCallback(() => {
    setChatListOpen(false);
    setDialogueWindowOpen(false);
    setScrollMode(false);
  }, [])

  const goToAppFromMessenger = useCallback((dialogueId, clientId) => {
    if (clientId !== dialogueId) { // проверка не открыта ли заявка этого клиента сейчас.
      navigate(`/application/${dialogueId}`); // переходим на старницу заявки, если у клиента есть заявки.
    }
    closeChatList();
  },[closeChatList, navigate]);

  const openDialogue = useCallback((dialogue) => {
    setScrollMode(false);
    setDialogueWindowOpen(true);
  }, [])

  const closeDialogue = useCallback(() => {
    setDialogueWindowOpen(false);
  }, [])

  const switchChatScrollMode = useCallback((value) => {
    setScrollMode(value);
  }, [])

  const themeContext = useMemo(() => {
    return {
      token: {
        colorBgLayout: '#F8F8F8',
      },
    }
  }, [])

  if(clientsLoading) {
    return
  }

  if (clientsError ) {
    return <MyError error={clientsError }/>
  }

  return (
    <ConfigProvider
      theme={themeContext}
    >
      <LayoutContextsProvider
        scrollMode={scrollMode}
        switchChatScrollMode={switchChatScrollMode}
        clientsData={clientsData}
        closeDialogue={closeDialogue}
      >
        <TimeToReloadMessage />
        {features.showNewAppNotifications && <NewApplicationNotificationService loginTime={loginTime.current} />}
        <Layout className="app-layout">
          <Head />
          <Layout className="main" hasSider>
            <Aside
              chatListOpen={chatListOpen}
              handleMenuSelect={handleMenuSelect}
              chatsInNeedOfHelpCount={chatsInNeedOfHelpCount}
              unreadSectionsCount={unreadSectionsCount}
              totalUnreadMessagesCount={totalUnreadMessagesCount}
              unreadNewsCount={unreadNewsCount}
            />
            <Content className="content" >
              {features.canUseMessenger ? (
                <MessengerDataProvider>
                  <Messenger
                    chatListOpen={chatListOpen}
                    dialogueWindowOpen={dialogueWindowOpen}
                    closeChatList={closeChatList}
                    goToAppFromMessenger={goToAppFromMessenger}
                    openDialogue={openDialogue}
                    loginTime={loginTime}
                  />
                </MessengerDataProvider>
              ) : null }
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </LayoutContextsProvider>
    </ConfigProvider>
  )
}

export default AppLayout;