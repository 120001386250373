import React, {useContext} from 'react';
import { Select } from "antd";
import { chatCurrentStatus } from '../helpers/chat-active-status'
import { dialogueActions } from '../helpers/chat-active-status';
import { chatActiveStatusActionOptions } from '../helpers/chat-active-status';
import '../../../assets/chat/chat.scss';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import { DialogueCloseContext } from '../../../contexts/layoutContexts';
import assignAndUnassignToMe from '../firebase/assignAndUnassignToMe';
import showNotification from '../../NotificationService/helpers/showNotification';
import getChatRef from '../../../firebase/chat/getChatRef';

const ChatActiveStatus = ({dialogueAssignedTo, dialogue, selectedChatSnapId}) => {
  const { authorizedUser, role } = useContext(UserDataContext)
  const { closeDialogue } = useContext(DialogueCloseContext)
  const { notificationAPI } = useContext(ProgramContext);

  const handleChange = async (value, _option) => {
    try {
      if(value === dialogueActions.finish.value) {
        closeDialogue()
      }
      await assignAndUnassignToMe( value, authorizedUser.id, dialogue.supportId, dialogue.UID, getChatRef(selectedChatSnapId))
      showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'success',})
    } catch (error) {
      if(error.message === 'Current employee already busy with this client') {
        showNotification(notificationAPI, 'process', {processName: 'employeeIsBusy', status:'error'})
      } else {
        showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'error',})
      }
    }

  }

  const value = dialogueAssignedTo ? chatCurrentStatus.inProgress : chatCurrentStatus.finished;
  const selectOptions =  dialogueAssignedTo ? chatActiveStatusActionOptions.finish : chatActiveStatusActionOptions.takeInProgress;
  const clientHaveApps = dialogue.totalApps > 0;

  return (
    <div className="chat__active-status">
      {(role === 'operator' && value === dialogueActions.takeInpRogress.value) || clientHaveApps || dialogue.accountDeleted ? (
        <span>{value}</span>
      ) : (
        <Select
          popupMatchSelectWidth={false}
          disabled={(role === 'operator' && value === dialogueActions.takeInpRogress.value) || clientHaveApps} // операторы не могут взять на себя сами в работу или если есть заявки.
          style={{
            width: "fit-content",
          }}
          onSelect={handleChange}
          options={selectOptions}
          value={value}
          bordered={false}
        />
      )}
    </div>
  );
};

export default ChatActiveStatus;
