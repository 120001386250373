import getEmployee from "../../../helpers/employee/getEmployee";
import { getDateFromTimestamp } from "../../../utils/getDateFromTimestamp";
import getAwaitTime from "../helpers/getAwaitTime";

const getClientsByAppsTableData = (clientByAppsResultData, countries, allOperators) => {
  return clientByAppsResultData.map((clientData, ) => {
    return {
      key:clientData.UID,
      UID: clientData.UID,
      UIDshort: clientData.UID.slice(0,4),
      hasUnreadApps: clientData.hasUnreadApps,
      lastAppCreatedAt : getDateFromTimestamp(clientData.lastAppCreatedAt, 'withTime'),
      awaitTime: getAwaitTime(clientData.lastClientMessageTime),
      applicant: clientData.name ? clientData.name : `ID: ${clientData.UID.slice(0,4)}`,
      citizenship: clientData.citizenship.toUpperCase(),
      phone: clientData.phone,
      email: clientData.email,
      assignedTo: clientData.assignedTo,
      mainAppApplicants: clientData.mainApp.passportsNum,
      mainAppStatus: clientData.mainApp.status,
      mainAppPayment: clientData.mainApp.paymentSuccessful,
      mainAppPrice: `${clientData.mainApp.price_USD} KZT`,
      mainAppAssignedTo: getEmployee(allOperators, clientData.mainApp.assignedTo),
      mainAppIIN: clientData.mainApp.iin,
      mainAppPaymentDate: getDateFromTimestamp(clientData.mainApp.confirmDate, 'withTime'),
      mainAppCountryFullName:clientData.mainApp.country_name_ru,
    }
  })
};

export default getClientsByAppsTableData;

