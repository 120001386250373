import { ConfigProvider, theme } from 'antd';
import React from 'react';
const { useToken } = theme;


const HeadMenu = ({children}) => {
  const { token } = useToken();

  return (
    <div className='menu__container'>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemBg: token.colorBgLayout,
            }
          }
        }}
      >
        {children}
      </ConfigProvider>
    </div>
  );
};

export default HeadMenu;
