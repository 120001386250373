import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';
import ApplicationDownloadData from './ApplicationDownloadData';

 //SUPER-IMPORTANT COMPONENT!!!
const ApplicationContainer = () => {
  const { clientId } = useParams();
  const [ clientIdState, setClientIdState ] = useState(clientId)

  useEffect(() => {
    if (clientIdState !== clientId) setClientIdState(clientId)
  },[clientId, clientIdState])

  if (clientIdState !== clientId) {

    // unmount ApplicationDownloadData if clientId from URL params dont match with stated clientId, and render ApplicationDownloadData again after useEffect has finished
    return (
      <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Spin size="large"/>
      </div>
    )
  }

  return (
    // <ApplicationWorkArea clientId={clientIdState}/>
    <ApplicationDownloadData clientId={clientIdState}/>
  );
};

export default ApplicationContainer;
