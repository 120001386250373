const getPaymentFilters = () => {
  return [
    {
      text: 'оплачено',
      value: true,
    },
    {
      text: 'не оплачено',
      value: false,
    }
  ]
}

export default getPaymentFilters;