import { chatPaths } from "../../../firebase/chat/chatsPath";
import { createNewMessageObject } from "../helpers/createNewMessageObject";
import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import showNotification from "../../NotificationService/helpers/showNotification";
import getChatRef from "../../../firebase/chat/getChatRef";

/**
 * Prepares new messages instance and upoads it to the firebase.
 * @param {*} text
 * @param {*} authorizedUser
 * @param {*} chatDocRef
 * @param {*} messages
 * @param {*} attachmentsArray
 */
export const sendMessage = async (notificationAPI, text, authorizedUser, dialogue, selectedChatSnapId, unreadMessagesDocs, attachmentsArray) => {
  // const messages = chatDoc.get('messages')
  const chatRef = getChatRef(selectedChatSnapId)

  try {
    const newMessage = createNewMessageObject(text, authorizedUser, attachmentsArray, dialogue.assignedTo, dialogue.supportId);
    // const allReadMessagesOrFalse = authorizedUser.role !== 'admin' && makeAllMessagesReadIfTheyAreNot(messages); // супер визовик не должен отправлять сообщения, пока не назначен на чат. А когда назначен - то как визовик.
    // const newMessagesToUpload = [...(allReadMessagesOrFalse || messages), newMessage];
    // TODO: отрефаторить updateDocField;
    await runTransaction(firestore, async (transaction) => {
      transaction.set(doc(chatRef, chatPaths.messagesSubCollection, newMessage.id), newMessage);
      transaction.update(chatRef, {lastEdited: serverTimestamp()});
      // if(authorizedUser.role !== 'admin' && authorizedUser.role !== 'careSupport' && chatDoc.get('assignedTo') === authorizedUser.id ) { // если мы не админ и не забота и назначены на заявку.
      if(dialogue.assignedTo === authorizedUser.id) { // если назначенный визовик или супервизовик
        unreadMessagesDocs.forEach(messageDoc => {
          transaction.update(messageDoc.ref, {
            readBy: [...messageDoc.get('readBy'), authorizedUser.role],
            isReadFromCRM: true,
          });
        });
      }
    })

  } catch (error) {
    console.log(error)
    showNotification(notificationAPI, 'process', {processName: 'sendMessage', status: 'error'})
  }
};
