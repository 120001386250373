import React from 'react';
import '../../assets/components/error-list/error-list.scss';

const ErrorList = ({isMaxDocsError, maxDocs}) => {
  return (
    <div className={`error-list ${isMaxDocsError ? '' : 'not-display'}`}>
      Общее количество документов не более {maxDocs}
    </div>
  );
};

export default ErrorList;