import { query, where } from "firebase/firestore"
import { getChatsCollectionRef } from "./getChatsCollectionRef"

// ищет документ в коллекции, в котором поле UID содержит искомый айди юзера.
export const getChatQueryForApplication = (applicantId) => {
  // Визовик тоже должен получать чат, т.к. страница записывает в глобальный реф - чат текущего клиента.
  // TODO: вынести эту логику, убрать загрузку чата для ApplicationWorkArea.
  const constraints = [
    where("UID", "==", applicantId),
  ]

  return query(getChatsCollectionRef(), ...constraints);
};
