import { useEffect, useState } from "react";
import getAssignedSectionsQuery from "../firebase/getAssignedSectionsQuery";
import { getCountFromServer } from "firebase/firestore";

const useAssignedSectionsCount = (authorizedUser, assignedSections, sectionsTableFilters) => {
  const [ count, setCount ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    // получаение общего количества назначенных секций
    const getAllAppsCount = async () => {
      try {
        const aggregateSnapshot = await getCountFromServer(getAssignedSectionsQuery(authorizedUser.role, authorizedUser.id, sectionsTableFilters));
        setCount(aggregateSnapshot.data().count);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    getAllAppsCount();
  }, [authorizedUser, assignedSections, sectionsTableFilters])

  return [
    count,
    loading,
    error,
  ]
}

export default useAssignedSectionsCount;