import { serverTimestamp } from "firebase/firestore";

const createAssistantAssignData = (assistantOption, section, applicationInfo, applicantId, applicantName) => {
  return {
    assistantId: assistantOption.value,
    assistantName: assistantOption.label,
    applicationInfo,
    applicantId: applicantId,
    applicantName: applicantName,
    sectionInfo: {
      sectionName: section.sectionTitle,
      sectionId: section.sectionId,
    },
    lastActivity: serverTimestamp(),
    isRead: false,
    step: 1, // статус секции.
  }
}

export default createAssistantAssignData;