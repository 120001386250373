import React, { useContext } from 'react';
import DocumentsContent from './DocsBySections/DocumentsContent';
import LegacyDocumentsContent from './LegacyDocuments/LegacyDocumentsContent';
import '../../../assets/documents/documents.scss';
import { UserDataContext } from '../../../contexts/context';

const Documents = ({uploadedDocs, curAppDocumentId, passports, countryCode, servicePrice, citizenship, assistantsData}) => {
  const { role } = useContext(UserDataContext)
  return (
    <div className={`documents-section ${role === 'assistant' ? 'assistant' : ''}`}>
      <div className="uploaded-documents" >
        <h3 style={{textAlign:'center', marginBottom:'2%'}}>
          Готовые документы:
        </h3>
        <div className="uploaded-documents__upload-section">
          {uploadedDocs.length && uploadedDocs[0].applicantId === undefined ? ( // если массив содержит объекты и в 1 объекте нет свйоства applicantId - значит исопльзовалась старая система.
            <LegacyDocumentsContent uploadedDocs={uploadedDocs} curAppDocumentId={curAppDocumentId}/>
          ) : (
             <DocumentsContent
              passports={passports}
              uploadedDocs={uploadedDocs}
              countryCode={countryCode}
              servicePrice={servicePrice}
              citizenship={citizenship}
              assistantsData={assistantsData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
