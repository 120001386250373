const sortTextElements = (filters) => {
  return filters.sort((a, b) => {
    const first = a.text.toLowerCase();
    const second = b.text.toLowerCase();
    if(first > second) return 1;
    if(first < second) return -1;
    return 0;
  });
}

export default sortTextElements;