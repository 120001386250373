import React from 'react';
import '../../../assets/application-additionals/details-info-item.scss';

const DetailsInfoItem = ({detail, value, curApplication}) => {

  return (
    <li className='detailsInfo__list-item'>
      <p className='detailsInfo__name'>{detail}</p>
      <div className='detailsInfo__value'>{`${curApplication[value.path]} ${value.units}`}</div>
    </li>
  );
};

export default DetailsInfoItem;
