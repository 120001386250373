import React from 'react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../assets/ckeditor/my-editor.scss';

const editorConfiguration = {
  // toolbar: [ 'bold', 'italic', ]
  removePlugins: [
    "CKFinderUploadAdapter",
    "CKBox",
    "CKFinder",
    "EasyImage",
    'Image',
    'ImageCaption',
    // 'ImageStyle',
    // 'ImageToolbar',
    'ImageUpload',
    'MediaEmbed'
  ],
};

const MyEditor = ({changeText, initialData='', setMyEditorInstance, disabled}) => {
  const textChangeHandler = (e, editor) => {
    changeText(editor);
  }

  const onReadyHandler = (editor) => {
    if(setMyEditorInstance) {
      setMyEditorInstance(editor)
    }
  }

  return (
    <div className={`my-editor__container ${disabled ? 'disabled' : ''}`}>
      <CKEditor
        editor={Editor.Editor}
        config={ editorConfiguration }
        data={initialData}
        onReady={onReadyHandler}
        onChange={textChangeHandler}
        // onBlur={ ( event, editor ) => {
        //     console.log( 'Blur.', editor );
        // } }
        // onFocus={ ( event, editor ) => {
        //     console.log( 'Focus.', editor );
        // } }
      />
    </div>
  );
};

export default MyEditor;