import { serverTimestamp } from "firebase/firestore"
import { nanoid } from "nanoid"

const createCommentObj = (text, authorizedUser) => {
  return {
    createdAt: serverTimestamp(),
    id: nanoid(),
    text: text,
    attachments: [],
    author:{
      role: authorizedUser.role,
      id: authorizedUser.id,
      name: authorizedUser.name,
    },
    type:'message',
  }
}

export default createCommentObj;