import React, { forwardRef, useContext } from 'react';
import DateDivider from './DateDivider';
import { getMessageCreationDate } from '../helpers/getMessageCreationDate';
import { getMessageCreationTime } from '../helpers/getMessageCreationTime';
import { getClassNameForMessage } from '../helpers/getClassNameForMessage';
import { memoizedCreationDate } from '../helpers/memoizedCreationDate';
import { UserDataContext } from '../../../contexts/context';
import Message from './Message';
import EmptyFillingBox from './messages/EmptyFillingBox';
import UnreadMessagesArea from './messages/UnreadMessagesArea';
import { ScrollModeContext } from '../../../contexts/layoutContexts';

const ChatMessages = forwardRef(({messageDocs, assignedTo, uploadingMessageWithAttachments }, ref) => {
  const { authorizedUser } = useContext(UserDataContext);
  const { scrollMode } = useContext(ScrollModeContext);

  let result = [<EmptyFillingBox key={"invisible-container"}/>,];
  let unreadMessages = [];

  const isDateNew = memoizedCreationDate();

  messageDocs.forEach((messageDoc,index) => { // В будующем можно будет получить реф, если нужно будет добавить редактирование сообщения.
    const message = messageDoc.data();
    const messageCreationDate = getMessageCreationDate(message.time.toMillis());
    const messageCreationTime = getMessageCreationTime(message.time.toDate()); // метод toDate возвращает js Date object с потерей точности до секунд.
    const classNameForMessage = getClassNameForMessage(message.sender);

    if(isDateNew(messageCreationDate)) {
      result.push(
        <DateDivider
          key={messageCreationDate}
          date={messageCreationDate}
        />
      )
    }

    if(!message.isReadFromCRM) {
      // супервизовик как админ, если не назначен или как оператор если назначен.
      // админ и careSupport видит картину визовика.
      // визовик и назначенный супервизовик видит непрочитанные сообщения, если скроллит по чату.
      const meNotAssigned = authorizedUser.id !== assignedTo;
      const meAssigneeAndScrolling = authorizedUser.id === assignedTo && scrollMode;

      if (meNotAssigned || meAssigneeAndScrolling) {
        unreadMessages.push(
          <Message
            key={message.id}
            styleClass={classNameForMessage}
            message={message}
            time={messageCreationTime}
          />
        )
        return;
      }
    }

    result.push(
      <Message
        key={message.id}
        styleClass={classNameForMessage}
        message={message}
        time={messageCreationTime}
      />
    )
  })

  if (unreadMessages.length > 0) {
    result.push(
      <UnreadMessagesArea key="unread-notification" unreadMessages={unreadMessages}/>
    )
  }

  if( uploadingMessageWithAttachments.length > 0 ) { // показываем отправляемое сообщение с прикрепленными файлами, пока оно не будет записано в БД
    uploadingMessageWithAttachments.forEach((message, index) => {
      const messageCreationTime = getMessageCreationTime(new Date(message.time));
      result.push(
        <Message
          key={message.time}
          styleClass={"message__content operator"}
          message={message}
          time={messageCreationTime}
          attachmentsIsLoading={true}
        />
      )
    })
  }

  return (
    <ul ref={ref} className="chat__messages">
      {result}
    </ul>
  )
});

export default ChatMessages;