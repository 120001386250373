import { collection, query, where } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { chatPaths } from "../../firebase/chat/chatsPath";

const getChatsWithUnreadMessagesQuery = (authorizedUser) => {
  const constraints = [
    where('unreadMessagesForCRM', '>', 0),
  ]

  if(authorizedUser.role === 'operator') {
    constraints.push(where('assignedTo', '==', authorizedUser.id)); // операторы получают только свои чаты.
  }

  return query(collection(firestore, chatPaths.chatCollection), ...constraints );
};

export default getChatsWithUnreadMessagesQuery;