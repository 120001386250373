import { orderBy, query, where } from "firebase/firestore";
import { getAppsCollRef } from "../../../firebase/applications/getAppsCollRef";

// запрос на получение всех заявок клиентов. 
const getApplicationsToAssign = (clientId) => {
  const constraints = [
    where('isCreated', '==', true),
    where('UID', "==", clientId ),
    where('preparedInformation.preparationStatus', '<=', 4),
  ]

  return query(getAppsCollRef(), ...constraints)
};

export default getApplicationsToAssign;
