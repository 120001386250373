const getAppIdWithFinishedStatus = (allClientAppsData, authorizedUserId ) => {
  // находим последнюю завершенную заявку этого визовика
  return allClientAppsData.reverse().find(app => { 
    const status = app.preparedInformation.preparationStatus;
    const meAssigned = authorizedUserId === app.preparedInformation.assignedTo;
    return meAssigned && status > 4;
  }).documentId;
  
}

export default getAppIdWithFinishedStatus;