import { Form, Input } from 'antd';
import React, { useState } from 'react';
import ApplyOrCancel from '../../../components/ApplyOrCancel';
import '../../../assets/application-additionals/application-information/change-price-form.scss'

const { TextArea } = Input;


const ChangePriceForm = ({cancelChanges, handleChangePrice, priceSaving}) => {
  const [ emailFormInstance ] = Form.useForm();

  const applyChanges = () => {
    emailFormInstance.submit();
  }

  const handleFinishForm = async ({newPrice, comment}) => {
    try {
      await handleChangePrice(newPrice, comment);
    } catch {}
  }

  return (
    <Form
      disabled={priceSaving}
      form={emailFormInstance}
      onFinish={handleFinishForm}
      className='change-price-form'
      layout='vertical'
    >
      <Form.Item
        name='newPrice'
        label='Новая цена'
        rules={[
          {
            required: true,
            message: 'Пожалуйста, введите новую цену',
          },
          (formInstance) => {
            return {
              validator: (rule, value) => {
                if(!value || value.match(/^[\d]*$/g)) {
                  // пустая строка только из чисел.
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error('Допустим ввод только чисел'))
                }
              }
            }
          }
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        name='comment'
        label='Комментарий'
        rules={[
          {
            required: true,
            message: 'Пожалуйста, введите комментарий',
          },
        ]}
      >
        <TextArea
          classNames={{textarea: 'comment'}}
        />
      </Form.Item>
      <ApplyOrCancel isEdit={true} applyChanges={applyChanges} cancelChanges={cancelChanges} loading={priceSaving} />
    </Form>
  );
};

export default ChangePriceForm;