import React from 'react';
import "../../../assets/chat/message.scss";
import getAttachments from '../helpers/getAttachments';


const Message = ({styleClass, message, time, attachmentsIsLoading=false}) => {
  const attachments = message.attachments.length > 0 ? getAttachments(message.attachments, attachmentsIsLoading) : null;
  const senderName = message.sender !== "me"
    ? (
        <div className="message__senderName">
          {message.sender}
        </div>
      )
    : null

  return (
    <li className="message__container">
      <div className={styleClass}>
        <div className='message__title'>
          {senderName}
          {/* <div className='message-menu'>
            <div className='message-menu__dot'></div>
            <div className='message-menu__dot'></div>
            <div className='message-menu__dot'></div>
          </div> */}
        </div>
        {attachments}
        <p className="message__text">
          {message.content || <i>пустое сообщение</i>}
        </p>
        <div className="message__status">
          <span className="message__created-time">{time}</span>
        </div>
      </div>
    </li>
  );
};

export default Message;

