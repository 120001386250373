import React, { useContext } from 'react';
import GLOBAL_ROLES from './constants/constants';
import { ProgramContext, UserDataContext } from './contexts/context';
import { notification } from 'antd';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppLayout from './layout/AppLayout';
import ApplicationsPage from './pages/ApplicationsPage/components/ApplicationsPage';
import ProfilePage from './pages/Profile/components/ProfilePage';
import NotificationsBoard from './pages/NotificationsBoard/components/NotificationsBoard';
import ApplicationContainer from './pages/ApplicationWorkArea/components/ApplicationContainer';
import AccountsManager from './pages/AccountsManager/components/AccountsManager';
import Clients from './modules/Clients/components/Clients';
import NewsPage from './pages/News/components/NewsPage';
import AssistantPage from './pages/AssistantPage/components/AssistantPage';
import PushCreatorPage from './pages/PushCreator/components/PushCreatorPage';
import CarePage from './pages/CarePage/components/CarePage';
import ClientsByAppsPage from './pages/ClientApps/ClientsByAppsPage';
import ClientsByApps from './modules/ClientApps/components/ClientsByApps';
import ClientPage from './pages/ClientPage.jsx/ClientPage';

const notificationsConfigGlobal = {
  placement: 'topRight',
  stack: {
    threshold: 3,
  },
}

const WorkRoutes = () => {
  const [ api, contextHolder ] = notification.useNotification(notificationsConfigGlobal);
  const { role } = useContext(UserDataContext);

  if(role === GLOBAL_ROLES.careSupport) {
    return  (
      <ProgramContext.Provider value = {{ notificationAPI:api}}>
        {contextHolder}
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<ApplicationsPage />}/>
            <Route path='client-by-apps'  element={<ClientsByAppsPage />}/>
            <Route path="client-apps/:UID" element={<ClientPage />}/>
            <Route path="users-manager" element={<AccountsManager />}/>
            <Route path="clients" element={<Clients />}/>
            <Route path="news" element={< NewsPage />}/>
            <Route path="user-profile" element={<ProfilePage />}/>
            <Route path="push-creator" element={<PushCreatorPage />}/>
            <Route path="notifications" element={<NotificationsBoard />}/>
            <Route path="assistant-page" element={<AssistantPage />}/>
            <Route path='care-page' element={<CarePage/>}/>
            <Route path="application/:clientId/:appId?" element={<ApplicationContainer />}/>
          </Route>
          <Route path="/*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </ProgramContext.Provider>
    )
  }
  
  if(role === GLOBAL_ROLES.operator) {
    return  (
      <ProgramContext.Provider value = {{ notificationAPI:api}}>
        {contextHolder}
        <Routes>
          <Route path="/" element={ <AppLayout /> }>
            <Route index element={ <ApplicationsPage/> }/>
            <Route path="news" element={< NewsPage />}/>
            <Route path="user-profile" element={< ProfilePage />}/>
            <Route path="notifications" element={< NotificationsBoard />}/>
            <Route path="application/:clientId/:appId?" element={< ApplicationContainer/>}/>
          </Route>
          <Route path="/*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </ProgramContext.Provider>
    )
  }

  if(role === GLOBAL_ROLES.assistant) {
    return  (
      <ProgramContext.Provider value = {{ notificationAPI:api}}>
        {contextHolder}
        <Routes>
          <Route path="/" element={<AppLayout /> }>
            <Route index element={<AssistantPage />}/>
            <Route path="news" element={< NewsPage />}/>
            <Route path="user-profile" element={< ProfilePage />}/>
            <Route path="notifications" element={< NotificationsBoard />}/>
            <Route path="application/:clientId/:appId?" element={< ApplicationContainer/>}/>
          </Route>
          <Route path="/*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </ProgramContext.Provider>
    )
  }

  if(role === GLOBAL_ROLES.admin) {
    return  (
      <ProgramContext.Provider value = {{ notificationAPI:api}}>
        {contextHolder}
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<ApplicationsPage />}/>
            <Route path='client-by-apps'  element={<ClientsByAppsPage />}/>
            <Route path="client-apps/:UID" element={<ClientPage />}/>
            <Route path="users-manager" element={<AccountsManager/>}/>
            <Route path="clients" element={<Clients/>}/>
            <Route path="news" element={< NewsPage />}/>
            <Route path="care-page" element={<CarePage/>}/>
            <Route path="user-profile" element={<ProfilePage />}/>
            <Route path="push-creator" element={<PushCreatorPage />}/>
            <Route path="notifications" element={<NotificationsBoard />}/>
            <Route path="assistant-page" element={<AssistantPage />}/>
            <Route path="application/:clientId/:appId?" element={<ApplicationContainer />}/>
          </Route>
          <Route path="/*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </ProgramContext.Provider>
    )
  }

  if(role === GLOBAL_ROLES.superOperator) {
    return  (
      <ProgramContext.Provider value = {{ notificationAPI:api}}>
        {contextHolder}
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<ApplicationsPage />}/>
            <Route path='client-by-apps'  element={<ClientsByAppsPage />}/>
            <Route path="client-apps/:UID" element={<ClientPage />}/>
            <Route path="users-manager" element={<AccountsManager />}/>
            <Route path="clients" element={<Clients />}/>
            <Route path="news" element={< NewsPage />}/>
            <Route path="care-page" element={<CarePage />}/>
            <Route path="user-profile" element={<ProfilePage />}/>
            <Route path="push-creator" element={<PushCreatorPage />}/>
            <Route path="notifications" element={<NotificationsBoard />}/>
            <Route path="assistant-page" element={<AssistantPage />}/>
            <Route path="application/:clientId/:appId?" element={<ApplicationContainer />}/>
          </Route>
          <Route path="/*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </ProgramContext.Provider>
    )
  }
};

export default WorkRoutes;