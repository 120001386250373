import { serverTimestamp } from "firebase/firestore";
import { nanoid } from "nanoid";

const getChangeAssigneeLogData = (authorizedUser, operatorName, operatorId) => {
  return {
    id: nanoid(),
    appointer: {
      name: authorizedUser.name,
      id: authorizedUser.id,
    },
    assignee: {
      name: operatorName,
      id: operatorId,
    },
    changedAt: serverTimestamp(),
  }
}

export default getChangeAssigneeLogData;