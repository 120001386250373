import { updateDoc } from "firebase/firestore";

import getAdminDocRef from "../../../firebase/admins/getAdminDocRef";

export const updateOperatorProfile = async (authorizedUser, values) => {
  console.log(values)
  try {
    await updateDoc(getAdminDocRef(authorizedUser.id), values)
  } catch (e) {
    throw e;
  }
};
