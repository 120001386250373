import React from 'react';
import PaymentIcon from '../../../components/PaymentIcon';

const PaymentType = ({paymentType, paymentSuccessful}) => {
  return (
    <div>
      <span style={{fontSize:'1rem', fontWeight:'700'}} >Оплата: </span>
      <span style={{fontSize:'1rem', color:'red'}} >{paymentType}</span>
      <div style={{display:'inline-block', marginLeft:'10px'}}>
        <PaymentIcon payment={paymentSuccessful} />
      </div>
    </div>
  );
};

export default PaymentType;
