import { httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import { useState } from "react";
import { functions } from "./firebase/firebase";

const useEmployeesPartialData = (onlyActive) => {
  const [ data, setData ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const getEmployeesPartialData = httpsCallable(functions, 'getEmployeesPartialData');
    getEmployeesPartialData(JSON.stringify({properties: ['id', 'name', 'role'], onlyActive}))
    .then(res => {
      setData(res.data)
      setLoading(false);
    })
    .catch(error => setError(error))
  }, [onlyActive])

  return [
    data,
    loading,
    error
  ]
}

export default useEmployeesPartialData;