import React from 'react';
import { DeleteFilled } from '@ant-design/icons';
import ItemText from './ItemText';
import { Progress } from 'antd';
import '../../assets/components/file-list/list-item.scss';

const ListItem = ({fileInfo, index, onDelete, disableDeletion, allowDeletion}) => {

  const deleteFileHandler = (e) => {
    onDelete(fileInfo)
  }

  return (
    <div className='file-list__list-item'>
      <div className='file-list__content'>
        <ItemText fileInfo={fileInfo} index={index}/>
        {allowDeletion ? (
          <div className={`file-list__delete-icon-container ${(disableDeletion) ? 'disabled' : ''}`} onClick={deleteFileHandler}>
            <DeleteFilled className='file-list__delete-icon'/>
          </div>
        ) : (
          null
        )}
      </div>
      {fileInfo.status === 'uploading' ? (
        <div>
          <Progress
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            size={[200, 4]}
            showInfo= {true}
            format={(percent) => `${percent}%`}
            percent={fileInfo.percent}
          />
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export default ListItem;