const issueStatuses = {
  new: {
    id: 'new',
    titleRu: 'новые',
    tagColor: "processing",
  },
  inProgress: {
    id: 'inProgress',
    titleRu: 'В процессе',
    tagColor: "yellow",
  },
  finished: {
    id: 'finished',
    titleRu: 'Закрытые',
    tagColor: "success",
  },
}

export default issueStatuses;