import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import EntryPage from './pages/EntryPage/components/EntryPage';
import AuthContainer from './modules/Auth/components/AuthContainer';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <EntryPage>
            <AuthContainer />
          </EntryPage>
        }
      />
      <Route path="*" element={<Navigate to="login" replace={true}/>} />
    </Routes>
  );
};

export default AuthRoutes;