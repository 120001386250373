import React from 'react';
import {FileTextOutlined} from '@ant-design/icons';
import '../../../assets/chat/application-exist-info.scss';
import getNumberOfApplications from '../helpers/getNumberOfApplications';

const iconStyle = {fontSize:"16px"}

const ApplicationExistsInfo = ({appsCount}) => {
  const numberOfApplications = getNumberOfApplications(appsCount)

  return appsCount > 0 ? (
    <div className="application-exists">
      <div className="application-exists__container">
        <div className="application-exists__icon">
          <FileTextOutlined style={iconStyle}/>
        </div>
        <div className="application-exists__content">
          {numberOfApplications}
        </div>
      </div>
    </div>
  ) : (
    <div className="application-not-exists"></div>
  )
};

export default ApplicationExistsInfo;