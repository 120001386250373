const allAppsCheckAssignedToAndStatus = (allClientAppsData, authorizedUser) => {
  let meOperatorAssignedAndInProgress = false;
  let meOperatorAssignedAndFinished = false;

  // узнаем, есть ли назначенные на визовика заявки в работе или законченные.
  allClientAppsData.forEach(app => { 
    const status = app.preparedInformation.preparationStatus;
    const meOperatorAssigned = app.preparedInformation.assignedTo === authorizedUser.id && authorizedUser.role === 'operator';
    if (status <= 4 && meOperatorAssigned && !meOperatorAssignedAndInProgress) {
      meOperatorAssignedAndInProgress = true;
    }
    if(status > 4 && meOperatorAssigned && !meOperatorAssignedAndFinished) {
      meOperatorAssignedAndFinished = true;
    }
  })

  return {
    meOperatorAssignedAndInProgress,
    meOperatorAssignedAndFinished
  }
}
export default allAppsCheckAssignedToAndStatus;
