import { Button, Form, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { UserAuthContext, ProfileContext, ProgramContext, UserDataContext } from '../../../contexts/context';
import { updateOperatorProfile } from '../firebase/updateOperatorProfile';
import { EmailAuthProvider, reauthenticateWithCredential, signOut, updateEmail, updatePassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';
import RegularDataForm from './RegularDataForm';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import '../../../assets/profile/profile-form.scss';
import Auth from '../../Auth/components/Auth';
import ReAuthModalTitle from './ReAuthModalTitle';
import showNotification from '../../NotificationService/helpers/showNotification';
import ConfirmPopUp from './ConfirmPopUp';

const ProfileData = () => {
  const { user } = useContext(UserDataContext);
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser } = useContext(UserDataContext);
  const [ profileUpdating, setProfileUpdating ] = useState(false);
  const [ curEditingForm, setCurEditingForm ] = useState('');
  const [ authModalOpened, setAuthModalOpened ] = useState(false);
  const [ emailState, setEmailState ] = useState(); // вынесли стейт имейла , т.к. нельзя получить стейт из formProvider функции handleFormProvider, т.к. не реализован вызов модалки асинхронно из фукнции.
  const [ passwordState, setPasswordState ] = useState([{name: 'pass', value: ''}, {name: 'confirm', value: ''}]); // вынесли стейт имейла , т.к. нельзя получить стейт из formProvider функции handleFormProvider, т.к. не реализован вызов модалки асинхронно из фукнции.

  const handleModalClose = () => {
    setAuthModalOpened(false);
    setProfileUpdating(false);
  }

  const reauthenticate = async (email, password) => {
    // TODO: спустить в функцию handleFormProvider, если сделаю асинхронный вызов модалки.
    console.log(email, password)
    try {
      const credential = EmailAuthProvider.credential(email, password); // связываем email и пароль с текущим firebase acc и получаем credential.
      console.log(credential)
      const userCredential = await reauthenticateWithCredential(user, credential); // реавторизация
      if (curEditingForm === 'emailForm') {
        await updateEmail(userCredential.user, emailState[0].value); // изменяем firebase acc
        await updateOperatorProfile(authorizedUser, {[emailState[0].name]: emailState[0].value});// изменяем firestore запись.
      }
      if (curEditingForm === 'passwordForm') {
        await updatePassword(userCredential.user, passwordState[0].value); // изменяем firebase acc
      }
      setAuthModalOpened(false);
      showNotification(notificationAPI, 'process', {processName: 'updateAdmin', status: 'success',})
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setCurEditingForm('');
      setProfileUpdating(false);
    }
  }

  const emailOrPaswordFormFinish = () => {
    setAuthModalOpened(true)
  }

  const handleSignOut = () => {
    signOut(auth)
  }

  return (
    <ProfileContext.Provider value={{profileUpdating, setProfileUpdating, curEditingForm, setCurEditingForm}}>
      <div className='profile__update-form' style={{marginTop:'5%'}}>
        <RegularDataForm name='regularDataForm' />
        <EmailForm name='emailForm' emailState={emailState} setEmailState={setEmailState} emailOrPaswordFormFinish={emailOrPaswordFormFinish}/>
        <PasswordForm name='passwordForm' passwordState={passwordState} setPasswordState={setPasswordState} emailOrPaswordFormFinish={emailOrPaswordFormFinish}/>
        <Button danger onClick={handleSignOut}>Выйти из аккаунта</Button>
      </div>
      <ConfirmPopUp
        authModalOpened={authModalOpened}
        handleModalClose={handleModalClose}
        reauthenticate={reauthenticate}
      />
    </ProfileContext.Provider>
  )
};

export default ProfileData;
