import { doc } from "firebase/firestore";
import { applicationsPath } from "../../../firebase/applications/appsPath";
import { getAppRefById } from "../../../firebase/applications/getAppRefById";

const getDocSectionRef = (appId, applicantId, sectionId) => {
  const { documents, sections } = applicationsPath;
  const sectionPath = `${documents}/${applicantId}/${sections}/${sectionId}`;
  return doc(getAppRefById(appId), sectionPath);
}

export default getDocSectionRef;