import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "../firebase";

const editName = async (id, newData) => {
  try {
    const querySnapshot = await getDocs(query(collection(firestore, 'users'), where('UID', '==', id)));
    if(querySnapshot.docs.length > 1) {
      throw new Error('Клиентов с данным ID больше одного')
    }
    if(querySnapshot.empty) {
      throw new Error('Клиент не найден')
    }
    const clientDoc = querySnapshot.docs[0];
    await updateDoc(clientDoc.ref, newData)
  } catch (error) {
    throw error;
  }
};

export default editName;