import React from 'react';
import StatusesHistoryItem from './StatusesHistoryItem';

const StatusesHistory = ({historyData}) => {
  const historyLog = historyData.map(entry => {
    return <StatusesHistoryItem key={entry.id} entry={entry}/>
  })

  return (
    <div className="statuses-history">
      <h3 className='statuses-history__title'>
        История изменения статусов:
      </h3>
      <ul className='statuses-history__list'>
        {historyLog}
      </ul>
    </div>
  );
};

export default StatusesHistory;
