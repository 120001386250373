import { serverTimestamp } from "firebase/firestore";
import { nanoid } from "nanoid";

const createPushObj = (pushState, authorizedUser) => {
  return {
    id: nanoid(),
    body:pushState.body ,
    title: pushState.title,
    createdAt: serverTimestamp(),
    author:{
      id: authorizedUser.id,
      name: authorizedUser.name,
    },
  }
}

export default createPushObj;