import React from 'react';
import '../../../assets/application-additionals/application-information.scss';
import PaymentType from './PaymentType';
import AdditionalServices from './AdditionalServices';
import ApplicationDetails from './ApplicationDetails';
import getPaymentTypes from '../helpers/getPaymentTypes';
import CreatedDate from './CreatedDate';
import Gifts from './Gifts';

const ApplicationInformation = ({curApplication}) => {
  const paymentType = getPaymentTypes()[curApplication.paymentType];

  return (
    <div className='application-information' >
      <CreatedDate date={curApplication.createdAt}/>
      <PaymentType paymentType={paymentType} paymentSuccessful={curApplication.paymentSuccessful} />
      <Gifts gifts={curApplication.gifts}/>
      <AdditionalServices curApplication={curApplication} applicantsNumber={curApplication.passports.length} />
      <ApplicationDetails curApplication={curApplication} applicantsNumber={curApplication.passports.length} />
    </div>
  );
};

export default ApplicationInformation;