import React from 'react';
import RoleIcons from './RoleIcons';
import '../assets/components/employee-item.scss';

const EmployeeItem = ({name, role}) => {
  return (
    <div className='employee__container'>
      {!name ? (
        <span className='employee__not-found'>
          Не назначено
        </span>
      ) : (
        <>
          <div className='employee__role-icon-container'>
            <RoleIcons role={role} iconSize='20px'/>
          </div>
          <div className='employee__name'>
            {name}
          </div>
        </>
      )}

    </div>
  );
};

export default EmployeeItem;