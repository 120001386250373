import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import getClientQueryByUID from '../getClientQueryByUID';

const useClientData = (clientID) => {
  const [ clientCollSnapshot, clientLoading, clientError ] = useCollection(clientID ? getClientQueryByUID(clientID) : null);
  const [ resultClientDocSnap, setResultClientDocSnap ] = useState(null)

  useEffect(() => {
    // Сохраняем загруженные данные в стейт. Сразу из хука нельзя брать, т.к. при загрузке возвращает undefined
    if (clientCollSnapshot) {
      setResultClientDocSnap(clientCollSnapshot.docs[0])
    }
  }, [clientCollSnapshot])

  return [
    resultClientDocSnap,
    clientLoading,
    clientError,
  ]
};

export default useClientData;