import { testStatuses } from "../helpers/app-status"

const getStatusesFilters = () => {
  return Object.values(testStatuses).map(value => {
    return {
      text: value.buttonFilterValue,
      value: value.dbProp,
    }
  })
}

export default getStatusesFilters;
