import React from 'react';
import '../../../assets/push-history/push-history.scss';
import PushHistoryList from './PushHistoryList';

const PushHistory = ({pushHistory, pushHistoryLoading, pushHistoryError}) => {
  return (
    <div className='push-history__inner'>
      <h2 className='push-history__title'>История</h2>
      <div className="push-history__list-container">
        <PushHistoryList
          pushHistory={pushHistory}
          pushHistoryLoading={pushHistoryLoading}
          pushHistoryError={pushHistoryError}
        />
      </div>
    </div>
  );
};

export default PushHistory;