import React from 'react';
import NewsListItemHeader from './NewsListItemHeader';
import parse from 'html-react-parser';
import NewsContent from '../NewsContent';
import NewListItemPicturePreview from './NewListItemPicturePreview';
import FileList from '../../../../components/FileList/FileList';
import NewsListItemFiles from './NewsListItemFiles';


const NewsListItemInner = ({news, setIsEdit, date}) => {
  return (
    <div>
      <NewsListItemHeader
        date={date}
        authorName={news.author.name}
        setIsEdit={setIsEdit}
      />
      <NewListItemPicturePreview url={news.picture.downloadUrl} />
      <NewsContent>
        <>{parse(news.content)}</>
      </NewsContent>
      <NewsListItemFiles
        fileList={news.files}
        allowDeletion={false}
      />

    </div>
  );
};

export default NewsListItemInner;