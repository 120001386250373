import { serverTimestamp } from "firebase/firestore";
import { nanoid } from "nanoid";

const createServiceCommentData = (authorizedUser, operation) => {
  return {
    author: {
      id:authorizedUser.id,
      name: authorizedUser.name,
      role: authorizedUser.role,
    },
    createdAt: serverTimestamp(),
    id: nanoid(),
    operation,
    type:'service',
  }
}

export default createServiceCommentData;