export const addFileToFileList = (fileListState, file) => {
  return [
    ...fileListState,
    {
      name: file.name,
      status: 'uploading', // чтобы Upload отобразил прогресс бар.
      uid: file.uid
    }
  ]
};
