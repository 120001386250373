import React from 'react';
import NewsPicturePreview from '../../../../components/news/NewsPicturePreview';

const NewListItemPicturePreview = ({url}) => {
  return url ? (
    <NewsPicturePreview url={url} />
  ) : (
    null
  )
};

export default NewListItemPicturePreview;