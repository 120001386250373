import { limit, orderBy, query, where } from "firebase/firestore";
import { getChatsCollectionRef } from "../../../firebase/chat/getChatsCollectionRef";

const getChatsQueryForDialoguesList = (authorizedUser, searchFilter, messengerPages) => {
  if(authorizedUser.role === 'assistant') {
    return null;
  }
  const constraints = []

  if (authorizedUser.role === 'operator') {
    constraints.push(where('active', '==', true), where('assignedTo', '==', authorizedUser.id))
  }

   if (searchFilter) {
    constraints.push(where('UID', '>=', searchFilter));
    constraints.push(where('UID', '<=', searchFilter + '\uf8ff'));
    constraints.push(orderBy('UID'))
    // constraints[authorizedUser.role].push(where('phoneNumber', '>=', searchFilter))
    // constraints[authorizedUser.role].push(where('phoneNumber', '<=', searchFilter + '\uf8ff'))
  }
  constraints.push(orderBy('needHelp', 'desc'));
  constraints.push(orderBy('lastEdited', 'desc'));

  if(messengerPages && messengerPages > 0) {
    constraints.push(limit(100 * messengerPages));
  }

  return  query(getChatsCollectionRef(), ...constraints);
}

export default getChatsQueryForDialoguesList;
