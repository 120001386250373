import getAvailableEmployeesRoles from "../accesses/getAvailableEmployeesRoles";
import EmployeeItem from "../components/EmployeeItem";
import { GLOBAL_ROLES_RU } from "../constants/constants";

const getAssignedToFilterData = (action, employeesData, role) => {
  if(!employeesData) return [];
  const roles = getAvailableEmployeesRoles(action, role);
  return roles.map(employeeRole => {
    return {
      title: GLOBAL_ROLES_RU[employeeRole.role],
      value: GLOBAL_ROLES_RU[employeeRole.role],
      disabled: employeeRole.disabled,
      children: employeesData.reduce((acc, employee) => {
        if(employee.role === employeeRole.role) {
          acc.push({
            value: employee.id,
            title: <EmployeeItem name={employee.name} role={employee.role}/> ,
            disabled: employeeRole.disabled,
          })
          return acc;
        }
        return acc;
      }, [])
    }
  })
}

export default getAssignedToFilterData;