import { Button, Divider, Select, TreeSelect } from 'antd';
import React from 'react';
import getSelectedColumns from './getSelectedColumns';
import '../../assets/components/column-selector/column-selector.scss';

const ColumnSelector = ({columnsToDisplay, hideOrShowColumn, resetColumns, groups}) => {

  const deselectColumnHandler = (value) => {
    hideOrShowColumn(value)
  }

  const selectColumnHandler = (value, _option) => {
    hideOrShowColumn(value)
  }

  const customizeDropdown = (defaultMenu) => {
    return (
      <>
        {defaultMenu}
        <Divider className='column-selector__divider'/>
        <div className='column-selector__dropdown-footer'>
          <Button danger size='small' onClick={resetColumns}>
            Reset
          </Button>
        </div>
      </>
    )
  }
  /**
   * Выставляя maxTagCount на 0 делаем все опции пропущенными для отображения в плейсхолдере дропдауна. Функция кастомизирует плейсхолдер для пропущенных опций.
   * @param {*} omittedValues Все опции минус показанные опции (проп maxTagCount).
   */
  const customizePlaceholder = (omittedValues) => {
    return (
      <p className='column-selector__placeholder'>
        {omittedValues.length}&nbsp;Колонок...
      </p>
    )
  }

  const selectedColumns = getSelectedColumns(columnsToDisplay, groups);

  return (
    <div className='column-selector__container'>
      {groups === 'hasGroups' ? (
      <TreeSelect
        style={{
          width: '200px',
        }}
        multiple
        placeholder="Колонки"
        treeDefaultExpandAll
        allowClear
        treeCheckable
        maxTagCount={0} // пропускаем опции в плейсхолдере не отображая ни одну из них.
        maxTagPlaceholder={customizePlaceholder} // создаем кастомный плейхолдер на основе количества пропущенных указанных опций.
        dropdownRender={customizeDropdown}
        onSelect={hideOrShowColumn}
        onDeselect={hideOrShowColumn}
        treeData={columnsToDisplay}
        value={selectedColumns}
      />
       ) : (
      <Select
        mode="multiple"
        showSearch={false}
        className='column-selector__selector'
        placeholder="Колонки"
        value={selectedColumns}
        options={columnsToDisplay}
        maxTagCount={0} // пропускаем опции в плейсхолдере не отображая ни одну из них.
        maxTagPlaceholder={customizePlaceholder} // создаем кастомный плейхолдер на основе количества пропущенных указанных опций.
        dropdownRender={customizeDropdown}
        onSelect={selectColumnHandler} // срабатывает только на select
        onDeselect={deselectColumnHandler} // срабатывает только на deselect
      />
        )}

    </div>
  );
};

export default ColumnSelector;