import docSectionsConfig from "../config/docSections.config"
import createSectionObj from "../helpers/docs-by-sections/createSectionObj"

const createInitialDocumentsState = (passports, uploadedDocs, countryCode, servicePrice) => {
  return passports.map((passport, passportIndex) => {
    const docSections = docSectionsConfig.reduce((acc, sectionConfig, sectionIndex) => {
      if(sectionConfig.countriesToDisplay) { // секция отображается только для определенных стран
        if(sectionConfig.countriesToDisplay.includes(countryCode)) { // страна заявки соответствует списку стран для отображения этой секции
          const section = createSectionObj(sectionConfig, passportIndex, sectionIndex, uploadedDocs)
          acc.push(section)
          return acc;
        } else {
          return acc;
        }
      }
      if(sectionConfig.priceToDisplay) {
        if(parseInt(servicePrice) > sectionConfig.priceToDisplay) {
          const section = createSectionObj(sectionConfig, passportIndex, sectionIndex, uploadedDocs);
          acc.push(section)
          return acc;
        } else {
          return acc;
        }
      }
      const section = createSectionObj(sectionConfig, passportIndex, sectionIndex, uploadedDocs)
      acc.push(section)
      return acc;
    }, [])

    return {
      applicantId: passportIndex,
      applicantName: `${passport.first_name} ${passport.last_name}`,
      docSections: docSections,
    }
  })
}

export default createInitialDocumentsState;