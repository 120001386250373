import getNotificationConfig from "./getNotificationConfig";
import playNotificationSound from "./playNotificationSound";

const showUnreadMessageNotification = (notificationAPI, options) => {
  const { title: message, description } = options;
  const config = getNotificationConfig('unreadMessage');
  playNotificationSound('unreadMessage')
  notificationAPI.info({...config, message, description});
  // notificationAPI.info({message, description});
}

export default showUnreadMessageNotification;