import { columns } from "../configs/operators-table-config"

const getAccountsTableColumns = (role) => {
  return columns.reduce((acc, column) => {
    if(column.access === 'all' || column.access.includes(role)) {
      acc.push(column);
      return acc
    }
    return acc;
  }, [])
}

export default getAccountsTableColumns;