import { Spin } from 'antd';
import React from 'react';
import MyError from '../../Error/components/MyError';
import PushHistoryItem from './PushHistoryItem';

const PushHistoryList = ({pushHistory, pushHistoryLoading, pushHistoryError}) => {
  if(pushHistoryLoading) {
    return <Spin></Spin>
  }
  if(pushHistoryError) {
    return <MyError error={pushHistoryError}></MyError>
  }

  return pushHistory.map((push, index) => {
    return <PushHistoryItem key={push.id} push={push} num={pushHistory.length - (index)}/>
  })
};

export default PushHistoryList;