import { doc, setDoc } from "firebase/firestore";
import getPushesRef from "./getPushesRef";

const sendPush = async (push) => {
  try {
    await setDoc(doc(getPushesRef(), push.id), push);
  } catch (error) {
    throw error;
  }
}

export default sendPush;