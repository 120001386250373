const getSelectedColumns = (cols, groups) => {
  return cols.reduce((acc, col) => {
    if(groups === 'hasGroups') {
      if(col.children){
        col.children.forEach(nestedCol => {
          if(nestedCol.isSelected) {
            acc.push(nestedCol.value);
            return acc;
          }
        })
      }
    }
    if(col.isSelected) {
      acc.push(col.value);
      return acc;
    }

    return acc;
  }, [])
}

export default getSelectedColumns;