import { Select } from 'antd';
import React, { useContext } from 'react';
import getSectionStatusOptions from '../../helpers/getSectionStatusOptions';
import { DocSectionContext, DocsContentContext, UserDataContext } from '../../../../contexts/context';
import createServiceCommentData from '../../models/createServiceCommentData';
import createChangeSectionStatusOperationData from '../../models/createChangeSectionStatusOperationData';
import createNewStatusData from '../../models/createNewStatusData';

const SectionStatus = () => {
  const { role, authorizedUser } = useContext(UserDataContext);
  const { section, applicantId, docSectionData } = useContext(DocSectionContext);
  const { changeSectionStatus } = useContext(DocsContentContext);

  const changeSectionStatusHandler = async (value, option) => {
    if(value === docSectionData.step) return false;
    try {
      const changeSectionStatusOperation = createChangeSectionStatusOperationData(option, docSectionData)
      const serviceCommentData = createServiceCommentData(authorizedUser, changeSectionStatusOperation);
      const newStatusData = createNewStatusData(value)
      await changeSectionStatus(newStatusData, applicantId, section.sectionId, serviceCommentData);
    } catch (error) {
      console.log(error);
    }
  }

  const options = getSectionStatusOptions(role, docSectionData?.step)
  return (
    <div>
      <Select
        value={docSectionData?.step}
        style={{width: '150px'}}
        options={options}
        disabled={!docSectionData?.assistantId} // заблокировать, если ассистент не назначен
        onSelect={changeSectionStatusHandler}
      />
    </div>
  );
};

export default SectionStatus;