import GLOBAL_ROLES from "../constants/constants";

const { admin, careSupport, superOperator, operator, assistant } = GLOBAL_ROLES;

const rolesSettings = {
  issueAssign:[
    {role: admin, disabled: false},
    {role: careSupport, disabled: false},
    {role: superOperator, disabled: false},
  ],
  assignedEmployeesFilter: [
    {role: admin, disabled: false},
    {role: careSupport, disabled: false},
    {role: superOperator, disabled: false},
    {role: operator, disabled: false},
  ],
  operatorAssign: {
    [admin]: [
      {role: admin, disabled: false},
      {role: careSupport, disabled: false},
      {role: superOperator, disabled: false},
      {role: operator, disabled: false},
    ],
    [careSupport]: [
      {role: admin, disabled: true},
      {role: careSupport, disabled: false},
      {role: superOperator, disabled: false},
      {role: operator, disabled: false},
    ],
    [superOperator]: [
      {role: admin, disabled: true},
      {role: careSupport, disabled: true},
      {role: superOperator, disabled: false},
      {role: operator, disabled: false},
    ]
  },
  assistantAssign: [
    {role: assistant, disabled: false}
  ]

}

const getAvailableEmployeesRoles = (action, role='') => {
  if(action === 'issueAssign' || action === 'assignedEmployeesFilter') {
    return rolesSettings[action];
  }
  if(action === 'operatorAssign') {
    return rolesSettings[action][role];
  }
  if(action === 'assistantAssign') {
    return rolesSettings[action];
  }
}

export default getAvailableEmployeesRoles;
