export const testStatuses = {
  0:{
    // TODO: поменять имена переменных
    dbProp:0,
    buttonFilterValue:"Новые", // текст статуса на кнопках сортировки и в тэгах таблицы.
    tagText:"Новые", // текст статуса на кнопках сортировки и в тэгах таблицы.
    tagColor: "processing", // цвета тега статуса
    progressBarColor:"#4DA1FF",
    selectLabel:'Новая',
    progressPercent:15,
    fullStatusName:'Новые',
  },
  1:{
    dbProp:1,
    buttonFilterValue:"В процессе",
    tagText:"В процессе",
    tagColor:"yellow",
    progressBarColor:"#F2F617",
    selectLabel:'В процессе',
    progressPercent:30,
    fullStatusName:'В процессе',
  },
  2:{
    dbProp:2,
    buttonFilterValue:"Подготавливаем",
    tagText:"Подготавливаем",
    tagColor:"gold",
    progressBarColor:"#b4b720",
    selectLabel:'Подготавливаем',
    progressPercent:50,
    fullStatusName:'Готовим документы в посольство',
  },
  3:{
    dbProp:3,
    buttonFilterValue:"Подаем",
    tagText:"Подаем",
    tagColor:"orange",
    progressBarColor:"#f3ab23",
    selectLabel: "Подаем",
    progressPercent:70,
    fullStatusName:'Документы готовы для подачи',
  },
  4: {
    dbProp:4,
    buttonFilterValue:"Сданы",
    tagText:"Сданы",
    tagColor:"lime",
    progressBarColor:"#60fa82",
    selectLabel: "Сданы",
    progressPercent:90,
    fullStatusName:'Сдали документы в посольство',
  },
  5.1: {
    dbProp:5.1,
    buttonFilterValue:"Одобрено",
    tagText:"Одобрено",
    tagColor:"success",
    progressBarColor:"#009521",
    selectLabel: "Одобрено  (Завершение)",
    progressPercent:100,
    fullStatusName:'Одобрено',
  },
  5.2: {
    dbProp:5.2,
    buttonFilterValue:"Отказано",
    tagText:"Отказано",
    tagColor:"error",
    progressBarColor:"#fe0000",
    selectLabel: "Отказано  (Чат будет завершен)",
    progressPercent:100,
    fullStatusName:'Отказано',
  },
  5.3: {
    dbProp:5.3,
    buttonFilterValue:"Приостановлена",
    tagText:"Приостановлена",
    tagColor:"default",
    progressBarColor:"grey",
    selectLabel: "Приостановлена  (Чат будет завершен)",
    progressPercent:10,
    fullStatusName:'Приостановлена',
  }
}