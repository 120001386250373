import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import getClientsTableColumns from '../helpers/getClientsTableColumns';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import Spinner from '../../../components/Spinner';
import MyError from '../../Error/components/MyError';
import getClientsTableQuery from '../firebase/getClientsTableQuery';
import getClientsTableData from '../helpers/getClientsTableData';
import '../../../assets/clients/clients.scss'
import { getCountFromServer } from 'firebase/firestore';
import { getAllFieldsFromDocSnapshot } from '../../../helpers/getAllFieldsFromDocSnapshot';
import Paginator from '../../../ui/Paginator';

const Clients = () => {
  const [ clientsListPage, setClientsListPage ] = useState(1);
  const [ clientsData, clientsLoading, clientsError, clientsCollSnap, reload ] = useCollectionDataOnce(getClientsTableQuery(clientsListPage));
  const [ clientsTableData, setClientsTableData ] = useState([])
  const [ totalAppsCount, setTotalAppsCount ] = useState(0);

  useEffect(() => {
    if(clientsData) {
      setClientsTableData(getClientsTableData(clientsData))
    }
  },[clientsData])

  useEffect(() => {
    // получаение общего количества возможных заявок
    const getAllAppsCount = async () => {
      const queryForAppsWithoutLimit = getClientsTableQuery();
      const aggregateSnapshot = await getCountFromServer(queryForAppsWithoutLimit);
      setTotalAppsCount(getAllFieldsFromDocSnapshot(aggregateSnapshot).count);
    }
    getAllAppsCount();
  }, [clientsData])

  if(!totalAppsCount) {
    return <Spinner />
  }

  if(clientsError) {
    return <MyError error={clientsError} />
  }

  const downloadMoreClients = () => {
    setClientsListPage(prev => ++prev);
  }

  return (
    <div
      className='clients'
    >
      <div className='clients__container'>
        <div className="clients__paginator-container">
          <Paginator currentCount={clientsTableData.length} totalCount={totalAppsCount}/>
        </div>
        <Table
          columns={getClientsTableColumns()}
          dataSource={clientsTableData}
          pagination={false}
        />
        <div className="clients__paginator-container">
          <Paginator currentCount={clientsTableData.length} totalCount={totalAppsCount}/>
        </div>
        <div style={{display:'flex', justifyContent:'center', marginTop: '1%'}}>
          <Button
            loading={clientsLoading}
            onClick={downloadMoreClients}
            disabled={totalAppsCount === clientsTableData.length}
          >
            Загрузить еще
          </Button>
        </div>
      </div>
    </div>
  )
};

export default Clients;