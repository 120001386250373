import { getDoc } from "firebase/firestore";

const getChatData = async (newMessageCollSnapshot) => {
  if(newMessageCollSnapshot.empty) {
    throw new Error('Новые сообщения не найдены')
  }
  const chatDocRef = newMessageCollSnapshot.docs[0].ref.parent.parent;
  const chatDoc = await getDoc(chatDocRef);
  return chatDoc.data();
}

export default getChatData;