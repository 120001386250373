import sortTextElements from "../helpers/sortTextElements";

const citizenships = [
  {
    "name_ru": "Казахстан",
    "country_code": "kz",
    "flag": "mini_flags/kz-small.png.svg",
    "flag_local": "assets/mini_flags/Казахстан.svg",
    "preview": "assets/previews/kz.png",
    "view": "assets/views/kz.png"
  },
  {
    "name_ru": "Кыргызстан",
    "country_code": "kg",
    "flag": "mini_flags/kg-small.png",
    "flag_local": "assets/mini_flags/Кыргызстан.svg",
    "preview": "assets/previews/kg.png",
    "view": "assets/views/kg.jpg"
  },
  {
    "name_ru": "Узбекистан",
    "country_code": "uz",
    "flag": "mini_flags/uz-small.png",
    "flag_local": "assets/mini_flags/Узбекистан.svg",
    "preview": "assets/previews/uz.jpg",
    "view": "assets/views/uz.jpg"
  },
  {
    "name_ru": "Украина",
    "country_code": "ua",
    "flag": "mini_flags/ua-small.svg",
    "flag_local": "assets/mini_flags/Украина.svg",
    "preview": "assets/previews/uk.png",
    "view": "assets/views/uk.jpg"
  },
  {
    "name_ru": "Азербайджан",
    "country_code": "az",
    "flag": "mini_flags/az-small.png",
    "flag_local": "assets/mini_flags/Азербайджан.svg",
    "preview": "assets/previews/az.jpg",
    "view": "assets/views/az.jpg"
  },
  {
    "name_ru": "Молдова",
    "country_code": "md",
    "flag": "mini_flags/md-small.png",
    "flag_local": "assets/mini_flags/Молдова.svg",
    "preview": "assets/previews/md.png",
    "view": "assets/views/md.jpg"
  },
  {
    "name_ru": "Таджикистан",
    "country_code": "tj",
    "flag": "mini_flags/tj-small.svg",
    "flag_local": "assets/mini_flags/Таджикистан.svg",
    "preview": "assets/previews/tj.png",
    "view": "assets/views/tj.jpg"
  },
  {
    "name_ru": "Армения",
    "country_code": "am",
    "flag": "mini_flags/am-small.png",
    "flag_local": "assets/mini_flags/Армения.svg",
    "preview": "assets/previews/am.png",
    "view": "assets/views/am.jpg"
  },
  {
    "name_ru": "Туркменистан",
    "country_code": "tm",
    "flag": "mini_flags/tm-small.svg",
    "flag_local": "assets/mini_flags/Туркменистан.svg",
    "preview": "assets/previews/tm.jpg",
    "view": "assets/views/tm.jpg"
  },
  {
    "name_ru": "Россия",
    "country_code": "ru",
    "flag": "mini_flags/ru-small.svg",
    "flag_local": "assets/mini_flags/Россия.svg",
    "preview": "assets/previews/ru.png",
    "view": "assets/views/ru.jpg"
  },
  {
    "name_ru": "Турция",
    "country_code": "tr",
    "flag": "mini_flags/tr-small.png",
    "flag_local": "assets/mini_flags/Турция.svg",
    "preview": "assets/previews/tr.jpg.webp",
    "view": "assets/views/tr.jpg"
  },
  {
    "name_ru": "Китай",
    "country_code": "cn",
    "flag": "mini_flags/cn-small.png",
    "flag_local": "assets/mini_flags/Китай (Китайская Народная Республика).svg",
    "preview": "assets/previews/cn.jpeg",
    "view": "assets/views/cn.jpg"
  },
  {
    "name_ru": "Индия",
    "country_code": "in",
    "flag": "mini_flags/in-small.png",
    "flag_local": "assets/mini_flags/Индия.svg",
    "preview": "assets/previews/in.jpeg",
    "view": "assets/views/in.jpeg"
  },
]

const getCitizenshipFilters = () => {
  const filters = citizenships.map(citizenship => {
    return {
      text: citizenship.name_ru,
      value: citizenship.country_code,
    }
  })
  return sortTextElements(filters);
}

export default getCitizenshipFilters;