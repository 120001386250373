import QuestionnaireItem from "../components/QuestionnaireItem";
import categoryForQuestion from "./categoryForQuestionIfItIsPersonal";

const getQuestionnaireItem = (question, questionIndex, personIndex) => {
  // создает элемент анкеты (вопрос-ответ)
  return (
    <QuestionnaireItem
      key={questionIndex}
      question={question}
      questionIndex={questionIndex}
      personIndex={personIndex}
    />
  )
}

// собирает категории с данными для общей анкеты и индивидуальныхз анкет
const combinePreparedData = (questionnaire, applicantsNumber) => {
  if(!questionnaire || questionnaire.length === 0) { // если анкеты в БД нет или она пустая.
    return null;
  }
  // категории для сбора секций с вопросами и ответами. 
  // 1) общая категория
  // 2) категория, содержащая массив с объектами собранных секций с вопросами и ответами для каждого заявителя.
  const questionnairePreparedData = {
    common:{},
    personal: Array.from(Array(applicantsNumber), () => {
      return {};
    }),
  };

  questionnaire.forEach((question, questionIndex) => {
    const category = categoryForQuestion[question.isMultiple];
    if(!question.isMultiple) { // вопрос общий для всех
      if (!questionnairePreparedData[category][question.section]) { // проверка общей категории на наличие секции
        questionnairePreparedData[category][question.section] = []; // если секции нет - создать, записать в нее пустой массив.
      }
      const questionnaireItem = getQuestionnaireItem(question, questionIndex, null);
      questionnairePreparedData[category][question.section].push(questionnaireItem); // пушим в секцию общей категории айтем (вопрос-ответ);
    } else { // вопрос должен быть индивидуальным для каждого заявителя
      if(question.type === 'list') { // если list - response у него не массив а объект с ключами равными индексам заявителей (0,1,2 и тд.)
        const keys = Object.keys(question.response);
        keys.forEach(key => {
          if (!questionnairePreparedData[category][key][question.section]) { // перебор массива ответов всех заявителей.
            questionnairePreparedData[category][key][question.section] = []; // создаем секцию для каждого заявителя в категории personal
          }
          const questionnaireItem = getQuestionnaireItem(question, questionIndex, key);
          questionnairePreparedData[category][key][question.section].push(questionnaireItem); // пушим в секцию вопрос-ответ для каждого заявителя отдельно.
        })
        return;
      }
      question.response.forEach((_personalResponse, personIndex) => {
        if (!questionnairePreparedData[category][personIndex][question.section]) { // перебор массива ответов всех заявителей.
          questionnairePreparedData[category][personIndex][question.section] = []; // создаем секцию для каждого заявителя в категории personal
        }
        const questionnaireItem = getQuestionnaireItem(question, questionIndex, personIndex);
        questionnairePreparedData[category][personIndex][question.section].push(questionnaireItem); // пушим в секцию вопрос-ответ для каждого заявителя отдельно.
      })
    }
  })

  return questionnairePreparedData;
}

export default combinePreparedData;
