import React from 'react';
import DetailsItem from './DetailsInfoItem';
import '../../../assets/application-additionals/details-info.scss';

const detailPaths = {
  'срок рассмотрения': {
    path: 'reviewDuration',
    units: 'дней',
  },
}

const DetailsInfo = ({ curApplication }) => {
  const list = Object.entries(detailPaths).map(entry => {
    return <DetailsItem key={entry[1]} detail={entry[0]} value={entry[1]} curApplication={curApplication}/>
  })

  return (
    <div className='details-info'>
      <ul className='details-info__list'>
        {list}
      </ul>
    </div>
  );
};

export default DetailsInfo;
