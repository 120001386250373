import React from 'react';

const dialogueEmptyStyle = {textAlign:'center', color:'#b8b8b8', fontStyle:'italic'};

const DialoguesEmpty = () => {
  return (
    <div key='dialoguesAreAbsent' style={dialogueEmptyStyle}>
      Чатов нет
    </div>
  );
};

export default DialoguesEmpty;