import { collection, limit, query } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import issuePaths from "../../../firebase/issues/issuesPaths";

const getIssuesQuery = (issuesCount) => {
  const constraints = [];
  if(issuesCount) {
    constraints.push(limit(issuesCount));
  }
  return query(collection(firestore,issuePaths.issues), ...constraints);
}

export default getIssuesQuery;