import dayjs from "dayjs";
import { getCountry } from "../../../helpers/countries/getCountry";

const getAssignedSectionTableData = (assignedSections, countriesData) => {
  return assignedSections.map(((assignedSection, index) => {
    const activityTime  = dayjs(assignedSection.lastActivity.toMillis()).format('DD/MM/YYYY HH:mm:ss')
    return {
      // key: `${index}-${assignedSection.applicationInfo.applicationId}-${assignedSection.sectionInfo.sectionId}`,
      key: `${assignedSection.applicantId}-${assignedSection.applicationInfo.applicationId}-${assignedSection.sectionInfo.sectionId}`,
      assistantId: assignedSection.assistantId,
      assistantName:assignedSection.assistantName,
      lastActivity: activityTime,
      isRead: assignedSection.isRead,
      UID: assignedSection.applicationInfo.UID,
      clientName: assignedSection.applicantName,
      applicationIdFull: assignedSection.applicationInfo.applicationId,
      applicationIdShort: assignedSection.applicationInfo.applicationId.slice(0,4),
      countryNameRu: getCountry(countriesData, assignedSection.applicationInfo.countryCode).name_ru,
      citizenShip: assignedSection.applicationInfo.citizenship.toUpperCase(),
      applicantsNum: assignedSection.applicationInfo.applicantsNum,
      sectionStatus: assignedSection.step,
      docSectionName: assignedSection.sectionInfo.sectionName,
    }
  }))
}

export default getAssignedSectionTableData;