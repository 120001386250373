const getCountriesFilters = (countries) => {
  if(!countries) return [];
  const countriesList = countries.map(country => {
    return {
      text: country.name_ru,
      value: country.country_code,
    }
  })
  return countriesList.sort((a, b) => {
    const first = a.text.toLowerCase();
    const second = b.text.toLowerCase();
    if(first > second) return 1;
    if(first < second) return -1;
    return 0;
  })
}

export default getCountriesFilters;