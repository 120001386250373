import React from 'react';
import '../../../assets/notification/service-message-notification-description.scss'

const ServiceMessageNotificationDescription = ({processName, description, data}) => {
  return (
    <div className='service-nitification-description'>
      <p className='service-nitification-description__message'>
        {description}
      </p>
      <p className='service-nitification-description__additional'>
        {data}
      </p>
    </div>

  );
};

export default ServiceMessageNotificationDescription;