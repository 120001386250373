import { Collapse } from 'antd';
import React from 'react';
import { getCollapseItems } from '../helpers/getCollapseItems';

const PersonalQuestionnaires = ({personalQuestionnaireData, passports}) => {
  if (!personalQuestionnaireData) {
    return null;
  }

  const allPersonalQuestionnaires = passports.map((passport, index) => {
    const collapseItems = getCollapseItems(personalQuestionnaireData[index])
    return (
      <div key={`applicant-${index}`} className='personalQuestionnaire'>
        <h3 className="personalQuestionnaire__applicant-name">
          {`${passport.first_name} ${passport.last_name}`}
        </h3>
        <Collapse
          bordered={false}
          items={collapseItems}
          size={"middle"}
        />
      </div>
    )
  })

  return allPersonalQuestionnaires;
};

export default PersonalQuestionnaires;
