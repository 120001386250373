import { doc } from 'firebase/firestore';
import React, { useContext, useMemo, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { firestore } from './firebase/firebase';
import { useEffect } from 'react';
import { VersionCheckContext } from './contexts/context';
import APP_VERSION from './environments/app-version';
import { Spin } from 'antd';

const VersionCheck = ({children}) => {
  const [ versionData, versionLoading, versionError ] = useDocumentData(doc(firestore, 'versions/crm'));
  const [ needReload, setNeedReload ] = useState(false);

  const context = useMemo(() => {
    return {
      needReload
    }
  }, [needReload])

  useEffect(() => {
    if (!versionLoading && !versionError) {
      // последняя версия указана в БД.
      const actualCRMVersion = versionData.version;
      if(APP_VERSION !== actualCRMVersion) {
        setNeedReload(true)
      }
    }
  }, [versionData?.version, versionError, versionLoading])

  if(versionLoading) {
    return (
      <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center" }}>
        <Spin size="large"/>
      </div>
    )
  }

  if(versionError) {
    console.log(versionError)
  }

  return (
    <VersionCheckContext.Provider value={context} >
      {children}
    </VersionCheckContext.Provider>
  );

};

export default VersionCheck;