import { Tag } from "antd"
import { Link } from "react-router-dom"
import { sectionStatuses } from "../../Documents/models/sectionStatuses"
import EmployeeItem from '../../../components/EmployeeItem';
import AssignedToCustomizedFilter from "../../../components/AssignedToTableFilter/AssignedToCustomizedFilter";

const applicationLinkRender = (text, record, _index) => {
  return <Link
    to={`/application/${record.UID}/${record.applicationIdFull}`}
    style={{ color:"#0EA5E9", fontWeight:"800" }}
  >
    {text}
  </Link>
}

const sectionStatusTagRender = (text, record, _index) => {
  return (
    <Tag
      bordered="false"
      color={sectionStatuses[text].tagColor}
    >
      {sectionStatuses[text].titleRu}
    </Tag>
  )
}

const assistantRender = (text, record, _index) => {
  return <EmployeeItem name={record.assistantName} role='assistant'/>
}

const assignedSectionsColumns = [
  // {
  //   title: 'ID ассистента',
  //   dataIndex: 'assistantId',
  //   key: 'assistantId',
  //   align: "center",
  //   ellipsis: true,
  // },
  {
    title: 'Заявка',
    dataIndex: 'applicationIdShort',
    key: 'applicationIdShort',
    align: "center",
    ellipsis: true,
    render: applicationLinkRender,
  },
  {
    title: 'Клиент',
    dataIndex: 'clientName',
    key: 'clientName',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Имя секции',
    dataIndex: 'docSectionName',
    key: 'docSectionName',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Страна',
    dataIndex: 'countryNameRu',
    key: 'countryNameRu',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Гражданство',
    dataIndex: 'citizenShip',
    key: 'citizenShip',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Кол-во заявителей',
    dataIndex: 'applicantsNum',
    key: 'applicantsNum',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Активность',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
    align: "center",
    ellipsis: true,
  },
  {
    title: 'Статус',
    dataIndex: 'sectionStatus',
    key: 'sectionStatus',
    align: "center",
    ellipsis: true,
    render: sectionStatusTagRender,
  },
  {
    title: 'Ассистент',
    dataIndex: 'assistantId',
    key: 'assistantId',
    align: "center",
    ellipsis: true,
    render: assistantRender,
    filterMode: 'tree',
    filterDropdown: (props) => <AssignedToCustomizedFilter filterProps={props}/>,
  },


]

export {assignedSectionsColumns}