import { addDoc, collection } from "firebase/firestore";

const addToLog = async (entryData, appRef, subCollection) => {
  try {
    await addDoc(collection(appRef, subCollection), entryData);
  } catch (error) {
    console.log(error);
  }
}

export default addToLog;