import React, { useContext, useEffect, useState } from 'react';
import ReassignAndDeactivateForm from './ReassignAndDeactivateForm';
import { getDeletingOperatorAppsQuery } from '../firebase/getDeletingOperatorAppsQuery';
import { getDeletingOperatorChatQuery } from '../firebase/getDeletingOperatorChatQuery';
import { OperatorsContext, ProgramContext } from '../../../contexts/context';
import Spinner from '../../../components/Spinner';
import { deactivateOperator } from '../firebase/deactivateOperator';
import { reassign } from '../firebase/reassign';
import OperatorDeactivatingConfirm from './OperatorDeactivatingConfirm';
import showNotification from '../../NotificationService/helpers/showNotification';
import useCount from '../../../hooks/useCount';

const DeactivateOperator = () => {
  const [ selectedOperator, setSelectedOperator ] = useState(null);
  const { notificationAPI } = useContext(ProgramContext)
  const { deactivatedOperatorId, setDeactivatedOperatorId, setActionType, popupIsOpened, setPopupIsOpened, reloadEmployees, deactivatedOperatorName } = useContext(OperatorsContext);
  const [ appsCountOfDeactivatedOperator, appsCountLoading, appsCountError ] = useCount(getDeletingOperatorAppsQuery(deactivatedOperatorId))
  const [ chatsCountOfDeactivatedOperator, chatsCountLoading, chatsCountError ] = useCount(getDeletingOperatorChatQuery(deactivatedOperatorId))

  useEffect(()=> {
    // очистить все стейты при закрытии попапа
    if(!popupIsOpened) {
      setActionType(null)
      setDeactivatedOperatorId(null)
      setSelectedOperator(null)
    }
  },[popupIsOpened])

  if(appsCountLoading || chatsCountLoading) {
    return <Spinner />
  }

  if(appsCountError || chatsCountError) {
    console.log(appsCountError || chatsCountError)
    return;
  }

  const isAppsWithChatWereNotAssigned = !appsCountOfDeactivatedOperator && !chatsCountOfDeactivatedOperator;

  const deactivateWithoutReassign = async () => {
    setPopupIsOpened(false)
    try {
      await deactivateOperator(deactivatedOperatorId);
      reloadEmployees()
    } catch (error) {
      console.log(error)
      if(error.name === 'DeactivateError') {
        showNotification(notificationAPI, 'process', {processName: 'opeartorDeactivate', status: 'error'})
        return
      } else {
        showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'error',})
      }
    }
  }

  const reassignAndDeactivate = async () => {
    try {
      await reassign(selectedOperator, deactivatedOperatorId);
      await deactivateOperator(deactivatedOperatorId);
      showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'success',})
      showNotification(notificationAPI, 'process', {processName: 'opeartorDeactivate', status: 'success'})
      setPopupIsOpened(false);
      reloadEmployees();
    } catch (error) {
      console.log(error)
      if(error.name === 'DeactivateError') {
        showNotification(notificationAPI, 'process', {processName: 'opeartorDeactivate', status: 'error'})
        return
      }
      if(error.message === 'Current employee already busy with this client') {
        showNotification(notificationAPI, 'process', {processName: 'employeeIsBusy', status:'error'})
        return
      } else {
        showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'error',})
      }
    }
  }

  return isAppsWithChatWereNotAssigned ? (
    <OperatorDeactivatingConfirm deactivateWithoutReassign={deactivateWithoutReassign} setPopupIsOpened={setPopupIsOpened} deactivatedOperatorName={deactivatedOperatorName}/>
  ) : (
    <ReassignAndDeactivateForm setSelectedOperator={setSelectedOperator} selectedOperator={selectedOperator} reassignAndDeactivate={reassignAndDeactivate} />
  );
};

export default DeactivateOperator;
