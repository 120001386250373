import { Badge } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';


const itemContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems:'center',
}

const SiderMenuNewsField = ({unreadNewsCount}) => {

  return (
    <div>
      <div style={itemContainerStyle}>
        <Link
          to="news"
        >
          Новости
        </Link>
        <div>
          <Badge
            count={unreadNewsCount}
            styles={{
              root:{color: "white", marginLeft:"2px"},
              indicator: {boxShadow:'none', backgroundColor:"#56DA27"}
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SiderMenuNewsField;