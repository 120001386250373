import { updateDoc } from "firebase/firestore"

const updateDocField = async (ref, data) => {
  try {
    await updateDoc(ref, data)
  } catch (e) {
    console.log(e)
    throw e
  }
}
export default updateDocField;
