import getRoleRu from "../../../utils/getRoleRu";

const getOperatorsTableData = (allOperators) => {
  return allOperators.map(operator => {
    return {
      roleRu: getRoleRu(operator.role),
      roleEn: operator.role,
      name: operator.name,
      id:operator.id, // для функции включения/выключения аккаунта.
      shortId: operator.id.slice(0,4),
      phoneNumber: operator.phoneNumber,
      applications: '', // пока нет реализации
      online: '', // пока нет реализации
      unreadMessages: '', // пока нет реализации
      active:operator.active,
    }
  })
}

export default getOperatorsTableData;