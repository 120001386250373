import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import '../../../../assets/passports/type-photo-field.scss';

const PdfImage = ({url, imgContentType}) => {
  // const [ modalOpen, setModalOpen ] = useState(false);

  // const showFullSize = () => {
  //   setModalOpen(true)
  // }

  // const closeModal = () => {
  //   setModalOpen(false);
  // }

  return (
    <>
    <div className='preview' >
      <object
        className='preview__object'
        data={url}
        type={imgContentType}
      >
      </object>
      <div
        className='preview__cover'
      >
        <a
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          <SearchOutlined
            className='preview__cover-increaser-img'
            // onClick={showFullSize}
          />
        </a>
      </div>
    </div>
    {/* <Modal
      open={modalOpen}
      onCancel={closeModal}
      width='' // сброс базовой ширины (512px)
      centered
      modalRender={() => (
        <div
          // style={{width:'fit-content', height:'100%'}}

        >
          <object
            data={url}
            type={imgContentType}
            onClick={showFullSize}
            // className=''
            style={{width:'1800px', height:"1000px"}}
          >
          </object>
        </div>
      )}
    >
    </Modal> */}
  </>
  );
};

export default PdfImage;