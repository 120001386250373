import { Button, Input } from 'antd';
import React, { useContext } from 'react';
import { QuestionnaireContext } from '../../../contexts/context';

const ListItem = ({item, index, changeList}) => {
  const { isEdit } = useContext(QuestionnaireContext);

  return (
    <li >
      {isEdit ? (
        <Input
          size='small'
          value={item}
          onChange={(e) => changeList(index, e.target.value, 'change')}
          suffix={
            <Button 
              type='link'
              onClick={() => changeList(index, null, 'delete')}
            >
              Удалить
            </Button>
          }
        />
      ) : (
        item
      )}
    </li>
  );
};

export default ListItem;
