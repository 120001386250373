import React from 'react';
import Spinner from '../../../components/Spinner';
import MyError from '../../Error/components/MyError';
import NewsListItemContainer from './NewsListItemContainer';

const NewsList = ({newsData, newsLoading, newsError,  reload}) => {
  if(newsLoading) {
    return <Spinner />
  }

  if(newsError) {
    return <MyError error={newsError} />
  }

  const newsList = newsData.map(news => {
    return <NewsListItemContainer key={news.id} news={news} reloadNews={reload}/>
  })

  return newsList;
};

export default NewsList;