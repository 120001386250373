import { Timestamp, doc, getDocs, runTransaction } from "firebase/firestore";
import getApplicationsToAssign from "./getApplicationsToAssign";
import { getChatQueryForApplication } from "../../../firebase/chat/getChatQueryForApplication";
import { firestore } from "../../../firebase/firebase";
import getAppsRefAndReadStatus from "./getAppsRefAndReadStatus";
import getChangeAssigneeLogData from "../models/getChangeAssigneeLogData";
import getClientQueryByUID from "../../../firebase/clients/getClientQueryByUID";
import getChatRef from "../../../firebase/chat/getChatRef";

const assignOperator = async (clientId, employeeId, employeeName, authorizedUser, selectedChatSnapId, dialogue) => {
  try {
    // получение заявок клиента
    const appsCollSnapshot = await getDocs(getApplicationsToAssign(clientId));
    // получение чата клиента
    let chat
    let chatRef
    if(dialogue && selectedChatSnapId) {
      chat = dialogue
      chatRef = getChatRef(selectedChatSnapId)
    } else {
      const dialogueCollSnapshot = await getDocs(getChatQueryForApplication(clientId));
      if(dialogueCollSnapshot.empty) {
        throw new Error({name: 'ChatDownloadError', message: 'Чат клиента не найден' })
      }
      chatRef = dialogueCollSnapshot.docs[0].ref;
      chat = dialogueCollSnapshot.docs[0].data();
    }
    if(chat.supportId === employeeId) {
      throw new Error('Current employee already busy with this client')
    }
    // Переназначение визвоика в заявках и чате.
    await runTransaction(firestore, async (transaction) => {
      // Transaction get для чата
      const chatDoc = await transaction.get(chatRef); // получим свежий chatDoc для првоерки свойства active
      // Transaction get для заявок
      if (!appsCollSnapshot.empty) {
        const appsRefAndReadStatus = await getAppsRefAndReadStatus(appsCollSnapshot.docs, transaction);
        // Transaction update заявок
        const logData = getChangeAssigneeLogData(authorizedUser, employeeName, employeeId);
        appsRefAndReadStatus.forEach( async (elem) => {
          const appNewData = {
            "preparedInformation.assignedTo": employeeId,
            'preparedInformation.assignedAt': Timestamp.now(),
            'preparedInformation.operatorName': employeeName,
          }
          if(elem.isRead) appNewData.isRead = false
          transaction.update(elem.ref, appNewData)
          // сохраняем в историю
          transaction.set(doc(elem.ref, 'assignment_history', logData.id), logData);
        })
      }

      // Transaction update чата
      const newData = { assignedTo: employeeId }
      if(!chatDoc.get('active')) newData.active = true;
      transaction.update(chatRef, newData);

      // transaction update user profile
      const userCollSnap = await getDocs(getClientQueryByUID(clientId));
      if(!userCollSnap.empty) {
        transaction.update(userCollSnap.docs[0].ref, {assignedTo: employeeId});
      }
    })
  } catch(e) {
    throw e
  }
};

export default assignOperator;