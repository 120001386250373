import { Button, TreeSelect } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useEffect } from 'react';
import '../../assets/components/assigned-to-table-filter/assignedto-filter.scss';

const AssignedToCustomizedFilter = ({filterProps}) => {
  const [ selectedEmployees, setSelectedEmployees ] = useState(filterProps.selectedKeys);
  const [ ref, setRef ] = useState(null);
  const [ assigneeDropdownOpened, setAssigneeDropdownOpened ] = useState(false);
  const [ confirmDisabled, setConfirmDisabled ] = useState(false);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    // подтверждение изменений при клике за пределами дропдауна фильтра таблицы
    const clickOutsideHandler = (e) => {
      // клик по фильтру не регистрируется document'ом, поэтому проверка на клик за пределами скрыта
      // if(!e.composedPath().includes(containerRef.current)) {
        if(confirmDisabled) return;
        filterProps.confirm();
      // }
    }
    // document не регистрирует клик по нашему узлу (фильтр) из рефа (из-за особенностей антовских дропдаунов?)
    document.addEventListener('click', clickOutsideHandler)
    return () => {
      document.removeEventListener('click', clickOutsideHandler)
    }
  },[confirmDisabled, filterProps])

  useEffect(() => {
    // сохраним в стейт наш реф, чтобы обновленный ui смог получить узел.
    if(assigneeDropdownOpened === true) {
      setRef(containerRef.current);
    }
  },[assigneeDropdownOpened])

  const clearFiltersHandler = () => {
    filterProps.clearFilters();
    setConfirmDisabled(false)
    setSelectedEmployees([])
  }

  const handleOkButtonClick = () => {
    filterProps.confirm();
    filterProps.close();
  }

  const employeesDropdownChangeHandler = (value, label, extra) => {
    if(value.length > 30) {
      setConfirmDisabled(true)
    }
    if(confirmDisabled && value.length < 30) {
      setConfirmDisabled(false)
    }
    filterProps.setSelectedKeys(value);
    setSelectedEmployees(value)
  }

  const employeesDropdownVisibleChangeHandler = (open) => {
    setAssigneeDropdownOpened(open)
  }

  const employeesDropdownSearch = (input, node) => {

    if(typeof node.title === 'string') {
      return false;
    } else {
      const label = node.title.props.name;
      if(label.toLowerCase().startsWith(input.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }
  }

  const customizePlaceholder = (omittedValues) => {
    return (
      <p>
        {omittedValues.length} items
      </p>
    )
  }

  return (
    <div ref={containerRef} className='assigned-filter__container'>
      <div className='assigned-filter__employees-container'>
        <TreeSelect
          value={selectedEmployees}
          treeData={filterProps.filters}
          multiple
          treeDefaultExpandAll
          treeCheckable={true}
          treeNodeFilterProp='title'
          onChange={employeesDropdownChangeHandler}
          onDropdownVisibleChange={employeesDropdownVisibleChangeHandler}
          filterTreeNode={employeesDropdownSearch}
          popupMatchSelectWidth={false}
          placeholder="Поиск"
          maxTagCount={0}
          maxTagPlaceholder={customizePlaceholder}
          getPopupContainer={() => {
            return containerRef.current
          }}
          style={{
            width: '200px',
          }}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
            width:'max-content',
            top: ref ? ref.scrollHeight + 5 : '0px',
          }}
        />
      </div>
      <div className='assigned-filter__buttons-container'>
        <Button size='small' danger onClick={clearFiltersHandler}>Reset</Button>
        <Button size='small' type='primary' disabled={confirmDisabled} onClick={handleOkButtonClick}>Ok</Button>
      </div>
    </div>
  );
};

export default AssignedToCustomizedFilter;