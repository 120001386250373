import { getDocs, runTransaction } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { getDeletingOperatorAppsQuery } from "./getDeletingOperatorAppsQuery";
import { getDeletingOperatorChatQuery } from "./getDeletingOperatorChatQuery";
import getDeletingOperatorClientsQuery from "./getDeletingOperatorClientsQuery";

export const reassign = async (selectedOperator, deactivatedOperatorId) => {
  // если один из методов транзакции провалится - все изменения будут невыполнены.
  try {
    const promises = []
    promises.push(getDocs(getDeletingOperatorAppsQuery(deactivatedOperatorId)));
    promises.push(getDocs(getDeletingOperatorChatQuery(deactivatedOperatorId)));
    promises.push(getDocs(getDeletingOperatorClientsQuery(deactivatedOperatorId)));
    const collSnaps = await Promise.all(promises);

    const selOperIsSupport = collSnaps[1].docs.some(docSnap => selectedOperator === docSnap.get('supportId'));
    if(selOperIsSupport) throw new Error('Current employee already busy with this client')

    await runTransaction(firestore, async (transaction) => {
      collSnaps.forEach((collSnap, index) => {
        if(index === 0) {
          collSnap.forEach(docSnap => transaction.update(docSnap.ref, {'preparedInformation.assignedTo' : selectedOperator}));
        }
        collSnap.forEach(docSnap => transaction.update(docSnap.ref, {assignedTo: selectedOperator}));
      })
    })
  } catch (error) {
    throw error;
  }
};
