import React,{useState, useContext } from 'react';
import { Input, Button } from 'antd';
import {SendOutlined } from "@ant-design/icons"
import ChatUpload from './upload/ChatUpload';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import { sendMessage } from '../firebase/sendMessage';
import '../../../assets/chat/chat-footer.scss';
import { LayoutFuncsContext } from '../../../contexts/layoutContexts';
const { TextArea } = Input;

const ChatFooter = ({ selectedChatSnapId, dialogue, unreadMessagesDocs, applicantId, allMessagesRef, setUploadingMessageWithAttachments}) => {
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser, role } = useContext(UserDataContext)
  const [ text, setText] = useState("");
  const { switchChatScrollMode } = useContext(LayoutFuncsContext)

  const handleChange = (e)=> {
    setText(e.target.value);
  }

  const handleSend = async () => {
    if(!text) {
      return
    }
    await sendMessage(notificationAPI, text, authorizedUser, dialogue, selectedChatSnapId, unreadMessagesDocs)
    switchChatScrollMode(false)
    setText("")
  }

  const keyDownHandler = (e) => {
    if(e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault()
      handleSend();
    }
  }

  const inputAllowed =
    role === 'admin' ||
    role === 'superOperator'||
    role === 'careSupport'  ||
    dialogue.assignedTo === authorizedUser.id;
  return (
    <div className="chat__footer" >
      <div className="chat__upload-button">
        <ChatUpload
          dialogue={dialogue}
          selectedChatSnapId={selectedChatSnapId}
          messageText={text}
          applicantId={applicantId}
          setUploadingMessageWithAttachments={setUploadingMessageWithAttachments}
          messages={dialogue.messages}
          disabled={!inputAllowed}
          allMessagesRef={allMessagesRef}
          unreadMessagesDocs={unreadMessagesDocs}
        />
      </div>
      <div className='chat__message-input' >
        {/* <TextAreaComp value={text} onChange={handleChange}/> */}
        <TextArea
          value={text}
          classNames={{textarea:'chat__textarea'}}
          autoSize={{minRows: 1, maxRows: 6}}
          placeholder='Напишите сообщение...'
          onKeyDown={keyDownHandler}
          disabled={!inputAllowed}
          onChange={handleChange}
        />
      </div>
      <div className='chat__send'>
        <Button
          className='chat__send-button'
          disabled={!inputAllowed}
          icon={<SendOutlined className='send-button__icon'/>}
          onClick={handleSend}
        />
      </div>
    </div>
  )
};

export default ChatFooter;
