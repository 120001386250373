import React, { useContext, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import getChatsQueryForDialoguesList from '../firebase/getChatsQueryForDialoguesList';
import { UserDataContext } from '../../../contexts/context';

const useChatsData = (chatsSearchFilter, chatListPages) => {
  const { authorizedUser } = useContext(UserDataContext);
  const [ resultDialoguesDocSnaps, setResultDialoguesDocSnaps] = useState([]);
  const [ chatsCollSnapshot, chatsLoading, chatsError ] = useCollection(getChatsQueryForDialoguesList(authorizedUser, chatsSearchFilter, chatListPages));

  useEffect(() => {
    // Если чаты подгрузились - сохраняем в стейт. Использование данных, возвращаемых из хука, не подходит, т.к. во время загрузки они undefined.
    // Так же игнорим данные, возвращаемые хуком из кэша.
    if(chatsCollSnapshot && !chatsCollSnapshot.metadata.fromCache) {
      setResultDialoguesDocSnaps(chatsCollSnapshot.docs)
    }
  }, [chatsCollSnapshot])

  return [
    resultDialoguesDocSnaps, chatsLoading, chatsError
  ]
};

export default useChatsData;