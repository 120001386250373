import { getDocs, query, writeBatch } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import showNotification from "../../NotificationService/helpers/showNotification";
import getClientQueryByUID from "../../../firebase/clients/getClientQueryByUID";

const changeAppStatus = async (selectedStatus, curApplication, dialogueSnap, appRef, currentClientApplications) => {
  const unfinishedAppsLeft = currentClientApplications.filter(appSnap => appSnap.get('preparedInformation.preparationStatus') <= 4)
  const curAppIsFinished = curApplication.preparedInformation.preparationStatus > 4;
  const curAppWillFinished = !curAppIsFinished && selectedStatus > 4;
  const curAppWillReturn = curAppIsFinished && selectedStatus <= 4;
  const allAppsFinished = unfinishedAppsLeft.length === 0;
  const oneUnfinishedAppLeft = unfinishedAppsLeft.length === 1;

  const unfinishedAppsSnaps = currentClientApplications.reduce((acc, appSnap) => {
    if(appSnap.get('preparedInformation.preparationStatus') < 5) {
      acc.push(appSnap);
      return acc;
    }
    return acc;
  }, [])
  const isOperatorIsSame = unfinishedAppsSnaps.every(appSnap => appSnap.get('preparedInformation.assignedTo') === curApplication.preparedInformation.assignedTo);


  try {
    const batch = writeBatch(firestore);

    if(oneUnfinishedAppLeft && curAppWillFinished) {
      // сброс чата
      const clientsCollSnap = await getDocs(getClientQueryByUID(curApplication.UID));

      batch.update(dialogueSnap.ref, {
        'assignedTo':  '',
        'active':  false,
      })
      // сброс профиля клиента
      if(!clientsCollSnap.empty) {
        batch.update(clientsCollSnap.docs[0].ref, {
          assignedTo:'',
        })
      }
    }
    if (curAppWillReturn && allAppsFinished) {
      // назначение чата и профиля на визовика этой заявки
      const employeeId = curApplication.preparedInformation.assignedTo;
      if(dialogueSnap.get('supportId') === employeeId) {
        throw new Error('Current employee already busy with this client')
      }
      const clientsCollSnap = await getDocs(getClientQueryByUID(curApplication.UID));

      batch.update(dialogueSnap.ref, {
        'assignedTo':  employeeId,
        'active':  true,
      })
      if(!clientsCollSnap.empty) {
        batch.update(clientsCollSnap.docs[0].ref, {
          assignedTo: employeeId,
        })
      }
    }
    if (curAppWillReturn && !allAppsFinished) {
      // в такой ситуации чат еще находится в работе на каком то визовике. Может и на на текущем, т.к. эта заявка завершена.
      // проверяем, совпадают ли визовики в незавершенных заявках с визовиком этой заявки.
      // если не совпадают - запретить назначение чата, профиля и смену статуса, т.к. другой визовик сейчас работает с незавершенными заявки и с чатом. Нельзя иметь разных визовиков на незавершенных заявках одного клиента.

      if(!isOperatorIsSame) {
        // если нет - заблокировать смену статуса, чат и профиль клиента не трогать.
        // TODO: нотификация о запрете.
        throw {name: 'StatusChangeError', message: 'You can not change application status now'};
      }
    }
    // смена статуса.
    batch.update(appRef, {'preparedInformation.preparationStatus': selectedStatus})
    await batch.commit();
  } catch (error) {
    throw error;
  }
}

export default changeAppStatus;
