import { sectionStatuses } from "./sectionStatuses";

const createChangeSectionStatusOperationData = (statusOption, docSectionData) => {
  return {
    type: 'changeSectionStatus',
    newStatusTitle: statusOption.label,
    newStatusStep: statusOption.value,
    oldStatusTitle: sectionStatuses[docSectionData.step].titleRu,
    step: docSectionData.step,
  }
}

export default createChangeSectionStatusOperationData;