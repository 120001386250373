import React from 'react';
import '../../../assets/pages/push-creator-page.scss'
// import PushCreator from '../../../modules/PushCreator/components/PushCreator';
import PushCreator from '../../../modules/PushCreator/components/PushCreator';
import PushHistory from '../../../modules/PushHistory/components/PushHistory';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import getPushesQuery from '../firebase/getPushesQuery';
import sendPush from '../firebase/sendPush';

const PushCreatorPage = () => {
  const [ pushHistory, pushHistoryLoading, pushHistoryError, pushHistoryCollSnap, reloadPushHistory ] = useCollectionDataOnce(getPushesQuery());

  const sendPushHandler = async (push) => {
    try {
      await sendPush(push);
      reloadPushHistory();
    } catch (error) {
      throw error;
    }
  }

  return (
    <div className='push-creator-page__container'>
      <div className='push-creator__container'>
        <PushCreator sendPushHandler={sendPushHandler}/>
      </div>
      <div className='push-history__container'>
        <PushHistory
          pushHistory={pushHistory}
          pushHistoryLoading={pushHistoryLoading}
          pushHistoryError={pushHistoryError}
        />
      </div>
    </div>
  );
};

export default PushCreatorPage;