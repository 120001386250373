import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";

const saveNewsData = async (data) => {
  try {
    await setDoc(doc(firestore, `news/${data.id}`), data);
  } catch (error) {
    throw error;
  }
}

export default saveNewsData;