import React from 'react';
import '../../../assets/news/news-feed.scss';
import NewsList from './NewsList';

const NewsFeed = ({newsData, newsLoading, newsError, reload}) => {
  return (
    <div className='news-feed__container'>
      <h1 className='news-feed__title' >Новости</h1>
      <div className='news-feed__list'>
        <NewsList newsData={newsData} newsLoading={newsLoading} newsError={newsError} reload={reload}/>
      </div>
    </div>
  );
};

export default NewsFeed;