const readFileAsDataURL = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = () => {
      reject(reader.error);
    }
  })
}

export default readFileAsDataURL;