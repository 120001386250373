import { doc, runTransaction, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { getAppRefById } from "../../../firebase/applications/getAppRefById";
import getDocSectionRef from "./getDocSectionRef";
import getDocSectionCommentRef from "./getDocSectionCommentRef";

const updateUploadedDocsInfo = async (appId, updatedDocuments, applicantId, sectionId, serviceMessageData) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      transaction
      .update(getAppRefById(appId), {'preparedInformation.documents': updatedDocuments})
      .set(getDocSectionRef(appId, applicantId, sectionId), {lastActivity: serverTimestamp(), isRead:false}, {merge:true})
      .set(getDocSectionCommentRef(appId, applicantId, sectionId, serviceMessageData.id), serviceMessageData)
    })
  } catch (error) {
    throw error;
  }
}

export default updateUploadedDocsInfo;