/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { UserDataContext } from './context';
import {
  AllOperatorsContext,
  AppsPagesContext,
  AppsSearchFiltersContext,
  BreadcrumbsContext,
  ClientsByAppsPagesContext,
  ClientsDataContext,
  DialogueCloseContext,
  IssuesPagesContext,
  LayoutFuncsContext,
  ScrollModeContext,
  SectionsPagesContext,
  ShowedAppsContext,
  StartSearchContext
} from './layoutContexts';
import useEmployeesPartialData from '../useEmployeesPartialData';

const LayoutContextsProvider = ({
  children,
  scrollMode,
  switchChatScrollMode,
  clientsData,
  closeDialogue
}) => {
  // contexts
  const { role } = useContext(UserDataContext);
  // states
  const [ pageCount, setPageCount ] = useState(1); // сколько сейчас загружено пачек заявок
  const [ clientsByAppsPageCount, setClientsByAppsPageCount ] = useState(1);
  const [ sectionsPageCount, setSectionsPageCount ] = useState(1); // сколько сейчас загружено пачек секций (Ассистент)
  const [ issuesPageCount, setIssuesPageCount ] = useState(1); // сколько сейчас загружено пачек запросов на помощь (Ассистент)
  const [ searchFilters, setSearchFilters ] = useState(null);
  const [ startSearch, setStartSearch ] = useState(false);
  // const [ breadCrumbs, setBreadCrumbs ] = useState([]);
  // data downloading
  const [ allOperators, allOperatorsLoading, allOperatorsError ] = useEmployeesPartialData(true); // для AssignOperator, ReassignAndDeactivate

  const switchStartSearch = useCallback((bool) => {
    setStartSearch(bool)
  }, [])

  const resetAppsPageNum = useCallback(() => {
    setPageCount(1);
  }, [])

  const resetClientsByAppsPageNum = useCallback(() => {
    setClientsByAppsPageCount(1);
  }, [])

  const resetAssignedSectionsPageNum = useCallback(() => {
    setSectionsPageCount(1);
  }, [])

  const addClientsByAppsPage = useCallback(() => {
    setClientsByAppsPageCount(prev => ++prev);
  }, [])

  const addAppsPage = useCallback(() => {
    setPageCount(prev => ++prev);
  }, [])

  const addAssignedSectionsPage = useCallback(() => {
    setSectionsPageCount(prev => ++prev);
  }, [])

  const addIssuesPage = useCallback(() => {
    setIssuesPageCount(prev => ++prev);
  }, [])

  const saveSearchFilters = useCallback((filters) => {
    setSearchFilters(filters);
  }, [])

  // объекты контекстов
  const appsPagesContext = useMemo(() => {
    return {
      pageCount
    }
  }, [pageCount])

  const clientsByAppsPagesContext = useMemo(() => {
    return {
      clientsByAppsPageCount
    }
  }, [clientsByAppsPageCount])

  const sectionsPagesContext = useMemo(() => {
    return {
      sectionsPageCount
    }
  }, [sectionsPageCount])

  const issuesPagesContext = useMemo(() => {
    return {
      issuesPageCount
    }
  }, [issuesPageCount])

  const appsSearchFiltersContext = useMemo(() => {
    return {
      searchFilters
    }
  }, [searchFilters])

  const scrollModeContext = useMemo(() => {
    return {
      scrollMode,
    }
  }, [scrollMode])



  const clientsDataContext = useMemo(() => {
    return {
      clientsData,
    }
  }, [clientsData])

  const allOperatorsContext = useMemo(() => {
    return {
      allOperators,
      allOperatorsLoading,
      allOperatorsError
    }
  }, [allOperators, allOperatorsLoading, allOperatorsError])

  const dialogueCloseContext = useMemo(() => {
    return {
      closeDialogue
    }
  }, [closeDialogue])

  const startSearchContext = useMemo(() => {
    return {
      startSearch
    }
  }, [startSearch])

  // const breadcrumbsContext = useMemo(() => {
  //   return {
  //     breadCrumbs
  //   }
  // }, [])

  const layoutFuncsContext = useMemo(() => {
    // никогда не изменяются, поэтому приводить к ререндеру подписанных на контекст компонентов не будут.
    return {
      addAppsPage,
      addClientsByAppsPage,
      addAssignedSectionsPage,
      addIssuesPage,
      resetAppsPageNum,
      resetClientsByAppsPageNum,
      resetAssignedSectionsPageNum,
      saveSearchFilters,
      switchChatScrollMode,
      switchStartSearch
    }
  }, [addAppsPage, addAssignedSectionsPage, addIssuesPage, resetAppsPageNum, saveSearchFilters,  switchChatScrollMode])

  return (
    <AppsPagesContext.Provider value={appsPagesContext}>
      <LayoutFuncsContext.Provider value={layoutFuncsContext}>
        <SectionsPagesContext.Provider value={sectionsPagesContext}>
          <IssuesPagesContext.Provider value={issuesPagesContext}>
            <AppsSearchFiltersContext.Provider value={appsSearchFiltersContext}>
              <ScrollModeContext.Provider value={scrollModeContext}>
                <ClientsDataContext.Provider value={clientsDataContext} >
                  <AllOperatorsContext.Provider value={allOperatorsContext} >
                    <DialogueCloseContext.Provider value={dialogueCloseContext}>
                      <ClientsByAppsPagesContext.Provider value={clientsByAppsPagesContext}>
                        <StartSearchContext.Provider value={startSearchContext} >
                          {/* <BreadcrumbsContext.Provider value={breadcrumbsContext}> */}
                            {children}
                          {/* </BreadcrumbsContext.Provider> */}
                        </StartSearchContext.Provider>
                      </ClientsByAppsPagesContext.Provider>
                    </DialogueCloseContext.Provider>
                  </AllOperatorsContext.Provider>
                </ClientsDataContext.Provider>
              </ScrollModeContext.Provider>
            </AppsSearchFiltersContext.Provider>
          </IssuesPagesContext.Provider>
        </SectionsPagesContext.Provider>
      </LayoutFuncsContext.Provider>
    </AppsPagesContext.Provider>
  );
};

export default LayoutContextsProvider;