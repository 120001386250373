import React, { useState, useEffect, useContext } from 'react';
import { Card, Progress } from 'antd';
import { testStatuses } from '../../../helpers/app-status';
import { getFileRef } from '../../../firebase/firebase';
import { ApplicationStatus, ProgramContext, UserDataContext } from '../../../contexts/context';
import { getDownloadURL } from 'firebase/storage';
import ToolBarTitle from './ToolBarTitle';
import ToolBarFooter from './ToolBarFooter';
import StatusesSelect from '../../../components/selectors/StatusesSelect';
import showNotification from '../../NotificationService/helpers/showNotification';
import changeAppStatus from '../firebase/changeAppStatus';
import getAppsForOperatorToChangeStatus from '../firebase/getAppsForOperatorToChangeStatus';
import { Timestamp } from 'firebase/firestore';
import addToLog from '../../../firebase/addToLog';
import { nanoid } from 'nanoid';

const visaType = {
  tourist: "Туристическая",
  student: "Студенческая",
  work: "Рабочая",
}

const ApplicationToolBar = ({clientId, curApplication, country, appRef, dialogueSnap, currentClientApplications}) => {
  const [ progressPercent, setProgressPercent]  = useState();
  const [ progressColor, setProgressColor ] = useState();
  const [ flagUrl, setFlagUrl ] = useState(null);
  const { curAppStatus, appId, accesses } = useContext(ApplicationStatus);
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser, role } = useContext(UserDataContext);
  useEffect(() => {
    // if(countryFlag) {
    const func = async () => {
      const flagRef = getFileRef(country.flag);
      const url = await getDownloadURL(flagRef);
      setFlagUrl(url)
    }
    func()
    // }
  })

  useEffect(() => {
    if(curAppStatus || curAppStatus === 0) {
      setProgressPercent(testStatuses[curAppStatus].progressPercent);
      setProgressColor(testStatuses[curAppStatus].progressBarColor);
    }
  },[curAppStatus])

  const handleStatusSelect = async (selectedStatus) => {
    if(selectedStatus === curApplication.preparedInformation.preparationStatus) return; // нет эффекта если выбрали текущий статус.
    try {
      // для визовика и супервизовика, назначенного на эту заявку - запрашиваем все
      const allAppsForOperator = (role === 'operator' && (role === 'superOperator' && curApplication.preparedInformation.assignedTo === authorizedUser.id)) && await getAppsForOperatorToChangeStatus(clientId, authorizedUser.id);
      await changeAppStatus(selectedStatus, curApplication, dialogueSnap, appRef, allAppsForOperator || currentClientApplications);
      const logData = {
        id: nanoid(),
        statusId: selectedStatus,
        statusName: testStatuses[selectedStatus].fullStatusName,
        changedAt:Timestamp.now(),
        operatorId:authorizedUser.id,
        operatorName: authorizedUser.name,
      }
      await addToLog(logData, appRef, 'statuses_history');
      showNotification(notificationAPI, 'process', {processName: 'statusChanged', status: 'success',});
    } catch (e) {
      console.log(e)
      if(e.message === 'Current employee already busy with this client') {
        showNotification(notificationAPI, 'process', {processName: 'employeeIsBusy', status:'error'})
        return;
      }
      if(e.name === 'StatusChangeError') {
        showNotification(notificationAPI, 'process', {processName: 'statusChangeAllowance', status: 'error',});
        return;
      }
      showNotification(notificationAPI, 'process', {processName: 'statusChanged', status: 'error',})
    }
  }

  const cardTitle = `${country.name_ru}-${visaType[Object.keys(curApplication.type)[0]]}`
  const helping = dialogueSnap.get('supportId') === authorizedUser.id;

  return (
    <div style={{marginBottom:"10px"}}>
      <Card
        headStyle={{padding:"42px 27px 0", backgroundColor:"#182A67", font:"500 20px Jost, sans-serif", color:"#fff", borderRadius:"0"}}
        bodyStyle={{padding:"44px 27px 22px", backgroundColor:"#182A67", borderRadius:"0"}}
        title={<ToolBarTitle cardTitle={cardTitle} flagUrl={flagUrl} helping={helping}/>}
        // extra={<QuestionnaireFillPercentage />}
      >
        <Progress
          percent = {progressPercent}
          strokeLinecap = "square"
          size = {["418px", 41]} // ширина и высота. Ширина складывается из фактической ширина прогресс бара и зарезервированного паддинга под ::after.
          strokeColor = {progressColor}
          trailColor = "#fff"
          style={{
            marginBottom:"25px",
            position:"relative", // позиционируем, чтобы спан прогресс бара, в котором рендерится селектор позиционировался относительно начальных координат прогресс бара
          }}
          format = {() => <StatusesSelect  handleStatusSelect={handleStatusSelect} disabled={!accesses.canChangeAppStatus}/>}
        />
        <ToolBarFooter
          curApplication={curApplication}
          dialogueSnap={dialogueSnap}
          currentClientApplications={currentClientApplications}
        />
      </Card>
    </div>
  );
};

export default ApplicationToolBar;
