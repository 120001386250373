import { Button, Input } from 'antd';
import React, { useContext, useState } from 'react';
import '../../../assets/push-creator/push-creator.scss'
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import showNotification from '../../NotificationService/helpers/showNotification';
import createPushObj from '../models/createPushObj';
const { TextArea } = Input;

const PushCreator = ({sendPushHandler}) => {
  // contexts
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser } = useContext(UserDataContext);
  // states
  const [ pushState, setPushState ] = useState({body:'', title:''});
  const [ isLoading, setIsLoading ] = useState(false);

  const pushStateChangeHandler = (e, field) => {
    setPushState(prev => ({...prev, [field]: e.target.value}));
  }

  const sendButtonClickHandler = async () => {
    try {
      if(!pushState.body || !pushState.title) {
        throw new Error('Заголовок и тест уведомления должны быть заполнены!')
      }
      setIsLoading(true)
      const push = createPushObj(pushState, authorizedUser);
      await sendPushHandler(push)
      showNotification(notificationAPI, 'process', {processName: 'pushes', status: 'success',});
      setPushState({body:'', title:''});
    } catch (error) {
      console.log(error)
      showNotification(notificationAPI, 'process', {processName: 'pushes', status: 'error',})
    } finally {
      setIsLoading(false)
    }
  }

  const keyDownHandler = (e) => {
    if(e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      sendButtonClickHandler();
    }
  }

  return (
    <div className=''>
      <h2 className='push-creator__title'>Уведомления</h2>
      <TextArea
        value={pushState.title}
        classNames={{textarea:'push-creator__title-textarea'}}
        onChange={(e) => pushStateChangeHandler(e, 'title')}
        autoSize={{minRows: 2, maxRows:4}}
        placeholder='Текст заголовка...'
        onKeyDown={keyDownHandler}
      />
      <TextArea
        value={pushState.body}
        classNames={{textarea:'push-creator__text-textarea'}}
        onChange={(e) => pushStateChangeHandler(e, 'body')}
        autoSize={{minRows: 6, maxRows:10}}
        placeholder='Текст уведомления...'
        onKeyDown={keyDownHandler}
      />
      <div className="push-creator__send-button-container">
        <Button
          size='large'
          block
          type='primary'
          onClick={sendButtonClickHandler}
          loading={isLoading}
        >Отправить</Button>
      </div>
    </div>
  );
};

export default PushCreator;