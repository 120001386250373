import { Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import '../../../../assets/documents/docs-by-sections/documents-section.scss';
import { DocSectionContext, UserDataContext } from '../../../../contexts/context';
import SectionTitle from './SectionTitle';
import SectionContent from './SectionContent';
import { useCollectionData, useDocumentData, useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import getCommentsQuery from '../../firebase/getCommentsQuery';
import { useParams } from 'react-router-dom';
import getDocSectionRef from '../../firebase/getDocSectionRef';
import readUnreadSection from '../../firebase/readUnreadSection';

const DocumentSection = ({section, applicantId, applicantName}) => {
  const { authorizedUser, role } = useContext(UserDataContext);
  // states
  const [ isMaxDocsError, setIsMaxDocsError ] = useState(false)
  const [ modalIsOpened, setModalIsOpened ] = useState(false);
  // react-router
  const { appId } = useParams();
  //downloading data
  const [ commentsData, commentsLoading, commentsError, commentsCollSnap ] = useCollectionData(getCommentsQuery(appId, applicantId, section.sectionId), {snapshotOptions: {serverTimestamps:'estimate'}});
  const [ docSectionData, docSectionDataLoading, docSectionDataError, docSectionDataDocSnap ] = useDocumentData(getDocSectionRef(appId, applicantId, section.sectionId));

  useEffect(() => {
    // автопросматривание секции, если она сейчас открыта.
    if(docSectionData?.assistantId === authorizedUser.id) { // если я - ответственный ассистент
      if(modalIsOpened && docSectionData?.isRead !== undefined) { // если секция раскрыта и свойство isRead существует.
        if(!docSectionData.isRead) { // если дата последней активности превышает дату последнего чтения ассистентом.
          try {
            readUnreadSection(docSectionDataDocSnap.ref);
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }, [authorizedUser.id, docSectionData, docSectionDataDocSnap, modalIsOpened])

  // Авто исчезание сообщения о превышении лимита загружаемых файлов.
  useEffect(() => {
    if(isMaxDocsError) {
      setTimeout(() => {
        setIsMaxDocsError(false);
      }, 6000)
    }
  }, [isMaxDocsError])

  const modalClose = () => {
    setModalIsOpened(false)
  }

  const openModal = () => {
    setModalIsOpened(true);
  }

  const docSerctionContextValue = {
    commentsData,
    commentsLoading,
    commentsError,
    docSectionData,
    docSectionDataLoading,
    docSectionDataError,
    applicantId,
    applicantName,
    section,
  }
  const sectionIsDisabled = role === 'assistant' && docSectionData?.assistantId !== authorizedUser.id;

  return (
    <>
      <div className={`section__container ${role === 'assistant' ? 'assistant-section' : ''}`}>
        <div className='section__header'>
          <SectionTitle
            section={section}
            openModal={openModal}
            assistantName={docSectionData?.assistantName}
            sectionDisabled={sectionIsDisabled}
            isRead={docSectionData?.isRead}
          />
        </div>
      </div>
      <Modal
        open={modalIsOpened}
        centered
        onCancel={modalClose}
        width='80%'
        destroyOnClose={true}
        footer={null}
      >
        <DocSectionContext.Provider value={docSerctionContextValue}>
          <SectionContent />
        </DocSectionContext.Provider>
      </Modal>
    </>
  );
};

export default DocumentSection;