import React from 'react';

const EmptyFillingBox = () => {
  return (
    <li  className="invisible-container">
      <div className="invisible-message"></div>
    </li>
  );
};

export default EmptyFillingBox;