import React, { useContext, useState } from 'react';
import { ProgramContext } from '../../../../contexts/context';
import NewsEditorHeader from './NewsEditorHeader';
import MyEditor from '../../../../CKeditor/components/MyEditor';
import NewsEditorApplyChanges from './NewsEditorApplyChanges';
import ApplyOrCancel from '../../../../components/ApplyOrCancel';
import uploadPicture from '../../firebase/uploadPicture';
import getUpdatedData from '../../helpers/getUpdatedData';
import deletePreviousPicture from '../../firebase/deletePreviousPicture';
import updateDocField from '../../../../firebase/updateDocField';
import getNewsDocRef from '../../firebase/getNewsDocRef';
import showNotification from '../../../NotificationService/helpers/showNotification';
import readFileAsDataURL from '../../../../utils/readFileAsDataURL';
import '../../../../assets/news/editor/news-editor.scss';
import NewsContent from '../NewsContent';
import NewsEditorPicturePreview from './NewsEditorPicturePreview';
import downloadNewsFiles from '../../../../firebase/files/downloadNewsFiles';
import deleteFilesFromStorage from '../../firebase/deleteFilesFromStorage';
import NewsEditorFiles from './NewsEditorFiles';

const createInitialStateData = (news) => {
  const {content, picture} = news;
  return {
    content,
    picture,
    files: news.files,
  }
}

const NewsEditor = ({news, date, setIsEdit, reloadNews}) => {
  const [ newsDataState, setNewsDataState ] = useState(() => createInitialStateData(news));
  const [ loading, setLoading ] = useState(false);
  const [ headPictureList, setHeadPictureList ] = useState([]);
  const [ filesToUpload, setFilesToUpload ] = useState([]);
  const [ filesToDelete, setFilesToDelete ] = useState([]);
  const { notificationAPI } = useContext(ProgramContext);

  const changeText = (editor) => {
    setNewsDataState(prev => ({...prev, content: editor.getData()}));
  }

  const deleteFile = (deletingFile) => {
    // 1) Если файл еще не был загружен , то удалить из filesToUpload.
    // 2) Если файл был загружен - удалить на фронте из newsDataState и добавить в filesToDelete  линку.
    if(deletingFile.link) { // файл был загружен
      setNewsDataState(prev => ({...prev, files: prev.files.filter(fileInfo => fileInfo.uid !== deletingFile.uid)}));
      setFilesToDelete(prev => [...prev, deletingFile.link]);
    } else { // файл только что добавлен  на фронт и не был загружен
      setFilesToUpload(prev => prev.filter(file => file.uid !== deletingFile.uid));
    }
  }

  const beforeFileUpload = async (file, fileList) => {
    setFilesToUpload(prev => [...prev,  {
      fileOrigin: file,
      uid: file.uid,
      link: '',
      downloadURL: '',
      name: file.name,
    }]);
    return false;
  }

  const cancelChanges = () => {
    setIsEdit(false);
    setNewsDataState(createInitialStateData(news));
    setHeadPictureList([])
    setFilesToUpload([]);
    setFilesToDelete([]);
  }

  const deletePicturePreview = () => {
    setNewsDataState(prev => ({...prev, picture: {name:'', downloadUrl: ''}}));
    setHeadPictureList([]);
  }

  const applyChanges = async () => {
    try {
      setLoading(true);
      if(!newsDataState.content) {
        throw new Error('Содержание новости не может быть пустым')
      }
      let filesInfoToUpload;
      if(filesToUpload.length) {
        const path = `news/${news.id}`
        filesInfoToUpload = await downloadNewsFiles(filesToUpload, path, setFilesToUpload);
      }
      const uploadedPictureResult = headPictureList.length && await uploadPicture(news.id, headPictureList[0]);
      const updatedData = await getUpdatedData(newsDataState, uploadedPictureResult, filesInfoToUpload);
      if((news.picture.downloadUrl && headPictureList.length) || (news.picture.downloadUrl && !newsDataState.picture.downloadUrl)) {
        // удаляем старую фотку из storage если мы выбрали новую картинку и ранее была загружена другая картинка.
        await deletePreviousPicture(`news/${news.id}/${news.picture.name}`);
      }
      if(filesToDelete) {
        await deleteFilesFromStorage(filesToDelete)
      }
      await updateDocField(getNewsDocRef(news.id), updatedData);
      showNotification(notificationAPI, 'process', {processName:'dataUpdated', status:'success'});
      setIsEdit(false);
      reloadNews();
    } catch (error) {
      showNotification(notificationAPI, 'process', {processName:'dataUpdated', status:'error'});
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  /**
   * Создает превьюшку, не загружая файл.
   * @param {*} file
   * @param {*} fileList
   * @returns
   */
  const beforeHeadPictureUpload = async (file, fileList) => {
    setHeadPictureList(fileList);
    const url = await readFileAsDataURL(file);

    setNewsDataState(prev => ({...prev, picture: {...prev.picture, downloadUrl: url}})) // имя не сохраняем, т.к. имя будет сохранено после загрузки картинки (это будут милисекунды даты создания)
    // setImgPreviewUrl(url);
    setIsEdit(true);
    return Promise.reject(); // отмена дефолтной загрузки файла компонентом Upload (cancel ant design's default behavior)
  }

  return (
    <div >
      <NewsEditorHeader
        date={date}
        authorName={news.author.name}
        fileList={headPictureList}
        beforeHeadPictureUpload={beforeHeadPictureUpload}
        setIsEdit={setIsEdit}
      />
      <NewsEditorPicturePreview
        url={newsDataState.picture.downloadUrl}
        deletePicturePreview={deletePicturePreview}
      />
      <NewsContent>
        <MyEditor
          changeText={changeText}
          initialData={newsDataState.content}
          disabled={loading}
        />
      </NewsContent>
      <NewsEditorFiles
        fileList={newsDataState.files.concat(filesToUpload)}
        filesBeforeUpload={beforeFileUpload}
        disableDeletion={loading}
        deleteFile={deleteFile}
      />
      <NewsEditorApplyChanges>
        <ApplyOrCancel
          isEdit={true}
          cancelChanges={cancelChanges}
          applyChanges={applyChanges}
          loading={loading}
        />
      </NewsEditorApplyChanges>
    </div>
  );
};

export default NewsEditor;