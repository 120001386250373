import React, { useContext } from 'react';
import '../../../../assets/documents/docs-by-sections/section-content.scss'
import SectionComments from './SectionComments';
import SectionFiles from './SectionFiles';
import SectionContentTitle from './SectionContentTitle';
import { DocSectionContext } from '../../../../contexts/context';
import Spinner from '../../../../components/Spinner';
import MyError from '../../../Error/components/MyError';

const SectionContent = () => {
  const { docSectionDataLoading, docSectionDataError } = useContext(DocSectionContext);

  if(docSectionDataLoading) {
    return <Spinner />
  }

  if(docSectionDataError) {
    return <MyError error={docSectionDataError} />
  }

  return (
    <div className='section-content__container'>
      <SectionContentTitle />
      <div className="section-content__content">
        <SectionComments />
        <SectionFiles />
      </div>
    </div>
  );
};

export default SectionContent;