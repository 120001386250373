import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Select } from 'antd';
import AssignOperator from '../../AssignOperator/components/AssignOperator';
import '../../../assets/application-info-card-footer.scss';
import getApplicationsSwitcherOptions from '../helpers/getApplicationsSwitcherOptions';
import { ApplicationStatus, UserDataContext } from '../../../contexts/context';
import FinishIssue from '../../../components/FinishIssue';
const {Title, Text} = Typography;

const ToolBarFooter = ({curApplication, dialogueSnap, currentClientApplications}) => {
  const { appId, clientId } = useParams();
  const navigate = useNavigate();
  const { features, authorizedUser } = useContext(UserDataContext)

  const { assignedOperator, assignedOperatorError } = useContext(ApplicationStatus)

  const handleChange = (value, _option) => {
    if (value === appId) {
      return
    }
    navigate(`/application/${clientId}/${value}`);
  }

  const assignedTo = curApplication.preparedInformation.assignedTo;
  const applicationSwitcherOptions = getApplicationsSwitcherOptions(currentClientApplications);
  return (
    <div className="aplication-info-card__footer">
      <div className="aplication-info-card__assignedTo">
        <Typography >
          <Title style={{color:"#fff"}} level={4}>
            Отв-ный:
          </Title>
        </Typography>
        {features.canAssignOperator ? (
          <AssignOperator
            clientId={clientId}
            selectedChatSnapId={dialogueSnap.id}
            dialogue={dialogueSnap.data()}
            assignedTo={assignedTo}
            transparent={false}
            disabledProp={curApplication.preparedInformation.preparationStatus > 4}
          />
        ) : (
          <Text strong style={{color:'white'}}>
            {assignedOperator?.name}
          </Text>
        )}
      </div>
      {dialogueSnap.get('supportId') === authorizedUser.id ? (
        <div className="aplication-info-card__finish-issue-container">
          <FinishIssue
            UID={dialogueSnap.get('UID')}
            issueId={dialogueSnap.get('issueId')}
            dialoguerRef={dialogueSnap.ref}
          />
        </div>
      ) : null}
      <div className="aplication-info-card__application-switcher">
        <Typography >
          <Title style={{color:"#fff"}} level={4}>
            Количество заявок: {currentClientApplications.length}
          </Title>
        </Typography>
        <Select
          style={{
            width: 150,
          }}
          dropdownStyle={{
            width:"240px",
          }}
          onSelect={handleChange}
          options={applicationSwitcherOptions}
          value={assignedOperatorError ? 'Ошибка' : appId}
        />
      </div>
    </div>
  );
};

export default ToolBarFooter;
