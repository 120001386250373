import React from 'react';
import Applications from '../../modules/Applications/components/Applications';
import { useParams } from 'react-router-dom';

const ClientPage = () => {
  const { UID } = useParams();

  return (
    <Applications clientUID={UID}/>
  );
};

export default ClientPage;