import React from 'react';
import '../../../assets/application-additionals/journal.scss';
import StatusesHistory from './StatusesHistory';
import DocumentsHistory from './DocumentsHistory';
import AssignmentHistory from './AssignmentHistory';
import PriceHistory from './PriceHistory';

const Journal = ({statusesHistoryData, documentsHistoryData, assignmentHistoryData, priceHistoryData}) => {
  return (
    <div className='history'>
      <AssignmentHistory historyData={assignmentHistoryData}/>
      <StatusesHistory historyData={statusesHistoryData} />
      <DocumentsHistory historyData={documentsHistoryData} />
      <PriceHistory priceHistoryData={priceHistoryData} />
    </div>
  );
};

export default Journal;