import GLOBAL_ROLES from "../../../constants/constants";

const pathsToShow = ['/', 'client-by-apps', 'client-apps'];

/**
 * routes and corresponding option keys to be displayed by default
 */
const defaultSearchOptions = {
  '/': 'applicationId',
  'client-by-apps': 'clientId',
  'client-apps': 'applicationId',
}

const appsSearchOptionsConfig = [
  {
    pages:{
      operator: ['/'],
      admins: ['/', 'client-apps'],
    },
    key: 'applicationId',
    label: 'ID заявки',
    value: 'applicationId',
  },
  {
    pages: {
      operator: ['/'],
      admins: ['/', 'client-by-apps'],
    },
    key: 'clientId',
    label: 'ID клиента',
    value: 'clientId',
  },
  {
    pages: {
      operator: ['/'],
      admins: ['/', 'client-by-apps'],
    },
    key: 'phone',
    label: '№ телефона',
    value: 'phone',
  },
  {
    pages: {
      operator: ['/'],
      admins: ['/', 'client-by-apps'],
    },
    key: 'email',
    label: 'Email',
    value: 'email',
  },
  {
    pages: {
      operator: ['/'],
      admins: ['/', 'client-by-apps'],
    },
    key: 'name',
    label: 'Имя',
    value: 'name',
  },
  {
    pages: {
      operator: ['/'],
      admins: ['/', 'client-by-apps', 'client-apps'],
    },
    key: 'empty',
    label: '(нет)',
    value: 'empty',
  }
]


/**
 * default search options by route.
 */
export const getDefaultSearchOption = (path) => {
  const pathChunks = path === '/' ? '/' : path.split('/')[1]
  return defaultSearchOptions[pathChunks];
}

const checkRole = (role) => {

  const roleMatches = {
    [GLOBAL_ROLES.operator]: [GLOBAL_ROLES.operator],
    admins: [GLOBAL_ROLES.admin, GLOBAL_ROLES.careSupport, GLOBAL_ROLES.superOperator],
  }
  const keys = Object.keys(roleMatches);
  for(let key of keys) {
    if(roleMatches[key].includes(role)) return key;
  }
  return false;
}

const getSearchOptions = (location, role) => {
  const path = location.pathname === '/' ? location.pathname : location.pathname.split('/')[1];
  const innerRole = checkRole(role);
  if(innerRole) {
    return appsSearchOptionsConfig.filter(option => option.pages[innerRole].includes(path));
  }
  return [];
}

export const checkToShow = (locationPath) => {
  const path = locationPath === '/' ? locationPath : locationPath.split('/')[1];
  return pathsToShow.includes(path)
}

export default getSearchOptions;