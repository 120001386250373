import { getDateFromTimestamp } from "../../../utils/getDateFromTimestamp";

const getTableData = (applications) => {
  return applications.reduce((acc, application) => {
    if (!application.UID || !application.documentID) {
      console.log('поступила заявка с ошибкой')
      console.log('UID: ', application?.UID)
      console.log('documentID: ', application?.documentID)
      return acc;
    }

    acc.push({
      key: application.documentID,
      application: application,
      accountIsDeleted: application.accountDeleted,
      id: application.documentID.slice(0,4),
      date: getDateFromTimestamp(application.createdAt, 'withTime'),
      iin: application.passports[0].IIN,
      applicant: application.applicantName || `ID: ${application.UID.slice(0,4)}`,
      citizenship: application.citizenship.toUpperCase(),
      email: application.applicantEmail,
      phone: application.applicantPhone,
      applicants: application.passports.length,
      payment: application.paymentSuccessful,
      paymentDate: getDateFromTimestamp(application.paymentInfo?.confirmDate, 'withTime'),
      totalPrice: `${application.price_USD} KZT`,
      countryFullName: application.country_name_ru,
    })
    return acc;
  }, [])
};

export default getTableData;
