import React, { useContext, useEffect, useRef } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import getNewMessageQuery from '../firebase/getNewMessageQuery';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import showNotification from '../helpers/showNotification';
import getChatData from '../firebase/getChatData';
import { ScrollModeContext } from '../../../contexts/layoutContexts';
import { useSelectedChatContext } from '../../Messenger/context/MessengerDataProvider';

const NewMessageNotificationService = ({loginTime}) => {
  // contexts
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser, role } = useContext(UserDataContext);
  const { scrollMode } = useContext(ScrollModeContext);
  const { selectedChat } = useSelectedChatContext()

  // data downloading
  const [ newMessageArr, loading, error, newMessageCollSnapshot ] = useCollectionData(getNewMessageQuery(loginTime));
  // refs
  const showedMessages = useRef({});

  useEffect(() => {
    if(newMessageCollSnapshot) {
      getChatData(newMessageCollSnapshot).then(res => {
        const chat = res;
        if(authorizedUser.id !== chat.assignedTo) return // тот кто не назначен на чат - не получит уведомление.
        const message = newMessageArr[0];
        const dialogIsOpened = chat.UID === selectedChat?.UID;
        if(dialogIsOpened && !scrollMode && chat.assignedTo) return; // мы в чате и он проскроллен и он назначен(потому что может быть ситуация когда чат не назначен - когда чат впервые стал активен и визовик его открыл). Не отображать нотификацию.
        if(showedMessages.current[message.id]) return; // если прочитано или уже записано в объект showedMessages. Не отображать нотификацию
        const clientName = message.sender === 'me' ? (chat?.name || chat.UID) :  message.sender;
        showNotification(notificationAPI, 'unreadMessage', {title : clientName, description: message.content,});
        showedMessages.current[message.id] = true;
      }).catch(error => console.log(error.message));
    }
  }, [newMessageCollSnapshot])

  if(error) {
    console.log(error);
  }

  return
};

export default NewMessageNotificationService;