import React, { forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { Button, Popover, Select } from 'antd';
import { testStatuses } from '../../helpers/app-status.js';
import { ApplicationStatus, ProgramContext, UserDataContext } from '../../contexts/context.js';

const StatusesSelect = ({handleStatusSelect, disabled=false}) => {
  const { curAppStatus } = useContext(ApplicationStatus);
  const { role } = useContext(UserDataContext);

  const content = <div style={{width:'100%'}}>
    <p>Если все заявки будут завершены, чат сбрасывается автоматически.</p>
    <p>После этого работа с клиентом считается завершенной.</p>
  </div>

  const statuses = Object.values(testStatuses).reduce((acc, statusConfig, index) => {
    if (role === 'operator' && statusConfig.dbProp <= curAppStatus) {
      return acc;
    }
    if(statusConfig.dbProp > 4) {
      acc.push({
        label: (
          <Popover
            title='Внимание'
            content={content}
            placement={"right"}
            zIndex={10000}
            color='#faaba5'
          >
          <p>{statusConfig.selectLabel}</p>
          </Popover>
        ),
        value: statusConfig.dbProp,
      });
    } else {
      acc.push({
        label: statusConfig.selectLabel,
        value: statusConfig.dbProp,
      });
    }
    return acc;
  }, [])

  return (
    <Select
      disabled={disabled}
      value={testStatuses[curAppStatus].selectLabel}
      dropdownStyle={{
        borderRadius:"0"
      }}
      placement="bottomRight"
      bordered={false}
      style={{
        position:"absolute", // чтобы спан прогресс бара позиционировался относительно первого позиционированного родителя - то есть прогресс бара.
        left:0,
        top:"5px", // сдвиг селектора по вертикали, чтобы верхний край дропдауна начинался с нижнего края прогрессбара и не было щели.
        width:"382px", // ширина селектора, соответствующая !! фактической !! ширине прогресс бара.
        font:"400 18px Inter, sans-serif" // Todo: менять через DesignToken. Так не срабатывает.
      }}
      options={statuses}
      onSelect={handleStatusSelect}
    />
  );
}

export default StatusesSelect;
