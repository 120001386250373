/**
 * Обновляет ответ вопроса с типом list.
 * value: {
 *   itemIndex, индекс изменяемого элемента / null
 *   value, новое значение элемента / null
 *   type, тип операции со списком ('add'/'delete'/'change')
 * }.
 * @param {*} list 
 * @param {*} value 
 * @returns новый экземпляр обновленного списка ответов.
 */
const updateList = (list, value) => {

  if(value.type === 'change') {
    return [...list.slice(0, value.itemIndex), value.value, ...list.slice(value.itemIndex + 1)];
  }
  if(value.type === 'add') {
    return [...list, ''];
  }
  if(value.type === 'delete') {
    return list.filter((_listItem, index) => index !== value.itemIndex);
  }
}
/**
 * Обновление подвопроса в вопросе типа dropdown
 * @param {*} dropdownResponseCopy - response дропдауна. Общий или персональный.
 * @param {*} questionData - кастомный question для подвопроса (Создается самим дропдауном при отрисовке подвопроса)
 * @param {*} value - обновленное значения для свойства answer элемента из массива answers.
 * @param {*} nestedOptions - вспомогательная информация подвопросов дропдауна. {
 *   isNested, - вложенный ли вопрос. Для данной функции всегда true.
 *   nestedQuestionIndex, - индекс подвопроса из массива answers
 *   dropdownQuestionData, - question самого дропдауна
 * }
*/
const updateDropdownSubQuestion = (dropdownResponseCopy, questionData, value, nestedOptions) => {
  if (questionData.type === 'list') {
    const list = dropdownResponseCopy.answers[nestedOptions.nestedQuestionIndex].answer;
    dropdownResponseCopy.answers[nestedOptions.nestedQuestionIndex].answer = updateList(list, value);
  } else {
    dropdownResponseCopy.answers[nestedOptions.nestedQuestionIndex].answer = value;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// без рефакторинга

/**
 *
 * @param {*} answersToUpdate - массив, собирающий изменяемые вопросы [{
 *   newResponse, - полный response вопроса (всех заявителей, если вопрос персональный)
 *   index - индекс вопроса из общего списка вопросов.
 * },]
 * @param {*} value - (обычно новый response). обновленный ответ вопроса / подвопроса. Обычно является свойством response
 * @param {*} questionData - объект question для вопроса или кастомный question для подвопроса (Создается самим дропдауном при отрисовке подвопроса)
 * @param {*} questionIndex - индекс вопроса из общего списка вопросов
 * @param {*} personIndex - num / null. Если передан, значит вопрос персональный для каждого заявителя. Нет - общий.
 * Response вопроса должен содержать массив ответов всех заявителей или объект с ключами = индексам паспартов заявителей.
 * @param {*} nestedOptions - вспомогательная информация для подвопросов дропдауна. {
 *   isNested, - если true - значит это подвопрос дропдауна. Хранится в дропдауне - response. или response[индекс заявителя]. + answers[индекс подвопроса]
 *   nestedQuestionIndex, - индекс подвопроса из массива answers
 *   dropdownQuestionData, - question самого дропдауна
 * }
 * @returns новый экземпляр answersToUpdate
 */
const prepareChanges = (answersToUpdate, value, questionData, questionIndex, personIndex, nestedOptions = {isNested: false}) => {
  const mainQuestionIsPersonal = personIndex !== null; 
  const isSubQuestion = nestedOptions.isNested; // редактируемый вопрос - вложенный. 
  const answerIndexInArray = answersToUpdate.findIndex(question => question.index === questionIndex);

  if(answerIndexInArray === -1) {
    // вопрос не редактировался
    if(isSubQuestion) {
      // подвопрос дропдауна
      // !!внести изменения в дропдаун сразу, и записать новый response в массив редактируемых вопросов сразу с изменениями!!
      // Вносим изменения в дропдаун.
      if(mainQuestionIsPersonal) {
        const dropdownResponse = nestedOptions.dropdownQuestionData.response;
        const personDropdownResponseCopy = {...dropdownResponse[personIndex]};
        updateDropdownSubQuestion(personDropdownResponseCopy, questionData, value, nestedOptions, personIndex);
        const allPersonsResponse = [...dropdownResponse.slice(0, personIndex), personDropdownResponseCopy, ...dropdownResponse.slice(personIndex + 1)]
        return [...answersToUpdate, {newResponse: allPersonsResponse, index: questionIndex}]; // поместить ранее нередактируемый вопрос (дропдаун) в массив редактируемых данных, с УЖЕ новыми данными.
      } else {
        const dropdownResponseCopy = nestedOptions.dropdownQuestionData.response;
        updateDropdownSubQuestion(dropdownResponseCopy, questionData, value, nestedOptions, personIndex);
        return [...answersToUpdate, {newResponse: dropdownResponseCopy, index: questionIndex}] // поместить ранее нередактируемый вопрос (дропдаун) в массив редактируемых данных, с УЖЕ новыми данными.
      }
    } else {
      let response;
      if (mainQuestionIsPersonal) {
        if (questionData.type === 'list') {
          // value = не response а редактируемый элемент списка, то есть одна строка из списка.
          const list = questionData.response[personIndex];
          const updatedPersonList = updateList(list, value);
          response = {...questionData.response, [personIndex]: updatedPersonList};
        } else {
          response = [...questionData.response.slice(0, personIndex), value, ...questionData.response.slice(personIndex + 1)];
        }
      } else {

        if (questionData.type === 'list') {
          const list = questionData.response;
          response = updateList(list, value);
        } else {
          response = value;
        }
      }
      return [...answersToUpdate, {newResponse: response, index: questionIndex}]
    }
  } else {
    const changedQuestion = answersToUpdate[answerIndexInArray];
    if(isSubQuestion) {
      if(mainQuestionIsPersonal) {
        // const dropdownResponse = nestedOptions.dropdownQuestionData.response;
        const dropdownResponse = changedQuestion.newResponse;
        const personDropdownResponseCopy = {...dropdownResponse[personIndex]};
        updateDropdownSubQuestion(personDropdownResponseCopy, questionData, value, nestedOptions, personIndex);
        const allPersonsResponse = [...dropdownResponse.slice(0, personIndex), personDropdownResponseCopy, ...dropdownResponse.slice(personIndex + 1)]
        return [...answersToUpdate.slice(0, answerIndexInArray), {...changedQuestion, newResponse: allPersonsResponse}, ...answersToUpdate.slice(answerIndexInArray + 1)]; // поместить ранее нередактируемый вопрос (дропдаун) в массив редактируемых данных, с УЖЕ новыми данными.
      } else {
        const dropdownResponseCopy = {...changedQuestion.newResponse};
        updateDropdownSubQuestion(dropdownResponseCopy, questionData, value, nestedOptions, personIndex);
        return [...answersToUpdate.slice(0, answerIndexInArray), {...changedQuestion, newResponse: dropdownResponseCopy}, ...answersToUpdate.slice(answerIndexInArray + 1)] // поместить ранее нередактируемый вопрос (дропдаун) в массив редактируемых данных, с УЖЕ новыми данными.
      }
    } else {
      let response;
      if (mainQuestionIsPersonal) {
        if (questionData.type === 'list') {
          // value = не response а редактируемый элемент списка, то есть одна строка из списка.
          const list = changedQuestion.newResponse[personIndex];
          const updatedPersonList = updateList(list, value);
          response = {...changedQuestion.newResponse, [personIndex]: updatedPersonList};
        } else {
          response = [...changedQuestion.newResponse.slice(0, personIndex), value, ...changedQuestion.newResponse.slice(personIndex + 1)];
        }
      } else {
        if (questionData.type === 'list') {
          const list = changedQuestion.newResponse
          response = updateList(list, value);
        } else {
          response = value;
        }
      }
      return [...answersToUpdate.slice(0, answerIndexInArray), {...changedQuestion, newResponse: response}, ...answersToUpdate.slice(answerIndexInArray + 1)]
    }
  }
}

export default prepareChanges;
