import React, { useContext, useEffect, useState } from 'react';
import SectionUpload from './SectionUpload';
import FilesNum from '../../../../components/FilesNum/FilesNum';
import FileList from '../../../../components/FileList/FileList';
import ErrorList from '../../../../components/ErrorList/ErrorList';
import isSectionFilesNumInvalid from '../../validation/isSectionFilesNumInvalid';
import { Spin, Upload } from 'antd';
import '../../../../assets/documents/docs-by-sections/section-files.scss'
import { ApplicationStatus, DocSectionContext, DocsContentContext, UserDataContext } from '../../../../contexts/context';
import createServiceCommentData from '../../models/createServiceCommentData';
import createDeleteFileOperationData from '../../models/createDeleteFileOperationData';

const SectionFiles = () => {
  // states
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ isMaxDocsError, setIsMaxDocsError ] = useState(false)
  //contexts
  const { isLoading, deleteFile, beforeUpload } = useContext(DocsContentContext);
  const { applicantId, section } = useContext(DocSectionContext);
  const { accesses } = useContext(ApplicationStatus);
  const { authorizedUser } = useContext(UserDataContext);

  useEffect(() => {
    if(isMaxDocsError) {
      setTimeout(() => {
        setIsMaxDocsError(false);
      }, 6000)
    }
  }, [isMaxDocsError])

  const deleteFileHandler = async (fileInfo) => {
    try {
      const deleteFileOperation = createDeleteFileOperationData(fileInfo.name, fileInfo.uid)
      const serviceCommentData = createServiceCommentData(authorizedUser, deleteFileOperation)
      setIsDeleting(true)
      await deleteFile(applicantId, section.sectionId, fileInfo, serviceCommentData);
    } catch (error) {
      console.log(error)
    } finally {
      setIsDeleting(false)
    }
  }

  const beforeUploadHandler = (file, fileList) => {
    if (isSectionFilesNumInvalid(fileList, section)) {
      setIsMaxDocsError(true)
      return Upload.LIST_IGNORE;
    }
    beforeUpload(file, fileList, applicantId, section)
    return false; // предотвращает загрузку по дефолтному адресу компонента Upload.
  }

  const uploadIsDisabled = !accesses.canUploadDocuments || isLoading || (section.docs.length === section.maxDocs);

  return (
    <div className="section-content__documents">
      <h3 className="section-content__documents-title">
        <span>Документы:</span>
        <SectionUpload
          uploadIsDisabled={uploadIsDisabled}
          beforeUploadHandler={beforeUploadHandler}
        />
        <FilesNum
          filesLenght={section.docs.length}
          maxNum={section.maxDocs}
        />
        {isDeleting ? (
          <div className='section__spinner-container'>
            <Spin size='small'></Spin>
          </div>
        ) : (
          null
        )}
      </h3>
      <div className="section-content__documents-content">
        <div className="section-content__file-list">
          <FileList
            fileList={section.docs}
            onDelete={deleteFileHandler}
            disableDeletion={isLoading}
          />
        </div>
        <ErrorList isMaxDocsError={isMaxDocsError} maxDocs={section.maxDocs}/>
      </div>
    </div>
  );
};

export default SectionFiles;