import React from 'react';
import '../../../../assets/documents/docs-by-sections/section-title.scss';
import FilesNum from '../../../../components/FilesNum/FilesNum';

const SectionTitle = ({section, openModal, assistantName, sectionDisabled, isRead}) => {

  const titleClickHandler = () => {
    openModal()
  }
  return (
    <div className={`section__title ${isRead === false ? 'is-new' : ''}`}>
      <div className='section__title-inner'>
        <div className={`section__title-text ${sectionDisabled ? 'disabled' : ''}`} onClick={titleClickHandler}>
          {section.sectionTitle}
        </div>
        <div className='section__additional'>
          <div className='section__assistant-name'>
            {assistantName}
          </div>
          <FilesNum
            filesLenght={section.docs.length}
            maxNum={section.maxDocs}
            disabled={sectionDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionTitle;