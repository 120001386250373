import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import SectionCommentsList from './SectionCommentsList';
import TextArea from 'antd/es/input/TextArea';
import { Button } from 'antd';
import {SendOutlined } from "@ant-design/icons"
import { DocSectionContext, UserDataContext } from '../../../../contexts/context';
import { useParams } from 'react-router-dom';
import '../../../../assets/documents/docs-by-sections/section-comments.scss'
import createCommentObj from '../../models/createCommentObj';
import leaveComment from '../../firebase/leaveComment';

const SectionComments = () => {
  // states
  const [ text, setText ] = useState('')
  // react-router
  const { appId } = useParams();
  // contexts
  const { authorizedUser } = useContext(UserDataContext);
  const { applicantId, section } = useContext(DocSectionContext);
  const { commentsData } = useContext(DocSectionContext)
  // ref
  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView(false);
    }, 1)
  }, [commentsData])

  const handleChange = (e)=> {
    setText(e.target.value);
  }

  const leaveCommentHandler = async () => {
    // TODO: добавить смену даты активности и в загрузку и удаление файла.
    try {
      const comment = createCommentObj(text, authorizedUser);
      setText('');
      await leaveComment(appId, applicantId, section.sectionId, comment)
    } catch (error) {
      console.log(error)
    }
  }

  const keyDownHandler = (e) => {
    if(e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      leaveCommentHandler();
    }
  }

  return (
    <div className='section-content__comments-container'>
      <div className='section-content__comments-list'>
        <SectionCommentsList />
        <span ref={scrollRef} ></span>
      </div>
      <div className="section-content__leave-comment-container">
        <div className="section-content__textarea-container">
          <TextArea
            value={text}
            classNames={{textarea:'section-content__textarea'}}
            autoSize={{minRows: 1, maxRows: 6}}
            placeholder='Напишите сообщение...'
            onKeyDown={keyDownHandler}
            onChange={handleChange}
          />
        </div>
        <div className="section-content__send-button-container">
          <Button
            className='chat__send-button'
            icon={<SendOutlined className='send-button__icon'/>}
            onClick={leaveCommentHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionComments;