import { Timestamp } from "firebase/firestore";

// значения по умолчанию для незаполненных подвопросов (при смене опции дропдауна).
const emptyAnswersForSubQuestions = {
  text: '',
  photo: '',
  choice: {
    pickedOption: -1,
    pickedOptionName: '',
  },
  list: [],
  date: Timestamp.fromMillis(new Date(2024)),
}

/**
 * При смене опции дропдауна должен раскрываться соответствующий опции список подвопросов.
 * Функция заполняет соответствующие вопросы ответами по умолчанию и комплектует обновленный response объект для дропдауна.
 * @param {*} value - индекс выбранной опции дропдауна
 * @param {*} optionInstance - экземпляр выбранной опции дропдауна
 * @param {*} questionData - question объект дропдауна.
 * @returns новый экземпляр response объекта для дропдауна.
 */
const getUpdatedDropDownResponse = (value, optionInstance, questionData) => {
  const subQuestions = questionData.options[value].questions;
  // заполняем все вопросы выбранной опции значениями по умолчанию.
  const newAnswers = subQuestions.map((subQuestion, index) => {
    const answer = {
      question: subQuestion.question,
      answer: emptyAnswersForSubQuestions[subQuestion.type],
      type: subQuestion.type,
    }

    // вопрос с типом choice должен содержать опции для выбора.
    if(subQuestion.type === 'choice') {
      answer.options = subQuestion.options;
    }

    return answer;
  })

  return {
    pickedOption: value,
    pickedOptionName: optionInstance.label,
    answers: newAnswers
  };
}

export default getUpdatedDropDownResponse;
