const getAppsRefAndReadStatus = async (appDocs, transaction) => {
  const promises = []
  const array = [];
  appDocs.forEach(appSnap => {
    const promise = transaction.get(appSnap.ref) // получим свежий appSnap для првоерки свойства isRead
      .then(freshAppSnap => {
        array.push({   // сохраним реф на заявку и статус isRead в массив.
          ref: appSnap.ref,
          isRead: freshAppSnap.get('isRead'),
        })
      })
    promises.push(promise)
  })
  await Promise.all(promises);
  return array;
}


export default getAppsRefAndReadStatus;
