import { collection, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";

export const getDeletingOperatorAppsQuery = (id) => {
  return query(
    collection(firestore, 'applications'),
    where('preparedInformation.assignedTo', '==', id),
    where('isCreated', '==', true),
    where('preparedInformation.preparationStatus','<=', 4),
    orderBy('preparedInformation.preparationStatus')
  );
};
