import { Upload } from 'antd';
import React from 'react';
import {FileImageOutlined} from '@ant-design/icons';
import '../assets/components/image-upload.scss';

const ImageUpload = ({fileList, beforeUploadHandler}) => {

  return (
    <Upload
      fileList={fileList}
      showUploadList={false}
      beforeUpload={beforeUploadHandler}
      maxCount={1}
    >
      <FileImageOutlined className="image-upload__icon interactive-icons"/>
    </Upload>
  );
};

export default ImageUpload;