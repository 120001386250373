import React, { useContext, useEffect, useRef } from 'react';
import showNotification from '../helpers/showNotification';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import { ClientsDataContext } from '../../../contexts/layoutContexts';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import getNewUnreadApplicationQuery from '../../../layout/firebase/getNewUnreadApplicationQuery';

/**
 * Если заявка падает впервые - показываем уведомление всем ролям, которые видят все заявки (визовикам не упадет).
 * Если заявка падает в результате назначение/переназначения - показываем уведомление только если она упала на "меня".
 * @param {*} param0
 * @returns
 */
const NewApplicationNotificationService = ({loginTime}) => {
  const { notificationAPI } = useContext(ProgramContext);
  const { authorizedUser, role } = useContext(UserDataContext)
  const { clientsData } = useContext(ClientsDataContext);
  const [ newUnreadApplication, newUnreadApplicationLoading, newUnreadApplicationError, collSnap ] = useCollectionData(getNewUnreadApplicationQuery(authorizedUser));
  const showedApps = useRef([]);

  useEffect(() => {
    if(newUnreadApplication && newUnreadApplication.length) {
      const application = newUnreadApplication[0]
      if(!application.preparedInformation.assignedTo) {
        // заявка упала впервые.
        if(application.createdAt.valueOf() < loginTime.valueOf()) return; // если дата создания заявки меньше даты входа в систему
        const alreadyShowed = showedApps.current.some(showedApp => showedApp.appId === application.documentID);
        if(alreadyShowed) return;
        const client = clientsData.find(client => client.UID === application.UID);
        const clientName = client?.name || client?.phone || client?.email || client?.UID;
        showNotification(notificationAPI, 'unreviewedApps', {applicantName: clientName, applicationId: application.documentID,});
        showedApps.current.push({appId: application.documentID, assignedAt: application.preparedInformation.assignedAt});
      } else {
        // заявка назначена/переназначена
        if(authorizedUser.id !== application.preparedInformation.assignedTo) return // не показываем уведомление, если не является ответственным.
        if(application.preparedInformation.assignedAt.valueOf() < loginTime.valueOf()) return // если дата назначения меньше даты входа в систему
        const showedApp = showedApps.current.find(showedApp => showedApp.appId === application.documentID);
        if(showedApp) {
          // заявка уже была показана ранее в этой сессии
          if(showedApp.assignedAt !== undefined && showedApp.assignedAt.valueOf() === application.preparedInformation.assignedAt.valueOf()) {
            // изменения в БД не коснулись этой заявки. Ее не показываем повторно
            return;
          } else {
            // в этой сессии заявку отзывали и назначили повторно. Показываем уведомление и пересохраняет дату назначения.
            const client = clientsData.find(client => client.UID === application.UID);
            const clientName = client?.name || client?.phone || client?.email || client?.UID;
            showNotification(notificationAPI, 'unreviewedApps', {applicantName: clientName, applicationId: application.documentID});
            showedApps.current = showedApps.current.map(showedApp => {
              if(showedApp.appId === application.documentID){
                return {...showedApp, assignedAt: application.preparedInformation.assignedAt}
              }
              return showedApp;
            })
          }
        } else {
          // заявка ранее не была показана, значит назначается впервые. Показываем уведомление.
          const client = clientsData.find(client => client.UID === application.UID);
          const clientName = client?.name || client?.phone || client?.email || client?.UID;
          showNotification(notificationAPI, 'unreviewedApps', {applicantName: clientName, applicationId: application.documentID});
          showedApps.current.push({appId: application.documentID, assignedAt: application.preparedInformation.assignedAt});
        }
      }
    }
  },[authorizedUser.id, clientsData, loginTime, newUnreadApplication, notificationAPI])

  if(newUnreadApplicationError) {
    console.log(newUnreadApplicationError);
  }

  return <></>
};

export default NewApplicationNotificationService;
