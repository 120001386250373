import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import '../../assets/components/popover-info.scss';

const PopoverInfo = ({text}) => {

  const textArray = text.split('/n');
  const content = (
    <ul>
      {textArray.map(text => <li>{text}</li>)}
    </ul>
  )

  return (
    <div className='popover-info'>
      <Popover content={content} title='Подсказка'>
        <InfoCircleOutlined style={{fontSize:'18px', color:"red"}}/>
      </Popover>
    </div>

  );
};

export default PopoverInfo;