import React from 'react';
import NewsPicturePreview from '../../../../components/news/NewsPicturePreview';
import PictureDeleteButton from '../../../../components/PictureDeleteButton';

const NewsEditorPicturePreview = ({url, deletePicturePreview}) => {
  return url ? (
    <div className='news-editor__picture-container'>
      <NewsPicturePreview url={url} />
      <PictureDeleteButton deleteFunc={deletePicturePreview} />
    </div>
  ) : (
    null
  )
};

export default NewsEditorPicturePreview;