import React from 'react';
import '../../../assets/news/new-news-form/new-news-form-picture-preview.scss'
import PictureDeleteButton from '../../../components/PictureDeleteButton';
import NewsPicturePreview from '../../../components/news/NewsPicturePreview';

const NewNewsFormPicturePreview = ({url, deleteFunc}) => {
  return url ? (
    <div className='new-news-form__picture-preview-container'>
      <NewsPicturePreview url={url}/>
      <PictureDeleteButton deleteFunc={deleteFunc}/>
    </div>
  ): (
    null
  )
};

export default NewNewsFormPicturePreview;