import React, { useContext } from 'react';
import { UserDataContext } from '../../../contexts/context';
import AssignOperator from '../../AssignOperator/components/AssignOperator';
import ChatActiveStatus from './ChatActiveStatus';
import ChatName from './ChatName';
import ChatPhoneNum from './ChatPhoneNum';
import TableStatusTag from '../../../components/TableStatusTag';

const ChatHeader = ({dialogue, clientData, applicantId, source, selectedChatSnapId, changeClientData, clientIsEditing}) => {
  const { role } = useContext(UserDataContext);
  const applicantName = dialogue?.name || dialogue.UID;
  
  return (
    <div className={`chat__info ${!dialogue.accountDeleted ? '' : 'chat__info__account-deleted' }`}>
      <div className='chat__common-info'>
        <ChatName  clientName={applicantName}  editClientFunc={changeClientData} loading={clientIsEditing} accountIsDeleted={dialogue.accountDeleted}/>
        <p className="chat__applicant-id">
          <span>ID: </span>
          <span>{dialogue.UID}</span>
        </p>
        <ChatPhoneNum phoneNum={dialogue?.phone} editClientFunc={changeClientData} loading={clientIsEditing} accountIsDeleted={dialogue.accountDeleted}/>
        {source === 'application' || role === 'operator' ? (
          <div className="chat__empty"></div>
        ) : (
          <div className="chat__operator-info">
            <div className="operator-title">
              Отв-ный:
            </div>
            <div className="operator-name">
              <AssignOperator clientId={applicantId} selectedChatSnapId={selectedChatSnapId} disabledProp={dialogue.accountDeleted} assignedTo={dialogue.assignedTo} size='small'/>
            </div>
          </div>
        )}
      </div>
      <div className="chat__main-app">
        <span className='main-app__title'>Основная заявка: </span>
        {clientData?.mainApp ? (
          <div className='main-app__content'>
            <span>ID: {clientData.mainApp.documentID.slice(0,4)}</span>
            <span>Страна: {clientData.mainApp.country_name_ru}</span>
            <span>Заявителей: {clientData.mainApp.passportsNum}</span>
            <div>
              <span>Статус: </span>
              <TableStatusTag status={clientData.mainApp.status}/>
            </div>
            <span>Цена: {clientData.mainApp.price_USD} KZT</span>
          </div>
        ) : (
          null
        )}
      </div>
      <div className="chat__status">
        <ChatActiveStatus
          dialogueAssignedTo={dialogue.assignedTo}
          dialogue={dialogue}
          selectedChatSnapId={selectedChatSnapId}
        />
      </div>
    </div>
  );
};

export default ChatHeader;