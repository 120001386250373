import React, { memo, useCallback, useContext } from 'react';
import OperatorsSelect from '../../../components/selectors/OperatorsSelect';
import { ProgramContext, UserDataContext } from '../../../contexts/context';
import showNotification from '../../NotificationService/helpers/showNotification';
import assignOperator from '../firebase/assignOperator';

const AssignOperator = memo(({clientId, transparent=true, assignedTo=null, disabledProp=false, selectedChatSnapId=null, dialogue=null, size='medium'}) => {
  const { notificationAPI } = useContext(ProgramContext)
  const { authorizedUser } = useContext(UserDataContext);

  const changeOperatorIntern = useCallback(async (employeeId, employeeName) => {
    if(assignedTo === employeeId) return false; // если заявка уже назначена на этого визовика
    try {
      await assignOperator(clientId, employeeId, employeeName, authorizedUser, selectedChatSnapId, dialogue)
      showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'success',})
    } catch(e) {
      console.log(e)
      if(e.message === 'Current employee already busy with this client') {
        showNotification(notificationAPI, 'process', {processName: 'employeeIsBusy', status:'error'})
      } else {
        showNotification(notificationAPI, 'process', {processName: 'operatorChanged', status: 'error',})
      }
    }
  }, [assignedTo, authorizedUser, clientId, selectedChatSnapId, notificationAPI, dialogue])

  return (
    <OperatorsSelect
      changeOperatorIntern={changeOperatorIntern}
      transparent={transparent}
      curOperator={assignedTo || null}
      selectIsDisabled={disabledProp}
      size={size}
    />
  )
});

export default AssignOperator;
