import { serverTimestamp } from "firebase/firestore";
import { nanoid } from "nanoid";

export const createNewMessageObject = (text, authorizedUser, attachmentsArray = [], assignedTo, supportId, isSttachmentsLoading) => {
  const time = isSttachmentsLoading ? Date.now() : serverTimestamp(); // для отображения загружающегося сообщения в чате на фронте или сообщения. принятого из firebase.
  return {
    id: nanoid(),
    attachments: attachmentsArray,
    content: text,
    sendState: 0,
    readBy: [authorizedUser.role,],
    isReadFromCRM: assignedTo === authorizedUser.id,
    supportMessage: supportId === authorizedUser.id,
    assigneeMessage: assignedTo === authorizedUser.id,
    sender: authorizedUser.name,
    avatar: authorizedUser.avatar,
    time: time,
    type: "message",
  }
}
