import React, { useContext } from 'react';
import { UserDataContext } from '../../../../contexts/context';
import { EditOutlined } from "@ant-design/icons";
import '../../../../assets/news/list-item-inner/news-list-item-header.scss';
import NewsInfo from '../NewsInfo';

const NewsListItemHeader = ({date, authorName, setIsEdit}) => {
  const { features } = useContext(UserDataContext);

  const editHandler = () => {
    setIsEdit(true);
  }

  return (
    <div className='news-list-item__header'>
      <NewsInfo
        date={date}
        authorName={authorName}
      />
      <div className='news-list-item__buttons'>
        {features.canEditNews ? (
          <EditOutlined
            className="news-list-item__edit-icon interactive-icons"
            onClick={editHandler}
          />
        ) : (
          null
        )}
      </div>
    </div>
  );
};

export default NewsListItemHeader;