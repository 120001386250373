import saveToLocalStorage from "./saveToLocalStorage";

const removeFilterFromLocalStorage = (value, authorizedUserId, filtersType) => {
  const storagedUserData = JSON.parse(localStorage.getItem(authorizedUserId));
  const filters = storagedUserData?.[filtersType];

  if(filters) {
    const storedValue = filters[value];
    if(storedValue && storedValue.length > 0) {
      const newApplicationFilters = {...filters, [value]: null}
      saveToLocalStorage(authorizedUserId, newApplicationFilters, filtersType)
    }
  }
};

export default removeFilterFromLocalStorage;