import React, { useState } from 'react';
import { EditOutlined } from "@ant-design/icons";
import { Input } from 'antd';
import ApplyOrCancel from '../../../components/ApplyOrCancel';
import "../../../assets/chat/chat-client-phone.scss";

// Временное решение, по ТЗ здесь должен находиться редактор телефона клиента только в случае, если номер телефона отсутствует, т.к. если номер телефона есть, то возможно он связан с firebase аккаунтом и нам нельзя в таком случае менять
const ChatPhoneNum = ({phoneNum, editClientFunc, loading, accountIsDeleted}) => {
  const [ clientPhoneIsEdit, setClientPhoneIsEdit] = useState(false);
  const [ newClientPhone, setNewClientPhone ] = useState('+')

  const enableEditName = () => {
    setClientPhoneIsEdit(true);
  }

  const handleInputChange = (e) => {
    if(!e.target.value.startsWith('+')) {
      return
    }
    setNewClientPhone(e.target.value)
  }

  const cancelEditName = () => {
    setClientPhoneIsEdit(false);
    setNewClientPhone('+');
  }

  const applyChanges = async () => {
    const newData = {phone: newClientPhone}
    await editClientFunc(newData)
    setClientPhoneIsEdit(false)
  }

  return (
    <div className={`chat__applicant-phone ${clientPhoneIsEdit ? 'chat__applicant-phone_is-edit' : ''}`}>
      {phoneNum === '' || phoneNum === undefined ?
        clientPhoneIsEdit ? (
          <>
            Тел:
            <div className='client-phone-edition-form'>
              <Input size='small' value={newClientPhone} onChange={handleInputChange} style={{width:'90px'}}/>
              <div className='applicant-name__tools'>
                <ApplyOrCancel isEdit={clientPhoneIsEdit} applyChanges={applyChanges} cancelChanges={cancelEditName} loading={loading} type='icons' />
              </div>
            </div>
          </>
        ) : (
          <>
            Тел:
            {accountIsDeleted ? null : <EditOutlined
              className="interactive-icons"
              style={{ fontSize: '16px', color: '#08c', marginLeft:"10px", marginRight:"10px"}}
              onClick={enableEditName}
            />}
          </>
        )
      : `Тел: ${phoneNum}`}
    </div>
  );
};

export default ChatPhoneNum;