import React, { useState } from 'react';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const convertDatesToDayjs = (dates) => {
  return dates.map(ms =>  dayjs(ms))
}

const DatesRangeTableFilter = ({filterProps}) => {
  const clearFiltersHandler = () => {
    filterProps.clearFilters();
  }

  const handleOkButtonClick = () => {
    filterProps.confirm();
    filterProps.close();
  }

  const datesChangeHandler = (dates, dateString) => {
    filterProps.setSelectedKeys(dates.map(date=>date.valueOf()))
  }

  return (
    <div className='numbers-range-filter__container'>
      <div className='numbers-range-filter__inputs'>
        <RangePicker
          value={convertDatesToDayjs(filterProps.selectedKeys)}
          onChange={datesChangeHandler}
        />
      </div>
      <div className='numbers-range-filter__footer'>
        <Button size='small' danger onClick={clearFiltersHandler}>Reset</Button>
        <Button size='small' type='primary' onClick={handleOkButtonClick}>Ok</Button>
      </div>
    </div>
  );
};

export default DatesRangeTableFilter;