import dayjs from 'dayjs';
import React from 'react';
import '../../../assets/application-additionals/created-date.scss';

const CreatedDate = ({date}) => {
  const formattedDate = dayjs(date.toMillis()).format('DD.MM.YYYY HH:mm');

  return (
    <div className='created-date__container'>
      <span className='created-date__title'>Дата создания: </span>
      <span className='created-date__value'>{formattedDate}</span>
    </div>
  );
};

export default CreatedDate;