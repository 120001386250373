import React, { useContext } from 'react';
import { ProgramContext, ReloadTimerContext, VersionCheckContext } from '../contexts/context';
import { useEffect } from 'react';
import showNotification from '../modules/NotificationService/helpers/showNotification';

const TimeToReloadMessage = () => {
  const { mins, secs } = useContext(ReloadTimerContext);
  const { needReload } = useContext(VersionCheckContext);
  const { notificationAPI } = useContext(ProgramContext);
  const key = 'timer'
  const data = `${String(mins).length === 2 ? mins : `0${mins}`}:${String(secs).length === 2 ? secs : `0${secs}`}`

  useEffect(() => {
    if(needReload) {
      showNotification(notificationAPI, 'process', {processName: 'reloadIncoming', status:'warning', data, key})
    }
  }, [data, needReload, notificationAPI])

  return null;
};

export default TimeToReloadMessage;