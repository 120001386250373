import React from 'react';
import {ReactComponent as OperatorIcon} from '../assets/icons/operator.svg';
import {ReactComponent as SuperOperatorIcon} from '../assets/icons/superOperator.svg';
import {ReactComponent as AssistantIcon} from '../assets/icons/assistant.svg';
import {ReactComponent as CareSupportIcon} from '../assets/icons/care-support.svg';
import {ReactComponent as AdminIcon} from '../assets/icons/Administrator.svg';
import '../assets/components/role-icons.scss'

const roleIconsComponents = {
  operator: {
    node: OperatorIcon,
    color:'#ffd230'
  },
  superOperator: {
    node: SuperOperatorIcon,
    color: '#38be69',
  },
  assistant:{
    node: AssistantIcon,
    color: '#B8C75F',
  },
  careSupport: {
    node: CareSupportIcon,
    color: '#2ccaad',
  },
  admin: {
    node: AdminIcon,
    color: '#2CB6C6',
  }
}

const RoleIcons = ({role, iconSize}) => {
  if(!roleIconsComponents[role]) {
    return null;
  }
  const RoleICon = roleIconsComponents[role].node;
  return <RoleICon style={{width:iconSize, height:iconSize, fill:roleIconsComponents[role].color}}/>;
};

export default RoleIcons;