import { limit, orderBy, query } from "firebase/firestore"
import getClientsRef from "../../../firebase/clients/getClientsRef"

const getClientsTableQuery = (clientsListPage=null) => {
  const constraints = []

  if(clientsListPage) {
    constraints.push(limit(50 * clientsListPage));
  }

  constraints.push(orderBy('created_at', 'desc'));

  return query(getClientsRef(), ...constraints);
}

export default getClientsTableQuery;