import React from 'react';
import { getCollapseItems } from '../helpers/getCollapseItems';
import { Collapse, Empty } from 'antd';

const CommonQuestionnaire = ({commonQuestionnaireData}) => {
  if (!commonQuestionnaireData) {
    return (
      <Empty
        description={<span>Анкета пока не заполнена...</span>}
        rootClassName="empty"
      />
    )
  }
  const questionnaireItems = getCollapseItems(commonQuestionnaireData); // компануем секцию и айтемы в айтемы для компонента collapse (секция - label, айтемы - children для выпадающей секции)

  return (
    <Collapse
      bordered={false}
      items={questionnaireItems}
      size={"middle"}
    />
  );
};

export default CommonQuestionnaire;
