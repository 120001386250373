import saveFilterToLocalStorage from "./saveFilterToLocalStorage";

const removeFilterIfColumnDeselected = (value, authorizedUserId, filtersType) => {
  const storagedUserData = JSON.parse(localStorage.getItem(authorizedUserId));
  const applicationFilters = storagedUserData?.[filtersType];
  console.log(applicationFilters)

  if(applicationFilters) {
    const storedValue = applicationFilters[value];
    console.log(storedValue)
    if(storedValue && storedValue.length > 0) {
      const newApplicationFilters = {...applicationFilters, [value]: null}
      saveFilterToLocalStorage(authorizedUserId, newApplicationFilters, filtersType);
    }
  }
}

export default removeFilterIfColumnDeselected;