import { collectionGroup, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { chatPaths } from "../../../firebase/chat/chatsPath";

const getNewMessageQuery = (loginTime) => {
  return query(
    collectionGroup(firestore, chatPaths.messagesSubCollection),
    where('isReadFromCRM', '==', false),
    where('time', '>', loginTime),
    orderBy('time', 'desc'),
    limit(1),
  )
}

export default getNewMessageQuery;