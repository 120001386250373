import { useCollectionData } from "react-firebase-hooks/firestore";
import { getQueryForAppsWithLimit } from "../firebase/getQueryForAppsWithLimit";
import { getAppsCollRef } from "../../../firebase/applications/getAppsCollRef";
import { APPS_PER_PAGE } from "../constants/constants";
import { useContext, useEffect, useState } from "react";
import { getCountFromServer } from "firebase/firestore";
import { getAllFieldsFromDocSnapshot } from "../../../helpers/getAllFieldsFromDocSnapshot";
import { AppsSearchFiltersContext } from "../../../contexts/layoutContexts";
import { useLocation } from "react-router-dom";

const useApplications = (authorizedUser, tableFilters, clientUID, pageCount) => {
  const { searchFilters } = useContext(AppsSearchFiltersContext);
  // Метод загрузки при включенном режиме поиска через поисковую строку.
  // const [ searchedApps, totalCount, searchedAppsLoading, serachedAppsError ] = useSearchApps(tableFilters, pageCount)
  // Стандартный метод загрузки заявок. Если включен режим поиска заявок по строке поиска - этот хук деактивируется.
  const [ applicationsTotalCount, setApplicationsTotalCount ] = useState(0);
  const [ applicationsData, tableLoading, tableError, applicationsCollSnap ] = useCollectionData(
    getQueryForAppsWithLimit(
      getAppsCollRef(),
      authorizedUser,
      clientUID,
      tableFilters,
      searchFilters,
      APPS_PER_PAGE,
      pageCount
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true // без этого при переходе со страницы clientsByApps используются данные из кеша и заявки не подгнружаются.
      }
    }
  );

  useEffect(() => {
    // получаение общего количества возможных заявок
    const getAllAppsCount = async () => {
      const queryForAppsWithoutLimit = getQueryForAppsWithLimit(getAppsCollRef(), authorizedUser, clientUID, tableFilters, searchFilters);
      const aggregateSnapshot = await getCountFromServer(queryForAppsWithoutLimit);
      setApplicationsTotalCount(getAllFieldsFromDocSnapshot(aggregateSnapshot).count);
    }
    getAllAppsCount();
  }, [authorizedUser, clientUID, searchFilters, tableFilters])

    return [
      applicationsData,
      applicationsTotalCount,
      tableLoading,
      tableError,
      applicationsCollSnap,
    ]
}

export default useApplications;