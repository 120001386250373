import allAppsCheckAssignedToAndStatus from "./allAppsCheckAssignedToAndStatus";
import getAppIdWithFinishedStatus from "./getAppIdWithFinishedStatus";
import getAppIdWithMaxPreparedStatus from "./getAppWithMaxPreparedStatus";

const getAppIdToNavigate = (allClientAppsData, authorizedUser) => {
  // TODO: раз applicationId передается через стейт навигации - попробовать сразу включить айди в URL строку.
  // если через стейт передан айди - сразу возвращаем,  т.к. мы зашли через таблицу.
  if(!allClientAppsData.length) {
    // если зашли через URL по айди клиента. Если заявок нет - вернется пустой массив.
    return false;
  }

  // ------------ для супервизовиков и админов --------------
  if(authorizedUser.role !== 'operator') {
    // если есть незавершенные - взять самую старшую.
    // если незаврешенных нет - взять последнюю по дате создания (значит все завершены).
    let maxUnfinishedStatusIndex;
    let curMaxStatus = 0;
    let lastCreated; // FB Timestamp. сравниваются методом valueOf()

    allClientAppsData.forEach((app, index) => {
      const status = app.preparedInformation.preparationStatus;
      if (status <= 4) {
        if(maxUnfinishedStatusIndex === undefined || status > curMaxStatus || (status === curMaxStatus && app.createdAt.valueOf() > lastCreated.valueOf())) {
          maxUnfinishedStatusIndex = index;
          curMaxStatus = status;
          lastCreated = app.createdAt;
          return;
        }
      }
    })

    if(!maxUnfinishedStatusIndex) {
      return allClientAppsData.at(-1).documentID;
    }

    return allClientAppsData[maxUnfinishedStatusIndex].documentID;
  }

  //--------- для Визовиков ---------
  // узнаем, есть ли назначенные на визовика заявки в работе или законченные.
  const assignedToFlags = allAppsCheckAssignedToAndStatus(allClientAppsData, authorizedUser);


  if (assignedToFlags.meOperatorAssignedAndInProgress) {
    return getAppIdWithMaxPreparedStatus(allClientAppsData);
  } else if (assignedToFlags.meOperatorAssignedAndFinished) {
    return getAppIdWithFinishedStatus(allClientAppsData, authorizedUser.id)
  }
  return null;
}

export default getAppIdToNavigate;
