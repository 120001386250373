import React, { useContext, useMemo, useState } from 'react';
import { ReloadTimerContext, VersionCheckContext } from './contexts/context';
import { useEffect } from 'react';

const ReloadTimer = ({children}) => {
  const { needReload } = useContext(VersionCheckContext);
  const [ mins, setMins ] = useState(5);
  const [ secs, setSecs ] = useState(0);

  useEffect(() => {
    if(needReload) {
      const timerId = setTimeout(() => {
        if(!mins && !secs) {
          window.location.reload(true);
          clearInterval(timerId);
          return;
        }
        if(!secs) {
          setSecs(59)
          setMins(mins - 1)
          return;
        }
        setSecs(secs - 1)
      }, 1000 )
      return () => clearInterval(timerId);
    }
  }, [mins, needReload, secs])

  const context = useMemo(() => {
    return {
      mins, secs
    }
  },[mins, secs])

  return (
    <ReloadTimerContext.Provider value={context} >
      {children}
    </ReloadTimerContext.Provider>
  )
};

export default ReloadTimer;