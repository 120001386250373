import React from 'react';
import AssignmentHistoryItem from './AssignmentHistoryItem';

const AssignmentHistory = ({historyData}) => {
  const logs = historyData.map(entry => {
    return <AssignmentHistoryItem key={entry.id} entry={entry}/>
  })
  return (
    <div>
      <h3>
        История назначений:
      </h3>
      <ul>
        {logs}
      </ul>
    </div>
  );
};

export default AssignmentHistory;