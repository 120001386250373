import { createContext } from "react";

export const AppsPagesContext = createContext('!!no context provider!!');
export const ClientsByAppsPagesContext = createContext('!!no context provider!!');
export const SectionsPagesContext = createContext('!!no context provider!!');
export const IssuesPagesContext = createContext('!!no context provider!!');
export const LayoutFuncsContext = createContext('!!no context provider!!');
export const AppsSearchFiltersContext = createContext('!!no context provider!!');
export const ScrollModeContext = createContext('!!no context provider!!');
export const CloseApplicationPageContext = createContext('!!no context provider!!');
export const ClientsDataContext = createContext('!!no context provider!!');
export const AllOperatorsContext = createContext('!!no context provider!!');
export const DialogueCloseContext = createContext('!!no context provider!!');
export const ChatMessagesContext = createContext('!!no context provider!!');
export const StartSearchContext = createContext('!!no context provider!!');
export const BreadcrumbsContext = createContext('!!no context provider!!');

