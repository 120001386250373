const getCardClassName = (authorizedUser, isSelected, isDeleted, needHelp=null, supportId=null) => {
  let className = 'dialogue-card ';
  if (isSelected) className += 'dialogue-card_selected '
  if (isDeleted) className += 'is-deleted '
  // needHelp не может быть true если supportId записан, и наоборот.
  if (needHelp) className += 'dialogue-card_need-help'
  if (supportId === authorizedUser.id) className += 'dialogue-card_helping'
  return className;
}

export default getCardClassName;
