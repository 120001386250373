import { Upload } from 'antd';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import '../../assets/components/files-upload/files-upload.scss';
import FileList from '../FileList/FileList';

const FilesUpload = ({fileList, filesBeforeUpload, deleteFile, disableDeletion}) => {

  return (
    <div className='files-upload__container'>
      <Upload
        multiple
        showUploadList={false}
        fileList={fileList}
        beforeUpload={filesBeforeUpload}
      >
        <DownloadOutlined className='files-upload__icon interactive-icons' />
      </Upload>
      <FileList
        fileList={fileList}
        onDelete={deleteFile}
        disableDeletion={disableDeletion}
      />
    </div>
  );
};

export default FilesUpload;