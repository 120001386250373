import React from "react";
import {Layout}  from "antd";
import '../../../assets/pages/entry-page.scss';
const {Content} = Layout;

const EntryPage = ({children}) => {
  return (
    <Layout className="wrapper" >
      <Content className='entry-page__primary-container'>
        {children}
      </Content>
    </Layout>
  )
}

export default EntryPage;
