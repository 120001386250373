import React, { useContext } from 'react';
import '../../../../assets/documents/docs-by-sections/section-content-title.scss'
import { useParams } from 'react-router-dom';
import { ApplicationStatus, DocSectionContext, DocsContentContext, UserDataContext } from '../../../../contexts/context';
import { Select } from 'antd';
import SectionStatus from './SectionStatus';
import createAssistantAssignData from '../../models/createAssistantAssignData';
import createServiceCommentData from '../../models/createServiceCommentData';
import createAssignAssistantOperationData from '../../models/createAssignAssistantOperationData';

const SectionContentTitle = () => {
  const { appId, clientId } = useParams();
  const { accesses } = useContext(ApplicationStatus);
  const { section, applicantId, applicantName, docSectionData } = useContext(DocSectionContext);
  const { assistantsData, assignAssistant, applicantsNum, countryCode, citizenship } = useContext(DocsContentContext);
  const { authorizedUser } = useContext(UserDataContext);

  const assignAssistantHandler = async (value, option) => {
    if(value === docSectionData?.assistantId) return false; // от повторного назначения того же ассистента.
    const applicationInfo = {
      countryCode,
      citizenship,
      applicantsNum,
      applicationId: appId,
      UID: clientId,
    }
    const assistantAssignData = createAssistantAssignData(option, section, applicationInfo, applicantId, applicantName);
    const operation = createAssignAssistantOperationData(option);
    const serviceCommentData = createServiceCommentData(authorizedUser, operation);
    await assignAssistant(applicantId, section.sectionId, assistantAssignData, serviceCommentData);
  }

  const selectOptions = assistantsData.map(assistant => {
    return {
      label: assistant.name,
      value: assistant.id,
    }
  })

  return (
    <div className="section-content__title">
      {`Заявка: ${appId.slice(0,4)}, секция: "${section.sectionTitle}", `}
      {accesses.canAssignAssistant ? (
        <Select
          options={selectOptions}
          style={{width: 150}}
          onSelect={assignAssistantHandler}
          value={!docSectionData ? null : docSectionData.assistantId}
        />
      ) : (
        <span>
          Асистент:
          <span className='section-content__assistant-name' >{`${docSectionData ? docSectionData.assistantName : '' }`}</span>
        </span>
      )}
      <div className='section-content__section-status'>
        <SectionStatus />
      </div>
    </div>
  );
};

export default SectionContentTitle;